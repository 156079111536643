export const BasketIcon = () => (
  <svg
    viewBox="0 0 20 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>checkout basket</title>
    <path
      d="M15.11 15.3001H4.89L1 5.57015H19L15.11 15.3001Z"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M15.1103 1L17.0503 5.57"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M4.88991 1L2.94991 5.57"
      stroke="currentColor"
      strokeWidth="1.8"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
)
