import styles from './menu-list.module.css'

interface MenuListProps {
  children: React.ReactNode
}

const MenuList: React.FC<MenuListProps> = (props) => (
  <ul className={styles.menuList} data-testid="desktop-navigation-menu-list">
    {props.children}
  </ul>
)

export default MenuList
