import SocialLink from '../social-link/social-link'
import type { SocialLink as SocialLinkType } from '../types'
import styles from './social-link-list.module.css'

interface SocialLinkListProps {
  links: SocialLinkType[]
}

const SocialLinkList: React.FC<SocialLinkListProps> = ({ links }) => (
  <ul className={styles.list}>
    {links.map((props) => (
      <SocialLink key={props.name} {...props} />
    ))}
  </ul>
)

export default SocialLinkList
