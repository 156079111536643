import { useContext } from 'react'

import Profile from '../assets/profile.svg'
import { SharedUiHeaderContext } from '../shared-ui-header.context'
import styles from './profile-button.module.css'

export function ProfileButton() {
  const { firstName } = useContext(SharedUiHeaderContext)

  return (
    <a
      className={styles.button}
      href={'/my-profile'}
      aria-label="My profile"
      data-testid="profile-button"
    >
      <span className={styles.label} data-testid="profile-label">
        {firstName ? `Hi ${firstName}!` : 'My profile'}
      </span>
      <Profile data-testid="profile-svg" className={styles.svg} />
    </a>
  )
}

export default ProfileButton
