import { SyntheticEvent, useState } from 'react'

import HoldingImage from '../holding-image/holding-image.component'
import LazyImage from '../lazy-image/lazy-image.component'

export interface ImageProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  i18n?: { noImage: string }
  isLazy?: boolean
  onError?: (event?: SyntheticEvent) => void
}

const Image: React.FC<ImageProps> = ({
  i18n = { noImage: 'Image Unavailable' },
  isLazy,
  className = '',
  onError,
  ...props
}) => {
  const [hasError, updateErrorState] = useState(false)
  const updateError = () => {
    updateErrorState(true)
  }

  return !props.src || hasError ? (
    <HoldingImage className={className} text={i18n.noImage} />
  ) : isLazy ? (
    <LazyImage
      {...props}
      className={className}
      onError={onError || updateError}
    />
  ) : (
    <img
      className={className}
      alt={props.alt || undefined}
      role={!props.alt ? 'presentation' : undefined}
      src={props.src || ''}
      onError={onError || updateError}
      width={props.width || undefined}
      height={props.height || undefined}
    />
  )
}

export default Image
