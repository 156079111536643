import {
  EventActions,
  EventCategories,
  EventNames,
  trackEventCallback,
} from '../shared-ui-header.types'
import { TrackingEventTypes } from '../types/analytics'
import { ModalState } from './return-to-checkout-modal.types'

export const useReturnToCheckoutModalTracking = (
  trackEvent: trackEventCallback,
) => {
  const resumeCheckoutOpenedModal = (section: ModalState) => {
    const evt = {
      event: {
        name: EventNames.click,
        category: EventCategories.button,
        action: EventActions.modalOpened,
        label: 'checkout-resume',
        section,
      },
    }

    trackEvent({
      eventName: 'Return to Checkout - Modal Opened',
      data: { ...evt, modalState: section },
      type: TrackingEventTypes.ADOBE,
    })
  }

  const resumeCheckoutClosedModal = (section: ModalState) => {
    const evt = {
      event: {
        name: EventNames.click,
        category: EventCategories.button,
        action: EventActions.modalClosed,
        label: 'checkout-resume',
        section,
      },
    }

    trackEvent({
      eventName: 'Return to Checkout - Modal Closed',
      data: { ...evt, modalState: section },
      type: TrackingEventTypes.ADOBE,
    })
  }

  const resumeCheckoutReturnedToCheckout = (section: ModalState) => {
    const evt = {
      event: {
        name: EventNames.click,
        category: EventCategories.button,
        action: EventActions.returnToCheckout,
        label: 'checkout-resume',
        section,
      },
    }

    trackEvent({
      eventName: 'Return to Checkout - User Clicked Modal CTA',
      data: { ...evt, modalState: section },
      type: TrackingEventTypes.ADOBE,
    })
  }

  const clickModalVehicleImage = () => {
    const evt = {
      event: {
        name: EventNames.click,
        category: EventCategories.image,
        action: EventActions.deadClick,
        label: 'checkout-return-car-image',
      },
    }

    trackEvent({
      eventName: 'Return to Checkout - User Clicked Vehicle Image',
      data: { ...evt },
      type: TrackingEventTypes.ADOBE,
    })
  }

  return {
    resumeCheckoutOpenedModal,
    resumeCheckoutClosedModal,
    resumeCheckoutReturnedToCheckout,
    clickModalVehicleImage,
  }
}
