import useActiveMenu from './hooks/use-active-menu'
import { NavigationBar } from './navigation-bar/navigation-bar'
import { initQuicklink } from './quicklink'
import { SharedUiHeaderContext } from './shared-ui-header.context'
import type { SharedUiHeaderContextProps } from './shared-ui-header.context'
import styles from './shared-ui-header.module.css'
import { SkipLinks } from '@cinch-labs/design-system'
import { hasChildren } from '@cinch-labs/shared-util'
import { ReactElement, useEffect, useState } from 'react'
import { FocusOn } from 'react-focus-on'

export interface SharedUiHeaderProps extends SharedUiHeaderContextProps {
  children?: ReactElement
}

export function SharedUiHeader({
  children,
  ...contextProps
}: SharedUiHeaderProps) {
  // Assume true until the component is mounted to prevent focus fighting
  const [isCookieBannerInDOM, setIsCookieBannerInDOM] = useState(true)

  const { activeMenu, setActiveMenu } = useActiveMenu()
  const [pathname, setPathName] = useState<string>('/')

  useEffect(() => {
    setPathName(window.location.pathname)
  }, [])

  const isPrivacyPage = pathname === '/policies/privacy'

  useEffect(() => {
    import('quicklink').then(initQuicklink)
    setIsCookieBannerInDOM(!!document.querySelector('#cookie-banner'))
  }, [])

  return (
    <SharedUiHeaderContext.Provider value={contextProps}>
      <header
        id="main-header"
        className={styles.sharedUiHeader}
        data-testid="shared-ui-header"
      >
        <SkipLinks showSkipToFooter />
        <FocusOn
          enabled={!isPrivacyPage && !!activeMenu && !isCookieBannerInDOM}
          onEscapeKey={() => setActiveMenu(null)}
        >
          <NavigationBar
            showBottomBorder={hasChildren(children) || pathname === '/'}
          />
        </FocusOn>
        {children}
      </header>
    </SharedUiHeaderContext.Provider>
  )
}

export default SharedUiHeader
