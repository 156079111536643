import { initOrdersStore } from '@cinch-nx/data-orders'
import { initUserStore } from '@cinch-nx/data-user'
import { Env, readFromEnv } from '@cinch-nx/environments'

const authKeys = {
  authKey: readFromEnv(Env.AuthKey),
  legacyAuthKey: readFromEnv(Env.LegacyAuthKey),
}

initUserStore({
  ...authKeys,
  identityServiceUrl: readFromEnv(Env.IdentityServiceUrl),
  profileServiceUrl: readFromEnv(Env.ProfileServiceUrl),
  redirectUrlKey: readFromEnv(Env.RedirectUrlKey),
})

initOrdersStore({
  ...authKeys,
  cinchCareServiceUrl: readFromEnv(Env.CinchcareServiceUrl),
  orderServiceUrl: readFromEnv(Env.OrderServiceUrl),
})
