import styles from './list-item.module.css'

export interface ListItemProps {
  label: string
  href: string
  ariaLabel: string
  horizontalLine?: boolean
  handleTrackEvent: () => void
}

export const ListItem = ({
  label,
  href,
  ariaLabel,
  horizontalLine = false,
  handleTrackEvent,
}: ListItemProps) => (
  <>
    <a
      href={href}
      aria-label={ariaLabel}
      className={styles.listItem}
      onClick={handleTrackEvent}
    >
      {label}
    </a>
    {horizontalLine && <hr className={styles.horizontalLine} />}
  </>
)
