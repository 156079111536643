import { useContext } from 'react'

import useActiveMenu from '../../hooks/use-active-menu'
import { useLatestCarReview } from '../../hooks/use-latest-car-review'
import { SharedUiHeaderContext } from '../../shared-ui-header.context'
import MenuArticle from '../shared/menu-article/menu-article'
import {
  ChildMenuNames,
  latestCarReview,
  parentMenu,
  secondaryMenu,
} from '../shared/menu-links'
import MenuListItemLink from '../shared/menu-list-item-link/menu-list-item-link'
import MenuListItem from '../shared/menu-list-item/menu-list-item'
import MenuListSection from '../shared/menu-list-section/menu-list-section'
import MenuList from '../shared/menu-list/menu-list'
import { checkForNullable } from '../utils/sanitising'
import { navigationTrackingDetails } from '../utils/tracking'
import DesktopNavigationDropdown from './desktop-navigation-dropdown/desktop-navigation-dropdown'
import DesktopNavigationItem from './desktop-navigation-item/desktop-navigation-item'
import styles from './desktop-navigation.module.css'

export interface DesktopNavigationProps {
  isDesktop?: boolean
}

export const DesktopNavigation: React.FC<DesktopNavigationProps> = ({
  isDesktop,
}) => {
  const { vehicleCount, trackEvent } = useContext(SharedUiHeaderContext)
  const { activeMenu, setActiveMenu } = useActiveMenu()
  const articleMap = new Map([['newsAndReviews', latestCarReview]])

  const latestReview = useLatestCarReview(isDesktop)

  const getVehicleCount = (linkName: string) => {
    if (linkName === 'View all cars' && vehicleCount) {
      return (
        <span className={styles.vehicleCount} data-testid="vehicle-count">
          {`(${Number(vehicleCount).toLocaleString()})`}
        </span>
      )
    }
    return null
  }

  const closeDropdown = () => {
    setActiveMenu(null)
  }

  const handleDropdownClick = (menuName: ChildMenuNames) => {
    const clickedMenuTitle = secondaryMenu[menuName][0].sectionTitle

    if (!activeMenu) {
      setActiveMenu(menuName)
      trackEvent?.(navigationTrackingDetails(clickedMenuTitle))
    } else {
      if (menuName === activeMenu) {
        closeDropdown()
      } else {
        setActiveMenu(menuName)
        trackEvent?.(navigationTrackingDetails(clickedMenuTitle))
      }
    }
  }

  const renderArticle = (currentMenu: ChildMenuNames | '') => {
    const renderedArticle = articleMap.get(currentMenu)

    if (!renderedArticle) return null

    if (
      currentMenu === 'newsAndReviews' &&
      latestReview &&
      Object.values(latestReview).length > 0 &&
      checkForNullable(latestReview)
    ) {
      const Content = { ...latestReview.content }
      renderedArticle.content = {
        title: Content.title,
        body: Content.snippet,
        linkText: `Read our ${Content.title}`,
        linkUrl: `/${latestReview.full_slug.replace(
          'car-reviews',
          'used-cars',
        )}/review`,
        image: Content.megaMenuThumbnail.filename,
      }
    }

    return (
      <div className={styles.menuArticle}>
        <MenuArticle
          {...renderedArticle}
          isSlimContainer={noTitleList.has(currentMenu)}
        />
      </div>
    )
  }

  const allowedSmallText = new Set(['Popular makes', 'Year'])
  const noTitleList = new Set(['newsAndReviews'])
  const currentActiveMenu = activeMenu ?? ''

  return (
    <nav
      id={'MainNavigation'}
      className={styles.container}
      data-testid="desktop-navigation"
    >
      <ul className={styles.nav}>
        {parentMenu
          .flatMap((obj) => obj.menuItems)
          .filter((item) => item.type !== 'contact')
          .map((item) => (
            <li key={item.label} className={styles.navItem}>
              <DesktopNavigationItem
                {...item}
                activeMenu={currentActiveMenu}
                onButtonClick={handleDropdownClick}
              />
              {item.type === 'parent' ? (
                <DesktopNavigationDropdown
                  onClickBackdrop={closeDropdown}
                  isOpen={currentActiveMenu === item.child}
                  data-testid={`desktop-navigation-dropdown-${item.child}`}
                >
                  <MenuList>
                    {secondaryMenu[item.child]
                      .filter(
                        (menuItem) =>
                          !menuItem.deviceType ||
                          menuItem.deviceType === 'desktop',
                      )
                      .map(({ sectionTitle, menuItems }) => (
                        <MenuListSection
                          title={
                            noTitleList.has(item.child) ? '' : sectionTitle
                          }
                          key={sectionTitle}
                        >
                          {menuItems.map(({ href, label, ariaLabel }) => (
                            <MenuListItem key={label}>
                              <MenuListItemLink
                                label={label}
                                href={href}
                                smallText={allowedSmallText.has(sectionTitle)}
                                parentDropdown={
                                  secondaryMenu[item.child][0].sectionTitle
                                }
                                ariaLabel={ariaLabel}
                              >
                                <span>{label}</span>
                                {getVehicleCount(label)}
                              </MenuListItemLink>
                            </MenuListItem>
                          ))}
                        </MenuListSection>
                      ))}
                    {renderArticle(currentActiveMenu)}
                  </MenuList>
                </DesktopNavigationDropdown>
              ) : null}
            </li>
          ))}
      </ul>
    </nav>
  )
}

export default DesktopNavigation
