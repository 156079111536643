import { AxiosError } from 'axios'

export const hasTokenExpired = (
  axiosError: AxiosError | undefined,
): boolean => {
  if (axiosError === undefined) {
    return false
  }

  return (
    axiosError?.response?.status === 401 || axiosError?.response?.status === 403
  )
}
