import { useEffect } from 'react'

export interface Props {
  warningTime: number
  expiresAt: number | undefined // current expiry time in milliseconds
  setShowExpiryWarning: (show: boolean) => void
  startTimerAt: (timeRemaining: number) => void
}

export const useExpiry = ({
  warningTime,
  expiresAt,
  setShowExpiryWarning,
  startTimerAt,
}: Props) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (typeof expiresAt === 'undefined' || Date.now() > expiresAt) {
        setShowExpiryWarning(false)
      } else if (expiresAt - warningTime < Date.now()) {
        startTimerAt(Math.round((expiresAt - Date.now()) / 1000))
        setShowExpiryWarning(true)
      } else {
        setShowExpiryWarning(false)
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [expiresAt, setShowExpiryWarning, startTimerAt, warningTime])
}

export default useExpiry
