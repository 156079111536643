import { SvgIcon } from '@cinch-labs/design-system'
import cx from 'classnames'
import { useContext } from 'react'

import { SharedUiHeaderContext } from '../../../shared-ui-header.context'
import { navigationTrackingDetails } from '../../utils/tracking'
import { ContactIcons } from '../menu-links'
import styles from './menu-list-item-link.module.css'

export interface MenuListItemLinkProps {
  label: string
  href: string
  icon?: ContactIcons
  smallText?: boolean
  parentDropdown: string
  ariaLabel?: string
  children: React.ReactNode
}

const MenuListItemLink: React.FC<MenuListItemLinkProps> = ({
  label,
  href,
  icon,
  smallText = false,
  parentDropdown,
  children,
  ariaLabel,
}) => {
  const { trackEvent } = useContext(SharedUiHeaderContext)

  return (
    <>
      {icon && <SvgIcon name={icon} className={styles.svg} size={20} />}
      <a
        data-testid={`shared-navigation-link-${label}`}
        title={label}
        aria-label={ariaLabel}
        href={href}
        className={cx(styles.link, {
          [styles.linkWithIcon]: !!icon,
          [styles.smallText]: smallText,
        })}
        onClick={() =>
          trackEvent?.(navigationTrackingDetails(parentDropdown, label))
        }
      >
        {children}
      </a>
    </>
  )
}

export default MenuListItemLink
