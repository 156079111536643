import { SvgIcon, Text, TextStyles } from '@cinch-labs/design-system'
import cx from 'classnames'

import styles from './holding-image.module.css'

const HoldingImage: React.FC<{ className?: string; text?: string }> = ({
  className = '',
  text = '',
}) => (
  <div className={cx(styles.holdingImage, className)}>
    <SvgIcon
      className={styles.icon}
      fill={'currentColor'}
      name="vehicle-side"
      size={64}
    />
    <Text type={TextStyles.menuTitle}>{text}</Text>
  </div>
)

export default HoldingImage
