import { CookieConsentPreference, SettingsType } from '../cookie-consent.types'
import { getCookieBannerMetrics, getCookieSettings } from './cookies'
import * as TrackInteraction from './track-interaction'

interface AddEventParams {
  startTime: number
  elementType: 'banner' | 'modal'
  count: number
  cookieEditorTime: number
  settingsType: SettingsType
  cookiePreferences: CookieConsentPreference
}
export const addEvent = ({
  startTime,
  elementType,
  count,
  cookieEditorTime,
  cookiePreferences,
  settingsType,
}: AddEventParams): void => {
  window.analytics &&
    window.analytics.ready(() => {
      TrackInteraction.cookieSettings(
        getCookieSettings(startTime, cookiePreferences, settingsType),
      )
      TrackInteraction.cookieBannerMetrics(
        getCookieBannerMetrics(startTime, elementType, count),
      )
      if (cookieEditorTime !== 0) {
        TrackInteraction.cookieEditorShown(cookieEditorTime)
      }
    })
}
