import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
import { getAnalyticIDs } from '@cinch-labs/shared-util'

import { SegmentMiddlewarePayload } from './types'

declare global {
  interface Window {
    _DATADOG_SYNTHETICS_BROWSER?: boolean
  }
}

type SegmentMiddleware = {
  payload: SegmentMiddlewarePayload
  next: (payload: Record<string, unknown>) => void
  integrations?: Record<string, boolean>
}

export const getSegmentUserId = () =>
  window.analytics?.user().id() ?? window.analytics?.user().anonymousId() ?? ''

export const datadogMiddleware = ({ payload, next }: SegmentMiddleware) => {
  if (window._DATADOG_SYNTHETICS_BROWSER) return

  const userId = getSegmentUserId()

  datadogRum.addRumGlobalContext('user', {
    id: userId,
  })

  next(payload)
}

export const excludedPagesMiddleware = ({
  payload,
  next,
}: SegmentMiddleware) => {
  const excludedPages = ['/auth-callback', '/silent-renew']
  if (excludedPages.includes(payload.obj.context.page.path)) return

  next(payload)
}

export const unauthenticatedMiddleware = ({
  payload,
  next,
}: SegmentMiddleware) => {
  // Have to access this via window.analytics rather than payload because
  // the payload doesn't detail if a user is authed or not
  if (window.analytics?.user().id()) return next(payload)

  const integrationWithPermissions: Record<string, boolean> = {
    Zendesk: false,
    'Customer.io': false,
  }

  const updatedPayload = {
    ...payload,
    obj: { ...payload.obj, integrations: { ...integrationWithPermissions } },
  }

  next(updatedPayload)
}

export const configureMiddleware = () => {
  window.analytics?.addSourceMiddleware(datadogMiddleware)
  window.analytics?.addSourceMiddleware(excludedPagesMiddleware)
  window.analytics?.addSourceMiddleware(unauthenticatedMiddleware)
}

export const identify = ({
  userId,
  data,
}: {
  userId?: string
  data?: Record<string, unknown>
}) => {
  try {
    if (userId) {
      window.analytics?.identify(userId, { ...data })
    } else {
      window.analytics?.identify({ ...data })
    }
  } catch (error) {
    datadogLogs.logger.error('call to window.analytics.identify() failed', {
      userId,
      data,
      error,
    })
  }
}

export const track = <T = Record<string, unknown>>(
  eventName: string,
  data?: T,
) => {
  if (!eventName || eventName.length === 0) return

  const { adobeID, gaID } = getAnalyticIDs()
  const analyticsContext = { analyticIds: { adobeID, gaID } }
  const options = { context: analyticsContext }

  try {
    window.analytics?.track(eventName, data ?? {}, options)
  } catch (error) {
    datadogLogs.logger.error('call to window.analytics.track() failed', {
      eventName,
      data,
      error,
    })
  }
}

export const reset = () => {
  try {
    window.analytics?.reset()
  } catch {
    datadogLogs.logger.error('call to window.analytics.reset() failed')
  }
}

export const page = <D = unknown>(name?: string, data?: D) => {
  if (!name) return

  try {
    window.analytics?.page(name, data ?? {}, {
      integrations: { Zendesk: false },
    })
  } catch (error) {
    datadogLogs.logger.error('call to window.analytics.page() failed', {
      name,
      data,
      error,
    })
  }
}
