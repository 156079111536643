import styles from './xmas-promo-banner.module.css'

export function XmasPromoBanner() {
  return (
    <p className={styles.text}>
      Get your skates on and order by December 16 for Christmas delivery.{' '}
      <a href="/support#faq-christmas" className={styles.link}>
        View delivery FAQs
      </a>
    </p>
  )
}
export default XmasPromoBanner
