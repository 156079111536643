import { Text, TextStyles, designTokens } from '@cinch-labs/design-system'
import cx from 'classnames'

import Car from './assets/car.svg'
import styles from './holding-image.module.css'

const { space } = designTokens.customProperties.ds

export const HoldingImage: React.FC<{ className?: string; text?: string }> = ({
  className = '',
  text = '',
}) => (
  <div className={cx(styles['holdingImage'], className)}>
    <span className={styles['icon']}>
      <Car fill={'currentColor'} width={space['8-x']} />
    </span>
    <Text type={TextStyles.menuTitle}>{text}</Text>
  </div>
)

export default HoldingImage
