export interface Token {
  access_token: string
  expires_at: number
  profile: {
    sub: string
    'https://cinch.co.uk/user_id': string
  }
}

export interface TokenConfiguration {
  authKey: string
  legacyAuthKey: string
}

const tokenIsInDate = (token: Token): boolean =>
  token.expires_at > Date.now() / 1000

const isToken = (maybeToken: unknown): maybeToken is Token =>
  Object.prototype.hasOwnProperty.call(maybeToken, 'expires_at')

export const getToken = ({ authKey, legacyAuthKey }: TokenConfiguration) => {
  const maybeLegacyAuthToken = JSON.parse(localStorage.getItem(authKey) ?? '{}')
  const maybeAuthToken = JSON.parse(localStorage.getItem(legacyAuthKey) ?? '{}')

  if (isToken(maybeAuthToken) && tokenIsInDate(maybeAuthToken)) {
    return maybeAuthToken
  }

  if (isToken(maybeLegacyAuthToken) && tokenIsInDate(maybeLegacyAuthToken)) {
    return maybeLegacyAuthToken
  }

  return null
}
