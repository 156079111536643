import { VEHICLE_SEARCH_URL } from '@cinch-nx/data-search'
import { fetcher, hasTokenExpired, useUserStore } from '@cinch-nx/data-user'
import type { DirectVehicleSummaryResponse } from '@cinch-nx/data-vehicle'
import { AxiosError } from 'axios'
import useSWR from 'swr'

import { FAVOURITES_URL } from '../../index'
import type { FavouriteVehiclesResponse } from '../types'

export const useFavouriteVehicles = (withVehicleData = false) => {
  const { setStatus, status } = useUserStore()

  const {
    data: favouritesData,
    error: favouritesError,
    mutate: mutateFavourites,
  } = useSWR<FavouriteVehiclesResponse, AxiosError>(
    status === 'valid' && FAVOURITES_URL,
    fetcher,
  )

  const directFavouritesTokenExpired = hasTokenExpired(favouritesError)

  if (directFavouritesTokenExpired) {
    setStatus('expired')
  }

  const favouritesAmount = favouritesData?.favouriteVehicles?.length ?? 0

  const { data: vehicleData, error: vehicleDetailsError } = useSWR<
    DirectVehicleSummaryResponse,
    AxiosError
  >(
    () =>
      withVehicleData && !!favouritesAmount
        ? `${VEHICLE_SEARCH_URL}?vehicleIds=${favouritesData?.favouriteVehicles
            .map(({ vehicleId }) => vehicleId)
            .join(',')}&excludeUnavailable=false&queryOnly=true`
        : null,
    fetcher,
  )

  const favouriteVehiclesTokenExpired = hasTokenExpired(favouritesError)

  if (favouriteVehiclesTokenExpired) {
    setStatus('expired')
  }

  return {
    favouritesData,
    favouritesAmount,
    favouritesError,
    vehicleData,
    vehicleDetailsError,
    mutateFavourites,
  }
}
