import { getBooleanFeatureFlag } from '@cinch-nx/landing-data-feature-flags'
import Layout from '@cinch-nx/landing-ui-shared/lib/layout/layout'
import { useEffect, useState } from 'react'

import SignPost from '../../../assets/404.svg'
import { LandingFeatureErrorPage } from '../landing-feature-error-page'

export interface PageErrorBoundaryProps {
  errorTitle: string
  errorText: string
  homeCtaText: string
  searchCarsCtaText: string
  currentUrl: string
}

export const ErrorBoundaryPage: React.FC<PageErrorBoundaryProps> = ({
  errorTitle,
  errorText,
  homeCtaText,
  searchCarsCtaText,
  currentUrl,
}) => {
  const [shouldShowSharedUIHeaderV2, setShouldShowSharedUIHeaderV2] =
    useState<boolean>(false)

  useEffect(() => {
    const getFeatureFlagData = async () => {
      const result = await getBooleanFeatureFlag('shared_ui_header_v2')
      setShouldShowSharedUIHeaderV2(result)
    }
    getFeatureFlagData()
  }, [])

  return (
    <Layout shouldShowSharedUIHeaderV2={shouldShowSharedUIHeaderV2}>
      <LandingFeatureErrorPage
        title={errorTitle}
        subTitle={errorText}
        image={SignPost} // TODO: Replace with appropriate image
        hasCta={true}
        ctaText={homeCtaText}
        searchCarsCtaText={searchCarsCtaText}
        ctaHref={currentUrl}
      />
    </Layout>
  )
}
