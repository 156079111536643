import { datadogRum } from '@datadog/browser-rum'

import { DatadogEnv } from '../types'
import { getEnvironmentFromURL, hasWindow } from '../utils'

export const initDataDogRUM = ({
  serviceName: service,
  rumGlobalSampleRate: sampleRate,
  applicationId,
  datadogRumClientToken: clientToken,
  appVersion: version,
}: DatadogEnv): void => {
  if (hasWindow) {
    const env = getEnvironmentFromURL(window.location.hostname)

    datadogRum.init({
      applicationId,
      clientToken,
      sampleRate,
      env,
      trackInteractions: true,
      trackFrustrations: true,
      service,
      site: 'datadoghq.eu',
      version,
      silentMultipleInit: true,
      allowedTracingOrigins: [
        'https://api-dev.cinch.co.uk',
        'https://api-production.cinch.co.uk',
        /https:\/\/.*\.snc-(dev|prod)\.aws\.cinch\.co\.uk/,
      ],
    })

    datadogRum.startSessionReplayRecording()
  }
}
