export enum BaseEnvironment {
  RedirectUrlKey = 'REDIRECT_URL_KEY',
}

export enum EnvironmentFromFile {
  Adobe = 'ADOBE_DTM',
  LegacyAuthKey = 'IDS_AUTH_KEY',
  AuthKey = 'AUTH0_AUTH_KEY',
  ProfileServiceUrl = 'PROFILE_SERVICE_URL',
  StaticAssetsUrl = 'STATIC_ASSETS_URL',
  OrderServiceUrl = 'ORDER_SERVICE_URL',
  CinchcareServiceUrl = 'CINCHCARE_SERVICE_URL',
  DeliveryServiceURl = 'DELIVERY_SERVICE_URL',
  SearchServiceUrl = 'SEARCH_SERVICE_URL',
  SegmentKey = 'SEGMENT_KEY',
  IdentityServiceUrl = 'IDENTITY_SERVICE_URL',
  promoBannerName = 'PROMO_BANNER_NAME',
  cinchUrl = 'CINCH_URL',
  TrustpilotUrl = 'TRUSTPILOT_URL',
  StoryblokBFFComponent = 'STORYBLOK_BFF_COMPONENT',
  consumerValuationsServiceUrl = 'CONSUMER_VALUATIONS_SERVICE_URL',
  CinchChargeCardRequestUrl = 'CINCH_CHARGE_CARD_REQUEST_URL',
  CinchChargeCardRequestAPIKey = 'CINCH_CHARGE_CARD_REQUEST_API_KEY',
  ProductDetailServiceUrl = 'PRODUCT_DETAIL_SERVICE_URL',
  ReservationStatusUrl = 'RESERVATION_STATUS_URL',
  InventoryServiceHistoryUrl = 'INVENTORY_SERVICE_HISTORY_URL',
  IOSAppStoreUrl = 'IOS_APP_STORE_URL',
  AndroidAppStoreUrl = 'ANDROID_APP_STORE_URL',
  AdminFeeReturnToCheckout = 'ADMIN_FEE_RETURN_TO_CHECKOUT',
  AdminFeeValue = 'ADMIN_FEE_VALUE',
  AdminFeeLoyalty = 'ADMIN_FEE_LOYALTY',
}

export enum EnvironmentFromProcess {
  DataDogAPIKey = 'DATADOG_API_KEY',
  DataDogClientId = 'DATADOG_CLIENT_ID',
  DataDogRumApplicationId = 'DATADOG_RUM_APPLICATION_ID',
  DataDogRumClientToken = 'DATADOG_RUM_CLIENT_TOKEN',
  DataDogRumGlobalSampleRate = 'DATADOG_RUM_GLOBAL_SAMPLE_RATE',
  DataDogServiceName = 'DATADOG_SERVICE_NAME',
  BuildId = 'BUILD_ID',
}

export interface IBaseEnvironment extends Record<BaseEnvironment, string> {
  REDIRECT_URL_KEY: string
}

export interface IEnvironmentFromFile
  extends Record<EnvironmentFromFile, string> {
  ADOBE_DTM: string
  IDS_AUTH_KEY: string
  AUTH0_AUTH_KEY: string
  CINCHCARE_SERVICE_URL: string
  DELIVERY_SERVICE_URL: string
  ORDER_SERVICE_URL: string
  PROFILE_SERVICE_URL: string
  SEARCH_SERVICE_URL: string
  SEGMENT_KEY: string
  STATIC_ASSETS_URL: string
  IDENTITY_SERVICE_URL: string
  PROMO_BANNER_NAME: string
  CINCH_URL: string
  TRUSTPILOT_URL: string
  CONSUMER_VALUATIONS_SERVICE_URL: string
  CINCH_CHARGE_CARD_REQUEST_URL: string
  CINCH_CHARGE_CARD_REQUEST_API_KEY: string
  PRODUCT_DETAIL_SERVICE_URL: string
  RESERVATION_STATUS_URL: string
  INVENTORY_SERVICE_HISTORY_URL: string
  IOS_APP_STORE_URL: string
  ANDROID_APP_STORE_URL: string
  ADMIN_FEE_RETURN_TO_CHECKOUT: string
  ADMIN_FEE_VALUE: string
}

export interface IEnvironmentFromProcess
  extends Record<EnvironmentFromProcess, string | undefined> {
  BUILD_ID: string | undefined
  DATADOG_API_KEY: string | undefined
  DATADOG_CLIENT_ID: string | undefined
  DATADOG_RUM_APPLICATION_ID: string | undefined
  DATADOG_RUM_CLIENT_TOKEN: string | undefined
  DATADOG_RUM_GLOBAL_SAMPLE_RATE: string | undefined
  DATADOG_SERVICE_NAME: string | undefined
}

export interface IEnvironment
  extends IBaseEnvironment,
    IEnvironmentFromFile,
    IEnvironmentFromProcess {}

export const Env = {
  ...BaseEnvironment,
  ...EnvironmentFromFile,
  ...EnvironmentFromProcess,
}

export type Env = BaseEnvironment | EnvironmentFromFile | EnvironmentFromProcess

export type keys = keyof IEnvironment
