import type { NavProps } from '../../shared/menu-links'
import DesktopNavigationLink from './desktop-navigation-link/desktop-navigation-link'
import DesktopNavigationParent from './desktop-navigation-parent/desktop-navigation-parent'

const DesktopNavigationItem = (item: NavProps) => {
  if (item.type === 'contact') {
    return null
  }

  if (item.type === 'link') {
    return <DesktopNavigationLink {...item} />
  }

  if (item.type === 'parent') {
    return <DesktopNavigationParent {...item} />
  }

  return null
}

export default DesktopNavigationItem
