import { Text } from '@cinch-labs/design-system'

import Chat from '../assets/chat.svg'
import Mail from '../assets/mail.svg'
import Phone from '../assets/phone.svg'
import WhatsApp from '../assets/whats-app.svg'
import type { SocialLink as SocialLinkType } from '../types'
import styles from './social-link.module.css'

const getIcon = (label: string): JSX.Element | undefined => {
  switch (label) {
    case 'WhatsApp': {
      return <WhatsApp />
    }
    case 'Text us': {
      return <Chat />
    }
    case 'Call us': {
      return <Phone />
    }
    case 'Email us': {
      return <Mail />
    }
    default:
      return
  }
}

const SocialLink = ({ name, linkLabel, href }: SocialLinkType) => (
  <li className={styles.item}>
    <a href={href} className={styles.link}>
      <Text
        className={styles.body}
        fontSize="s"
        lineHeight="body"
        fontWeight={'semibold'}
      >
        {getIcon(name)}
        <span className={styles.iconName}>{name}</span>
      </Text>
      <Text className={styles.linkLabel}>{linkLabel}</Text>
    </a>
  </li>
)

export default SocialLink
