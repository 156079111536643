import { useCustomerData } from '@cinch-labs/customer-data-segment'

import styles from './black-friday-banner.module.css'

interface BlackFridayBannerProps {
  sendDigitalDataEvent: ((data?: Record<string, unknown>) => void) | undefined
}

export function BlackFridayBanner({
  sendDigitalDataEvent,
}: BlackFridayBannerProps) {
  const { trackEvent } = useCustomerData()
  const handleOnClick = () => {
    const data = {
      event: {
        name: 'click',
        category: 'link',
        action: 'navigate',
        label: 'shop sale now',
      },
    }
    trackEvent({
      type: 'track',
      eventName: 'Promotion Clicked',
      data: {
        name: 'Black Friday sale promo banner - cars on offer',
        position: 'top banner',
      },
    })
    if (sendDigitalDataEvent) sendDigitalDataEvent(data)
  }
  return (
    <>
      <span className={styles.saveTag}>BLACK FRIDAY</span>
      <p className={styles.text}>
        Save <b>£1000</b> off{' '}
        <a href="/find-vehicle?tags=cars-on-offer" onClick={handleOnClick}>
          selected cars
        </a>{' '}
        22 – 28 Nov. <a href="/policies/black-friday-sale">T&Cs apply</a>
      </p>
    </>
  )
}

export default BlackFridayBanner
