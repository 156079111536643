import { useEffect, useState } from 'react'

export const useCookieEditorTime = (isModalOpen: boolean) => {
  const [cookieEditorTime, setCookieEditorTime] = useState(0)
  const [startTime] = useState(Date.now())

  useEffect(() => {
    if (!cookieEditorTime && isModalOpen) {
      setCookieEditorTime(Math.round(Date.now() / 1000 - startTime / 1000))
    }
  }, [cookieEditorTime, isModalOpen, startTime])

  return {
    cookieEditorTime,
    setCookieEditorTime,
    startTime,
  }
}
