import styles from './nav-card.module.css'
import Image from 'next/image'
import { LinkIcon } from '../../../assets/link-icon'

export interface NavCardProps {
  heading: string
  subHeading: string
  link: string
  href: string
  imageSrc: string
  alt: string
  isImageFullWidth?: boolean
  handleTrackEvent: () => void
}

export const NavCard = ({
  heading,
  subHeading,
  link,
  href,
  imageSrc,
  alt,
  isImageFullWidth = false,
  handleTrackEvent,
}: NavCardProps) => (
  <div className={styles.navCard}>
    <div className={styles.headingContainer}>
      <h2 className={styles.heading}>{heading}</h2>
      <h3 className={styles.subHeading}>{subHeading}</h3>
    </div>
    <div className={!isImageFullWidth ? styles.imageWrapper : ''}>
      <Image
        src={imageSrc}
        alt={alt}
        layout="responsive"
        width={285}
        height={190}
        className={styles.image}
      />
    </div>
    <a href={href} className={styles.link} onClick={handleTrackEvent}>
      {link}
      <div className={styles.icon}>
        <LinkIcon />
      </div>
    </a>
  </div>
)
