import { BaseComponent } from '../../types/components'
import { StoryData } from '../../types/shared'
import { STORYBLOK_BFF_URL } from '../../util/api'
import { GetStoriesParams, getStoriesUrlParams } from './schema'

export type GetStoriesResponse<Content extends BaseComponent> = {
  total: number
  stories: StoryData<Content>[]
}

export const getStories = async <Content extends BaseComponent>(
  params: GetStoriesParams,
): Promise<GetStoriesResponse<Content>> => {
  const urlParams = getStoriesUrlParams.parse(params)
  const url = `${STORYBLOK_BFF_URL}/get-all-stories?${urlParams.toString()}`

  try {
    const response = await fetch(url)

    if (response.status !== 200) {
      throw new Error(
        `'get-all-stories' responded with non-OK response status ${response.status}`,
      )
    }

    return response.json()
  } catch (error) {
    if (error instanceof Error) {
      // eslint-disable-next-line unicorn/prefer-type-error
      throw new Error(
        `'get-all-stories' endpoint threw an error: ${error.message}`,
      )
    }

    throw new Error(
      `'get-all-stories' endpoint threw an unknown error: ${error}`,
    )
  }
}

export type { GetStoriesParams } from './schema'
