import cx from 'classnames'

import styles from './wrapper.module.css'

export interface WrapperProps {
  children?: React.ReactNode
  isFullHeight?: boolean
  isFluid?: boolean
  isSlim?: boolean
  hasOverflow?: boolean
  className?: {
    container?: string
    inner?: string
  }
  backgroundColor?: string
}

const Wrapper: React.FC<WrapperProps> = ({
  children,
  isFullHeight,
  isFluid,
  isSlim,
  hasOverflow,
  className,
  backgroundColor = 'transparent',
  ...rest
}) => {
  const classNames = cx(
    styles.wrapper,
    {
      [styles.container]: !isFluid,
      [styles.wrapperFullHeight]: isFullHeight,
      [styles.slimContainer]: !isFluid && isSlim,
      [styles.wrapperOverflow]: !hasOverflow,
    },
    className?.inner,
  )

  return (
    <section className={className?.container} style={{ backgroundColor }}>
      <div className={classNames} {...rest}>
        {children}
      </div>
    </section>
  )
}

export default Wrapper
