import { MenuIcon } from '../../../assets/menu-icon'
import { BasketNavigation } from '../basket-navigation/basket-navigation'
import { MobileMenu } from '../mobile-menu/mobile-menu'
import { NavItem } from '../nav-item/nav-item'
import { ListWrapper } from '../list-wrapper/list-wrapper'
import { ReactElement, useEffect, useContext, useState } from 'react'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'

import styles from './mobile-nav.module.css'
import { Favourites } from '../favourites/favourites'

interface MobileNavProps {
  checkoutModal?: ReactElement | null
}

export const MobileNav = ({ checkoutModal }: MobileNavProps) => {
  const { logicStore, userStore } = useContext(SharedUiHeaderV2Context)
  const { handleTrackEvent } = logicStore
  const { isLoggedIn } = userStore
  const [shouldShowSecondaryMenu, setShouldShowSecondaryMenu] = useState(false)
  const [shouldShowMobileMenu, setShouldShowMobileMenu] = useState(false)
  const [isMobile, setIsMobile] = useState(false)

  const [shouldShowCheckoutModal, setShouldShowCheckoutModal] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setIsMobile(window?.innerWidth <= 767)
    }
  })

  return (
    <div className={styles.menuIconWrapper}>
      {isLoggedIn ? <Favourites /> : null}
      {isMobile && (
        <BasketNavigation
          setShouldShowCheckoutModal={() =>
            setShouldShowCheckoutModal(!shouldShowCheckoutModal)
          }
        />
      )}

      {shouldShowCheckoutModal && checkoutModal}

      <button
        aria-label="mobile menu"
        onClick={() => setShouldShowMobileMenu(!shouldShowMobileMenu)}
      >
        <MenuIcon />
      </button>

      {shouldShowMobileMenu && (
        <div className={styles.mobileMenuWrapper}>
          <MobileMenu
            handleMenuClose={() => {
              setShouldShowMobileMenu(!shouldShowMobileMenu)
              setShouldShowSecondaryMenu(!shouldShowSecondaryMenu)
            }}
          >
            <>
              {/* to save these getting too big we can create
                  components out of eact secion ie -> 
                  'popular makes', 'shop by budget'*/}
              {!shouldShowSecondaryMenu ? (
                <>
                  <NavItem
                    handleSecondaryMobileMenu={() =>
                      setShouldShowSecondaryMenu(!shouldShowSecondaryMenu)
                    }
                    title={'Test Nav Item Mobile'}
                    handleTrackEvent={() =>
                      handleTrackEvent({
                        name: 'click',
                        category: 'mega menu',
                        label: 'Test Nav Item Mobile',
                        action: 'button',
                      })
                    }
                  />

                  <ListWrapper
                    heading={'Test heading'}
                    urlText={'Find more cars'}
                    href={'/'}
                    handleTrackEvent={() =>
                      handleTrackEvent({
                        name: 'click',
                        category: 'mega menu',
                        label: 'Test Nav Item Mobile',
                        action: 'Find more cars',
                        value: 'Test heading',
                      })
                    }
                  >
                    <div>Test Item</div>
                  </ListWrapper>
                </>
              ) : null}

              {shouldShowSecondaryMenu && (
                <NavItem
                  isSecondaryMenu
                  handleSecondaryMobileMenu={() =>
                    setShouldShowSecondaryMenu(!shouldShowSecondaryMenu)
                  }
                  title={'Second Menu Item'}
                  handleTrackEvent={() =>
                    handleTrackEvent({
                      name: 'click',
                      category: 'mega menu',
                      label: 'Second Menu Item',
                      action: 'button',
                    })
                  }
                />
              )}
            </>
          </MobileMenu>
        </div>
      )}
    </div>
  )
}
