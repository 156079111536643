export const ArrowBorder = () => (
  <svg
    width="49"
    height="40"
    viewBox="0 0 49 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#arrow-border-clip)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.2136 9.99862C26.1399 9.99425 26.068 9.97959 26 9.95559V7H18.5C10.4919 7 4 13.4919 4 21.5C4 29.5081 10.4919 36 18.5 36H19H30.5C38.5081 36 45 29.5081 45 21.5V20.9073C46.433 20.7287 47.7831 20.2978 49 19.6628V21.5C49 31.2123 41.5158 39.1765 32 39.9401V40H30.5H19H18.5C8.28273 40 0 31.7173 0 21.5C0 11.2827 8.28273 3 18.5 3H26V0.0444101C26.068 0.0204119 26.1399 0.00574628 26.2136 0.00137845C26.4098 -0.0102487 26.6029 0.0520338 26.7514 0.174829L31.7517 4.46811C31.8298 4.53521 31.8923 4.61724 31.9351 4.70887C31.9779 4.8005 32 4.89969 32 5C32 5.10031 31.9779 5.1995 31.9351 5.29113C31.8923 5.38276 31.8298 5.46479 31.7517 5.53189L26.7514 9.82517C26.6029 9.94797 26.4098 10.0102 26.2136 9.99862Z"
        fill="url(#arrow-border-paint)"
      />
    </g>
    <defs>
      <linearGradient
        id="arrow-border-paint"
        x1="24.5"
        y1="0"
        x2="24.5"
        y2="40"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF2DFF" />
        <stop offset="1" stopColor="#FF5B83" />
      </linearGradient>
      <clipPath id="arrow-border-clip">
        <rect width="49" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
