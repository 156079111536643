export const LinkIcon = () => (
  <svg
    preserveAspectRatio="xMidYMid"
    height="32px"
    width="32px"
    fill="currentColor"
    viewBox="0 0 32 32"
    aria-hidden
  >
    <path
      fillRule="evenodd"
      d="M15.72 18.47a.75.75 0 1 0 1.06 1.06l7-7a.75.75 0 0 0 0-1.06l-7-7a.75.75 0 1 0-1.06 1.06l5.72 5.72H.75a.75.75 0 0 0 0 1.5h20.69l-5.72 5.72Z"
    />
  </svg>
)
