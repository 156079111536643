import HamburgerIcon from '../assets/menu.svg'
import styles from './mobile-menu-button.module.css'

interface MobileMenuButtonProps {
  onClick: () => void
}

export const MobileMenuButton = ({ onClick }: MobileMenuButtonProps) => (
  <button aria-label={'Open Menu'} onClick={onClick} className={styles.button}>
    <HamburgerIcon />
  </button>
)

export default MobileMenuButton
