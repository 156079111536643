export const MyAccountIcon = () => (
  <svg width="32" height="32" xmlns="http://www.w3.org/2000/svg" aria-hidden>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 12C22 15.3137 19.3137 18 16 18C12.6863 18 10 15.3137 10 12C10 8.68629 12.6863 6 16 6C19.3137 6 22 8.68629 22 12ZM20.5 12C20.5 14.4853 18.4853 16.5 16 16.5C13.5147 16.5 11.5 14.4853 11.5 12C11.5 9.51472 13.5147 7.5 16 7.5C18.4853 7.5 20.5 9.51472 20.5 12Z"
      fill="#2F2F2F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 28C6 28.1701 6.00664 28.339 6.01972 28.5065C6.59211 28.9639 7.1966 29.3828 7.82925 29.7593C7.61354 29.1881 7.5 28.5955 7.5 28C7.5 24.7052 10.9759 21.5 16 21.5C21.0241 21.5 24.5 24.7052 24.5 28C24.5 28.5955 24.3865 29.1881 24.1708 29.7593C24.8034 29.3828 25.4079 28.9639 25.9803 28.5065C25.9934 28.339 26 28.1701 26 28C26 23.5817 21.5228 20 16 20C10.4772 20 6 23.5817 6 28Z"
      fill="#2F2F2F"
    />
  </svg>
)
