import { SvgIcon } from '@cinch-labs/design-system'
import { useContext } from 'react'

import { SharedUiHeaderContext } from '../../../shared-ui-header.context'
import { ChildMenuNames, parentMenu } from '../../shared/menu-links'
import MenuListItemLink from '../../shared/menu-list-item-link/menu-list-item-link'
import MenuListItem from '../../shared/menu-list-item/menu-list-item'
import MenuListSection from '../../shared/menu-list-section/menu-list-section'
import MenuList from '../../shared/menu-list/menu-list'
import { navigationTrackingDetails } from '../../utils/tracking'
import styles from '../mobile-navigation.module.css'

export interface PrimaryMobileMenuProps {
  onButtonClick: (menuType: ChildMenuNames) => void
}

export const PrimaryMobileMenu: React.FC<PrimaryMobileMenuProps> = ({
  onButtonClick,
}) => {
  const { trackEvent } = useContext(SharedUiHeaderContext)

  const handleButtonClick = (child: ChildMenuNames, menuName: string) => {
    onButtonClick(child)
    trackEvent?.(navigationTrackingDetails(menuName))
  }

  return (
    <MenuList>
      {parentMenu?.map(({ sectionTitle, menuItems }) => (
        <MenuListSection title={sectionTitle} key={sectionTitle}>
          {menuItems.map((item) => (
            <MenuListItem key={item.label}>
              {item.type === 'parent' && (
                <button
                  className={styles.menuItemButton}
                  onClick={() => handleButtonClick(item.child, item.label)}
                >
                  {item.label}
                  <SvgIcon name="chevron-right" color="mineShaft" size={22} />
                </button>
              )}
              {(item.type === 'contact' || item.type === 'link') && (
                <MenuListItemLink
                  {...item}
                  parentDropdown={item.label}
                  data-testid={`mobile-navigation-link-${item.label}`}
                >
                  {item.label}
                </MenuListItemLink>
              )}
            </MenuListItem>
          ))}
        </MenuListSection>
      ))}
    </MenuList>
  )
}

export default PrimaryMobileMenu
