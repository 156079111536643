import { ReactElement } from 'react'
import { CloseIcon } from '../../../assets/close-icon'
import { LogoImg } from '../../../assets/logo'
import { MyAccountIcon } from '../../../assets/my-account-icon'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import { useContext } from 'react'
import styles from './mobile-menu.module.css'

export interface MobileMenuProps {
  children: ReactElement
  handleMenuClose: () => void
}

export const MobileMenu = ({ handleMenuClose, children }: MobileMenuProps) => {
  const { logicStore } = useContext(SharedUiHeaderV2Context)
  const { handleTrackEvent } = logicStore

  return (
    <>
      <div className={styles.mobileMenuHeader}>
        <div>
          <a href="/" aria-label="cinch logo mobile">
            <LogoImg />
          </a>
        </div>

        <div>
          <a
            className={styles.mobileProfile}
            href="/my-profile"
            aria-label="my account mobile"
            onClick={() => {
              handleTrackEvent({
                name: 'click',
                category: 'mega menu',
                label: 'my profile',
                action: 'button',
              })
            }}
          >
            <MyAccountIcon />
          </a>

          <button
            className={styles.mobileCloseButton}
            aria-label="close icon"
            onClick={handleMenuClose}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
      <div>{children}</div>
    </>
  )
}
