import { IEnvironmentFromFile } from './environment.types'

export const environmentProd: IEnvironmentFromFile = {
  ADOBE_DTM: 'launch-EN4b0cc1c046db4a3da7308feed4637416.min',
  IDS_AUTH_KEY: 'oxford-user:https://identity-production.cinch.co.uk:fe',
  AUTH0_AUTH_KEY:
    'oxford-user:https://auth.cinch.co.uk:B5XFE1dV7b8IMpeZxY8kqCPOPiAwgwD7',
  CINCHCARE_SERVICE_URL: 'https://cinchcare.cnv-prod.aws.cinch.co.uk',
  DELIVERY_SERVICE_URL: 'https://delivery.api.cinch.co.uk',
  ORDER_SERVICE_URL: 'https://orders.api.cinch.co.uk',
  PROFILE_SERVICE_URL: 'https://profile.onr-prod.aws.cinch.co.uk',
  SEARCH_SERVICE_URL: 'https://search-api.snc-prod.aws.cinch.co.uk',
  SEGMENT_KEY: 'fxuC9PcNxIbVcTp5QHTWYF9QggSg3Mvs',
  STATIC_ASSETS_URL: 'https://assets-production.cinch.co.uk',
  IDENTITY_SERVICE_URL: 'https://api-production.cinch.co.uk/idt/v1',
  PROMO_BANNER_NAME: 'sale',
  CINCH_URL: 'https://www.cinch.co.uk',
  TRUSTPILOT_URL: 'https://trustpilot.snc-prod.aws.cinch.co.uk',
  STORYBLOK_BFF_COMPONENT: 'https://storyblok-bff.snc-prod.aws.cinch.co.uk',
  CONSUMER_VALUATIONS_SERVICE_URL:
    'https://consumer-valuations.cnv-prod.aws.cinch.co.uk',
  CINCH_CHARGE_CARD_REQUEST_URL:
    'https://extras-cinch-charge-card.cnv-prod.aws.cinch.co.uk',
  CINCH_CHARGE_CARD_REQUEST_API_KEY: 'R48mFOTC6BamHbg1LR8k97fvGXOBOweg1PsHdERV',
  PRODUCT_DETAIL_SERVICE_URL: 'https://product.api.cinch.co.uk',
  RESERVATION_STATUS_URL: 'https://orders.api.cinch.co.uk/reservation-status',
  INVENTORY_SERVICE_HISTORY_URL:
    'https://service-history.api.cinch.co.uk/basic',
  IOS_APP_STORE_URL: 'https://apps.apple.com/app/id1641060889',
  ANDROID_APP_STORE_URL:
    'https://play.google.com/store/apps/details?id=com.cinchapp',
  ADMIN_FEE_RETURN_TO_CHECKOUT: 'false',
  ADMIN_FEE_VALUE: '9900',
  ADMIN_FEE_LOYALTY: 'false',
}
