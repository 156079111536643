import { SvgIcon, Text } from '@cinch-labs/design-system'
import cx from 'classnames'

import { menuArticle } from '../menu-links'
import styles from './menu-article.module.css'

const MenuArticle: React.FC<menuArticle> = ({
  sectionHeading,
  content: { title, body, linkText, linkUrl, image },
  isSlimContainer,
}) => (
  <article
    className={cx(styles.articleContainer, {
      [styles.slimContainer]: isSlimContainer,
    })}
  >
    <Text className={styles.articleSectionHeading} element="h2">
      {sectionHeading}
    </Text>
    <div className={styles.articleContainerInner}>
      <img className={styles.articleImg} src={image} alt={title} />
      <div className={styles.articleContent}>
        <Text
          fontWeight="bold"
          element="h3"
          fontSizeFixed="m"
          className={styles.articleTitle}
        >
          {title}
        </Text>
        <Text lineHeight="display" className={styles.articleBody}>
          {body}
        </Text>
        <a className={styles.articleLink} href={linkUrl}>
          {linkText}
          <SvgIcon name="arrow-right" />
        </a>
      </div>
    </div>
  </article>
)

export default MenuArticle
