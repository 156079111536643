import { isBrowser } from '@cinch-labs/shared-util'

import { ActiveOrder, CheckoutStage, OrderCheckoutStage } from '../types'

export const cardDetailsPagePath = '/checkout/card-details/'
export const deliveryPagePath = '/checkout/delivery/'
export const personalDetailsPagePath = '/checkout/personal-details/'
export const findVehiclePath = '/find-vehicle/'
export const partExchangePagePath = '/checkout/part-exchange/'
export const financePagePath = '/checkout/payment/'
export const cinchCarePagePath = 'checkout/cinch-care/'
export const extrasPagePath = '/checkout/car-care/'

type Stages = { [Stage in CheckoutStage]?: string }

export const returnToCheckoutStage = (
  checkoutStep: CheckoutStage,
  activeOrder: ActiveOrder,
) => {
  const vehicle = activeOrder.vehicle
  const orderId = activeOrder.id
  const stages: Stages = {
    [CheckoutStage.PartExchange]: partExchangePagePath,
    [CheckoutStage.Finance]: financePagePath,
    [CheckoutStage.DeliverySlots]: deliveryPagePath,
    [CheckoutStage.Extras]: extrasPagePath,
    [CheckoutStage.CinchCare]: cinchCarePagePath,
    [CheckoutStage.Payment]: cardDetailsPagePath,
  }
  if (stages[checkoutStep]) {
    return window.location.assign(`${stages[checkoutStep]}${orderId}`)
  }
  return window.location.assign(`${personalDetailsPagePath}${vehicle.id}`)
}

const isOrderCheckoutStage = (
  maybeCheckoutStage: unknown,
): maybeCheckoutStage is OrderCheckoutStage =>
  Object.prototype.hasOwnProperty.call(maybeCheckoutStage, 'orderId') &&
  Object.prototype.hasOwnProperty.call(maybeCheckoutStage, 'stage')

export const getLastCheckoutStage = (
  activeOrder?: ActiveOrder,
): OrderCheckoutStage | undefined => {
  const storageLastCheckoutStage = isBrowser
    ? JSON.parse(sessionStorage?.getItem('checkout-last-visited-stage') ?? '{}')
    : null

  if (!activeOrder) {
    return
  }

  if (
    isOrderCheckoutStage(storageLastCheckoutStage) &&
    storageLastCheckoutStage.orderId === activeOrder?.id
  ) {
    return storageLastCheckoutStage
  }

  return {
    orderId: activeOrder.id,
    stage: CheckoutStage.PersonalDetails,
  }
}
