import { ReturnToCheckoutBanner } from '@cinch-labs/return-to-checkout-banner'
import {
  SharedUiHeader,
  StageName,
  trackEventCallback,
} from '@cinch-labs/shared-ui-header'
import { SharedUiHeaderV2 } from '@cinch-labs/shared-ui-header-v2'
import { SharedPromoBanner } from '@cinch-labs/shared-ui-promo-banner'
import { useFavouriteVehicles } from '@cinch-nx/data-favourites'
import { useOrdersStore } from '@cinch-nx/data-orders'
import { useVehicleCount } from '@cinch-nx/data-search'
import type { Hero as THero } from '@cinch-nx/data-storyblok'
import { useUserStore } from '@cinch-nx/data-user'
import { Env, readFromEnv } from '@cinch-nx/environments'

import {
  sendDigitalDataEvent,
  TrackingEvent,
  TrackingEventTypes,
  useAnalytics,
} from '@cinch-nx/shared-analytics'
import { SharedUiFooter } from '@cinch-nx/shared-ui-footer'
import { Gradient } from '@cinch-nx/shared-ui/lib/svg'
import { datadogRum } from '@datadog/browser-rum'
import { StoryData } from '@storyblok/react'
import cx from 'classnames'
import { AppProps as NextAppProps } from 'next/app'
import { ReactNode, useEffect } from 'react'

import Breadcrumbs from '../breadcrumbs/breadcrumbs'
import type { Breadcrumbs as TBreadcrumbs } from '../breadcrumbs/types'
import { Hero } from '../hero/hero'
import styles from './layout.module.css'

type withLayoutProps = {
  preview: boolean
  story?: StoryData<unknown>
  layoutProps: LayoutProps
}
// eslint-disable-next-line @typescript-eslint/ban-types
type PageComponentProps = {}

export interface AppPropsWithLayoutProps<PageProps = PageComponentProps>
  extends NextAppProps<PageProps> {
  pageProps: PageProps & withLayoutProps
}

export interface AppPropsWithLayoutPropsWithError<
  PageProps = PageComponentProps,
> extends AppPropsWithLayoutProps<PageProps & { err?: Error }> {
  err?: Error
  pageProps: PageProps & withLayoutProps
}

export interface LayoutProps {
  children?: ReactNode
  breadCrumbs?: TBreadcrumbs[]
  heroProps?: THero
  showBorderRadius?: boolean
  showFooterBorderRadius?: boolean
  useVerticalGradient?: boolean
  hasBleed?: boolean
  hasPromoBanner?: boolean
  shouldShowSharedUIHeaderV2?: boolean
}

const TempCheckoutModalPlaceHolder = () => (
  <div className={styles.v2Modal}>
    <h2 className={styles.v2ModalHeading}>Checkout modal</h2>
  </div>
)

export function Layout({
  children,
  breadCrumbs,
  heroProps,
  showBorderRadius = false,
  showFooterBorderRadius = true,
  useVerticalGradient,
  hasBleed,
  hasPromoBanner = true,
  shouldShowSharedUIHeaderV2 = false,
}: LayoutProps) {
  const showHeroInHeader = !!heroProps
  const trackEvent = useAnalytics((state) => state.trackEvent)

  const {
    refreshActiveOrder,
    checkIfVehicleIsReserved,
    extendOrderExpiryDate,
    activeOrder,
    cancelOrder,
  } = useOrdersStore()

  const { isLoggedIn, firstName, token } = useUserStore((state) => ({
    isLoggedIn: state.status === 'valid',
    firstName: state.profile?.firstName,
    token: state.token,
  }))

  const stageName = (process.env['STAGE_NAME'] ?? 'local') as StageName

  const { favouritesData } = useFavouriteVehicles()
  const favourites = favouritesData?.favouriteVehicles ?? []
  const { vehicleCount } = useVehicleCount()

  useEffect(() => {
    const internalAPIs = [
      'landing-www.snc-prod.aws.cinch.co.uk',
      'landing-articles.snc-prod.aws.cinch.co.uk',
      'landing-categories.snc-prod.aws.cinch.co.uk',
    ]
    if (
      !window.location?.href.includes('_storyblok') &&
      !window.location?.href.endsWith('sitemap.xml') &&
      internalAPIs.some((p) => window.location?.href.includes(p)) &&
      process.env['STAGE_NAME'] === 'production'
    ) {
      window.location.href = `https://www.cinch.co.uk${
        window.location.pathname ?? ''
      }`
    }
  }, [])

  const handleTrackEvent = (data: Record<string, unknown>) => {
    trackEvent({
      type: TrackingEventTypes.ADOBE,
      eventName: data['name'],
      data,
    } as unknown as TrackingEvent)

    sendDigitalDataEvent(data)
  }

  return (
    <>
      {hasPromoBanner && (
        <SharedPromoBanner
          name="sale"
          sendDigitalDataEvent={sendDigitalDataEvent}
        />
      )}

      <div
        className={cx(styles.headerContainer, {
          [styles.verticalGradient]: useVerticalGradient,
          [styles.borderRadiusHeader]: showBorderRadius,
          [styles.headerHasBleed]: hasBleed,
        })}
      >
        {!shouldShowSharedUIHeaderV2 && (
          <SharedUiHeader
            stageName={stageName}
            isLoggedIn={isLoggedIn}
            accessToken={token?.access_token || ''}
            firstName={firstName}
            favourites={favourites}
            refreshActiveOrder={refreshActiveOrder}
            activeOrder={activeOrder}
            checkIfVehicleIsReserved={checkIfVehicleIsReserved}
            extendOrderExpiryDate={extendOrderExpiryDate}
            cancelOrder={cancelOrder}
            trackEvent={trackEvent as trackEventCallback}
            vehicleCount={vehicleCount}
            adminFeeFlag={readFromEnv(Env.AdminFeeReturnToCheckout) === 'true'}
            adminFeeValueInPence={Number(readFromEnv(Env.AdminFeeValue))}
          >
            {showHeroInHeader ? <Hero {...heroProps} /> : undefined}
          </SharedUiHeader>
        )}

        {shouldShowSharedUIHeaderV2 && (
          <SharedUiHeaderV2
            handleTrackEvent={(data: Record<string, unknown>) => {
              handleTrackEvent(data)
            }}
            stageName={stageName}
            isLoggedIn={isLoggedIn}
            accessToken={token?.access_token || ''}
            firstName={firstName}
            favourites={favourites}
            refreshActiveOrder={refreshActiveOrder}
            activeOrder={activeOrder || undefined}
            checkoutModal={<TempCheckoutModalPlaceHolder />}
          >
            {showHeroInHeader ? <Hero {...heroProps} /> : undefined}
          </SharedUiHeaderV2>
        )}
      </div>

      <main
        id="main-content"
        className={cx({
          [styles.borderRadiusMain]: showBorderRadius,
        })}
      >
        {breadCrumbs && <Breadcrumbs breadcrumbs={breadCrumbs} />}
        <Gradient />
        {children}
      </main>

      <ReturnToCheckoutBanner
        activeOrder={activeOrder}
        extendOrderExpiry={extendOrderExpiryDate}
        refreshActiveOrder={refreshActiveOrder}
        sendDigitalDataEvent={sendDigitalDataEvent}
        reportDatadogError={(error, ctx) => datadogRum.addError(error, ctx)}
        accessToken={token?.access_token}
        adminFeeFlag={readFromEnv(Env.AdminFeeReturnToCheckout) === 'true'}
        adminFeeValueInPence={Number(readFromEnv(Env.AdminFeeValue))}
      />
      <SharedUiFooter showFooterBorderRadius={showFooterBorderRadius} />
    </>
  )
}

export default Layout
