export const Heart = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 32 32"
    fill="currentColor"
  >
    <path
      fillRule="evenodd"
      strokeLinejoin="round"
      d="M16.064 10.162l1.607-1.607a6.023 6.023 0 0 1 8.518 0 6.241 6.241 0 0 1 .154 8.667l-9.547 10.252a1 1 0 0 1-1.463 0L5.785 17.222a6.241 6.241 0 0 1 .155-8.667 6.023 6.023 0 0 1 8.518 0l1.606 1.607z"
    />
  </svg>
)
