import cx from 'classnames'
import styles from './favourites.module.css'
import { useContext } from 'react'
import { Heart } from '../../../assets/heart'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'

export const Favourites = () => {
  const { logicStore, userStore } = useContext(SharedUiHeaderV2Context)
  const { favourites } = userStore
  const favouritesAmount = favourites?.length ?? 0
  const { handleTrackEvent } = logicStore

  return (
    <div className={styles.link}>
      <a
        href="/my-profile/favourites"
        aria-label={'View your favourites'}
        onClick={() =>
          handleTrackEvent({
            name: 'click',
            category: 'mega menu',
            label: 'saved favourites',
            action: 'button',
          })
        }
        data-testid="Favourites/Anchor"
      >
        <div className={styles.svg}>
          <Heart />
        </div>
        {favouritesAmount > 0 && (
          <span
            data-testid="Favourites/Amount"
            className={cx(styles.number, styles.pulse)}
          >
            {favouritesAmount}
          </span>
        )}
      </a>
    </div>
  )
}
