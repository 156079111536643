import { CategoryPreferences, Destination } from '../cookie-consent.types'
import { groupDestinations } from '../utils/group-destinations'

export class SegmentPreferences {
  private destinations: Destination[]
  private setPreferences: (preferences: CategoryPreferences) => void

  constructor(
    destinations: Destination[],
    setPreferences: (preferences: CategoryPreferences) => void,
  ) {
    this.destinations = destinations
    this.setPreferences = setPreferences
  }

  setCategoryPreference = (
    selectedCategory: string,
    isSelected: boolean,
  ): void => {
    const destination = this.destinations.find(
      (x: Destination) => x.category === selectedCategory,
    )
    if (destination) {
      this.setPreferences({
        [destination.id]: isSelected,
      })
    }
  }

  getSelectedDestinations = (preferences: CategoryPreferences): Destination[] =>
    this.destinations.filter(
      (dest: Destination) => preferences[dest.id] === true,
    )

  getDestinationsGroupedByCategory = () =>
    groupDestinations(this.destinations, 'category')
}
