export type ContactIcons = 'mail' | 'phone'

export type ChildMenuNames = 'findACar' | 'howCinchWorks' | 'newsAndReviews'
export type DeviceType = 'mobile' | 'desktop'
interface BaseMenu {
  sectionTitle: string
  deviceType?: DeviceType
}

interface SharedNavAttributes {
  label: string
  activeMenu: ChildMenuNames | ''
  onButtonClick: (menu: ChildMenuNames) => void
  ariaLabel?: string
}
export interface NavLink extends SharedNavAttributes {
  type: 'link'
  href: string
}

export interface NavParent extends SharedNavAttributes {
  type: 'parent'
  child: ChildMenuNames
}

export interface NavContact extends SharedNavAttributes {
  type: 'contact'
  icon: ContactIcons
  href: string
}

type OmitProps<T> = Omit<T, 'onButtonClick' | 'activeMenu'>

export type NavProps = NavLink | NavParent | NavContact

export type NavigationItemLink = OmitProps<NavLink>

export type NavigationItemParent = OmitProps<NavParent>

export type NavigationItemContact = OmitProps<NavContact>

type NavigationItem =
  | NavigationItemLink
  | NavigationItemContact
  | NavigationItemParent

export interface ParentMenu extends BaseMenu {
  menuItems: NavigationItem[]
}

export interface ChildMenu extends BaseMenu {
  menuItems: OmitProps<NavLink>[]
}

export const parentMenu: ParentMenu[] = [
  {
    sectionTitle: 'Buy a car',
    menuItems: [
      {
        type: 'parent',
        label: 'Find a car',
        child: 'findACar',
      },
      {
        type: 'link',
        href: '/car-finance',
        label: 'Car finance',
      },
      {
        type: 'link',
        href: '/part-exchange',
        label: 'Part exchange',
      },
    ],
  },
  {
    sectionTitle: 'Get to know us',
    menuItems: [
      {
        type: 'parent',
        label: 'How cinch works',
        child: 'howCinchWorks',
      },
      {
        type: 'parent',
        label: 'News & reviews',
        child: 'newsAndReviews',
      },
    ],
  },
  {
    sectionTitle: 'Contact us',
    menuItems: [
      {
        type: 'contact',
        icon: 'phone',
        label: '0333 015 4101',
        href: 'tel:03330154101',
      },
      {
        type: 'contact',
        icon: 'mail',
        label: 'hello@cinch.co.uk',
        href: 'mailto:hello@cinch.co.uk',
      },
    ],
  },
]

const findACar: ChildMenu[] = [
  {
    sectionTitle: 'Find a car',

    menuItems: [
      {
        type: 'link',
        label: 'View all cars',
        href: '/find-vehicle',
      },
      {
        type: 'link',
        label: 'Help Me Choose',
        href: '/help-me-choose/most-important',
      },
      {
        type: 'link',
        label: 'Electric cars',
        href: '/electric-cars',
      },
    ],
  },
  {
    sectionTitle: 'Popular makes',
    menuItems: [
      {
        type: 'link',
        label: 'Ford',
        href: '/find-vehicle?make=Ford',
      },
      {
        type: 'link',
        label: 'BMW',
        href: '/find-vehicle?make=BMW',
      },
      {
        type: 'link',
        label: 'Mercedes-Benz',
        href: '/find-vehicle?make=Mercedes-Benz',
      },
      {
        type: 'link',
        label: 'Vauxhall',
        href: '/find-vehicle?make=Vauxhall',
      },
      {
        type: 'link',
        label: 'Volkswagen',
        href: '/find-vehicle?make=Volkswagen',
      },
      {
        type: 'link',
        label: 'Audi',
        href: '/find-vehicle?make=Audi',
      },
      {
        type: 'link',
        label: 'Land Rover',
        href: '/find-vehicle?make=Land%20Rover',
      },
      {
        type: 'link',
        label: 'Nissan',
        href: '/find-vehicle?make=Nissan',
      },
      {
        type: 'link',
        label: 'KIA',
        href: '/find-vehicle?make=KIA',
      },
      {
        type: 'link',
        label: 'Mini',
        href: '/find-vehicle?make=Mini',
      },
    ],
  },
  {
    sectionTitle: 'Year',
    menuItems: [
      {
        type: 'link',
        label: '2021',
        href: '/find-vehicle?fromYear=2021&toYear=2021',
      },
      {
        type: 'link',
        label: '2020',
        href: '/find-vehicle?fromYear=2020&toYear=2020',
      },
      {
        type: 'link',
        label: '2019',
        href: '/find-vehicle?fromYear=2019&toYear=2019',
      },
      {
        type: 'link',
        label: '2018',
        href: '/find-vehicle?fromYear=2018&toYear=2018',
      },
      {
        type: 'link',
        label: '2017 and older',
        href: '/find-vehicle?fromYear=-1&toYear=2017',
      },
    ],
  },
]

const howCinchWorks: ChildMenu[] = [
  {
    sectionTitle: 'How cinch works',
    menuItems: [
      {
        type: 'link',
        label: 'About cinch',
        href: '/about-us',
      },
      {
        type: 'link',
        label: 'Our promise to you',
        href: '/our-commitment',
      },
      {
        type: 'link',
        label: 'Quality checks',
        href: '/car-inspection',
        ariaLabel: 'quality checks',
      },
      {
        type: 'link',
        label: 'Money-back guarantee',
        href: '/returns',
      },
      {
        type: 'link',
        label: 'Home delivery',
        href: '/delivery',
      },
      {
        type: 'link',
        label: 'Collection',
        href: '/car-collections',
      },
      {
        type: 'link',
        label: 'Support',
        href: '/support',
      },
    ],
  },
  {
    sectionTitle: 'Car care',
    menuItems: [
      {
        type: 'link',
        label: 'Warranty, service, breakdown',
        href: '/cinch-care',
      },
      {
        type: 'link',
        label: 'Electric car charging',
        href: '/car-care/charging-electric-cars',
      },
    ],
  },
]

const newsAndReviews: ChildMenu[] = [
  {
    sectionTitle: 'News & reviews',
    menuItems: [
      {
        type: 'link',
        label: 'Latest news',
        href: '/news',
      },
      {
        type: 'link',
        label: 'Guides',
        href: '/guides',
      },
      {
        type: 'link',
        label: 'Announcements',
        href: '/announcements/cinch-news',
      },
      {
        type: 'link',
        label: 'Partnerships',
        href: 'https://www.cinchpresents.co.uk/partners ',
      },
      {
        type: 'link',
        label: 'Competitions',
        href: 'https://www.cinchpresents.co.uk/competitions',
      },
    ],
  },
  {
    sectionTitle: '',
    menuItems: [
      {
        type: 'link',
        label: 'Car reviews',
        href: '/reviews',
      },
      {
        type: 'link',
        label: 'Jargon buster',
        href: '/jargon',
      },
    ],
  },
]

export const secondaryMenu = {
  findACar,
  howCinchWorks,
  newsAndReviews,
}

export interface menuArticle {
  sectionHeading?: string
  content: {
    title: string
    body: string
    linkText: string
    linkUrl: string
    image: string
  }
  isSlimContainer?: boolean
}

export const latestCarReview: menuArticle = {
  sectionHeading: 'Latest car review',
  content: {
    title: '2022 Range Rover review',
    body: 'Fully revamped Range Rover in top-grade form is as lovely as you’d expect.',
    linkText: 'Read our 2022 Range Rover review',
    linkUrl: '/used-cars/land-rover/2022-range-rover/review',
    image: 'https://a.storyblok.com/f/143588/x/ce24c54c18/rangeroverleadjpg',
  },
}
