import { useContext } from 'react'

import { SharedUiHeaderContext } from '../../../shared-ui-header.context'
import { ChildMenuNames, secondaryMenu } from '../../shared/menu-links'
import MenuListItemLink from '../../shared/menu-list-item-link/menu-list-item-link'
import MenuListItem from '../../shared/menu-list-item/menu-list-item'
import MenuListSection from '../../shared/menu-list-section/menu-list-section'
import MenuList from '../../shared/menu-list/menu-list'
import styles from '../mobile-navigation.module.css'

export interface MobileSecondaryMenuProps {
  activeMenu: ChildMenuNames
}

const MobileSecondaryMenu: React.FC<MobileSecondaryMenuProps> = ({
  activeMenu,
}) => {
  const { vehicleCount } = useContext(SharedUiHeaderContext)

  const getVehicleCount = (linkName: string) => {
    if (linkName === 'View all cars' && vehicleCount) {
      return (
        <span className={styles.vehicleCount} data-testid="vehicleCount">
          {`(${Number(vehicleCount).toLocaleString()})`}
        </span>
      )
    }
    return null
  }

  const allowedSmallText = new Set(['Popular makes', 'Year'])
  const customStyleList: { [key: string]: string } = {
    newsAndReviews: styles.newsAndReviews,
  }
  const menuList =
    activeMenu &&
    secondaryMenu[activeMenu].filter(
      (menuItem) => !menuItem.deviceType || menuItem.deviceType === 'mobile',
    )

  return (
    <MenuList>
      {menuList?.map(({ sectionTitle, menuItems }) => (
        <MenuListSection
          title={sectionTitle}
          key={sectionTitle}
          customClass={customStyleList[activeMenu]}
        >
          {menuItems.map(({ label, href, ariaLabel }) => (
            <MenuListItem key={label}>
              <MenuListItemLink
                label={label}
                href={href}
                smallText={allowedSmallText.has(sectionTitle)}
                parentDropdown={menuList[0].sectionTitle}
                ariaLabel={ariaLabel}
              >
                {label}
                {getVehicleCount(label)}
              </MenuListItemLink>
            </MenuListItem>
          ))}
        </MenuListSection>
      ))}
    </MenuList>
  )
}

export default MobileSecondaryMenu
