import { TrackingEvent, TrackingEventTypes } from '../../types/analytics'

const commonTrackingData: TrackingEvent = {
  eventName: `Mega Nav`,
  data: {
    event: {
      name: 'click',
      category: 'button',
      action: 'mega menu',
    },
  },
  type: TrackingEventTypes.ADOBE,
}

export function navigationTrackingDetails(label: string, value?: string) {
  const output = { ...commonTrackingData }
  if (output.data) {
    output.data.event.label = label
    if (value && value !== label) {
      output.data.event.value = value
    }
  }
  return output
}
