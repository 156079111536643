import { getToken } from '@cinch-labs/shared-util'
import { Env, readFromEnv } from '@cinch-nx/environments'
import axios, { AxiosInstance, AxiosStatic } from 'axios'
import create from 'zustand'

import { ActivateCinchCare } from '../index'
import { checkCinchCareEligibility } from './helpers/check-cinchcare-eligibility'
import { mapCinchCareOrderToVehicle } from './helpers/map-cinch-care-order-to-vehicle'
import type {
  CinchCareOrdersResponse,
  OrderDetailsResponse,
  OrderDetailsStatus,
  OrdersStore,
} from './types'

export interface OrderStoreFactoryOptions {
  orderServiceUrl: string
  cinchCareServiceUrl: string
  authKey: string
  legacyAuthKey: string
}

let ordersConfig: OrderStoreFactoryOptions = {
  orderServiceUrl: '',
  cinchCareServiceUrl: '',
  authKey: '',
  legacyAuthKey: '',
}

let httpClient: AxiosInstance = axios

export const initOrdersStore = (
  config?: Partial<OrderStoreFactoryOptions>,
  axiosImpl: AxiosStatic = axios,
) => {
  ordersConfig = {
    orderServiceUrl: readFromEnv(Env.OrderServiceUrl),
    cinchCareServiceUrl: readFromEnv(Env.CinchcareServiceUrl),
    authKey: readFromEnv(Env.AuthKey),
    legacyAuthKey: readFromEnv(Env.LegacyAuthKey),
    ...config,
  }
  const _httpClient = axiosImpl.create()
  _httpClient.interceptors.request.use((request) => {
    const token = getToken({
      authKey: ordersConfig.authKey,
      legacyAuthKey: ordersConfig.legacyAuthKey,
    })

    return {
      ...request,
      headers: {
        ...request.headers,
        Authorization: `Bearer ${token?.access_token}`,
      },
    }
  })

  httpClient = _httpClient
}

/**
 * @description
 * Vehicle orders and cinchCare orders are dependent upon one another
 * cinchCare orders require the vehicle object from the order, matched by vehicle VRM
 * vehicle orders have a boolean flag to indicate their eligibility for cinchCare
 *
 * Because of this cross dependency, the functions getVehicleOrders and getCinchCareOrders
 * should be used to retrieve the enriched objects from the store instead of accessing the
 * raw response arrays inside vehicleOrdersRequest and cinchCareOrdersRequest.
 *
 * These functions will enrich the raw response each time they are requested from the store,
 * ensuring the most up-to-date data
 */

export const useOrdersStore = create<OrdersStore>((set, get) => ({
  vehicleOrdersRequest: { status: 'pending', orders: [] },

  cinchCareOrdersRequest: { status: 'pending', orders: [] },

  activeOrder: undefined,

  vehicleOrderAddingCinchCare: undefined,

  setVehicleOrderAddingCinchCare: (order) =>
    set({ vehicleOrderAddingCinchCare: order }),

  refreshActiveOrder: async () => {
    try {
      const { orderServiceUrl } = ordersConfig
      const activeOrder = (
        await httpClient.get<
          { latestActiveOrder: OrderDetailsResponse } | undefined
        >(`${orderServiceUrl}/orders/latest/active`)
      ).data
      set(() => ({ activeOrder: activeOrder?.latestActiveOrder }))
    } catch {
      // TODO - handle these errors, most likely is 401 if token expires
    }
  },

  checkIfVehicleIsReserved: async (vehicleId) => {
    const { orderServiceUrl } = ordersConfig

    if (!vehicleId) {
      return { reserved: true }
    }

    return (
      await httpClient.get<{ reserved: boolean }>(
        `${orderServiceUrl}/reservation-status/${vehicleId}?userId=`,
      )
    ).data
  },

  extendOrderExpiryDate: async (orderId: string, token: string) => {
    const { orderServiceUrl } = ordersConfig

    try {
      await httpClient.post<OrderDetailsResponse>(
        `${orderServiceUrl}/order/extend-expiry`,
        { orderId },
        { headers: { Authorization: `Bearer ${token}` } },
      )
      await get().fetchOrders()
    } catch {
      return set(() => ({
        vehicleOrdersRequest: { status: 'invalid', orders: undefined },
      }))
    }
  },

  cancelOrder: async (
    orderId: string,
    cancelOrderReason: string,
    token: string,
  ) => {
    const { orderServiceUrl } = ordersConfig

    return await httpClient.post<OrderDetailsResponse>(
      `${orderServiceUrl}/order/cancel-order`,
      { orderId, cancelOrderReason },
      { headers: { Authorization: `Bearer ${token}` } },
    )
  },

  cinchCareOrders: () => {
    const {
      vehicleOrdersRequest: { orders: vehicleOrders },
      cinchCareOrdersRequest: { orders: cinchCareOrders },
    } = get()

    return [...(cinchCareOrders ?? [])].map((cinchCareOrder) =>
      mapCinchCareOrderToVehicle(cinchCareOrder, vehicleOrders),
    )
  },

  vehicleOrders: () => {
    const {
      vehicleOrdersRequest: { orders: vehicleOrders },
      cinchCareOrdersRequest: { orders: cinchCareOrders },
    } = get()

    return [...(vehicleOrders ?? [])].map((order) => ({
      order,
      eligibleForCinchCare: checkCinchCareEligibility(order, cinchCareOrders),
    }))
  },

  vehicleOrdersByStatus: (...statuses) =>
    get()
      .vehicleOrders()
      ?.filter((vehicleOrder) =>
        statuses.includes(vehicleOrder.order.orderStatus as OrderDetailsStatus),
      ),

  fetchOrders: async () => {
    try {
      const { orderServiceUrl } = ordersConfig

      const { orders } = (
        await httpClient.get<{ orders: OrderDetailsResponse[] }>(
          `${orderServiceUrl}/orders`,
        )
      ).data
      return set(() => ({
        vehicleOrdersRequest: { status: 'valid', orders },
      }))
    } catch {
      return set(() => ({
        vehicleOrdersRequest: { status: 'invalid', orders: undefined },
      }))
    }
  },

  fetchCinchCareOrders: async () => {
    try {
      const { cinchCareServiceUrl } = ordersConfig

      const { data: cinchCareOrders } = await httpClient.get<
        CinchCareOrdersResponse[]
      >(`${cinchCareServiceUrl}/orderdetails`)

      set(() => ({
        cinchCareOrdersRequest: {
          status: 'valid',
          orders: cinchCareOrders,
        },
      }))

      return
    } catch {
      set(() => ({
        cinchCareOrdersRequest: { status: 'invalid', orders: undefined },
      }))
      return
    }
  },

  activateCinchCare: async (payload: ActivateCinchCare) => {
    const { cinchCareServiceUrl } = ordersConfig

    const activateCinchCareUrl = `${cinchCareServiceUrl}/cinchcare/activate`

    return httpClient.post(activateCinchCareUrl, payload)
  },
}))
