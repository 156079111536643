import { ReactElement } from 'react'
import { ArrowRight } from '../../../assets/arrow-right'
import styles from './list-wrapper.module.css'

export interface ListWrapper {
  children: ReactElement | null
  heading: string
  href: string
  urlText: string
  handleTrackEvent: () => void
}

export const ListWrapper = ({
  children,
  heading,
  urlText,
  href,
  handleTrackEvent,
}: ListWrapper) => {
  const handleLinkTrackingEvent = () => {
    handleTrackEvent()
  }

  return (
    <div className={styles.listWrapperContainer}>
      <h2 className={styles.heading}>{heading}</h2>
      {children}
      <a
        className={styles.wrapperLink}
        href={href}
        onClick={handleLinkTrackingEvent}
      >
        {urlText}{' '}
        <div className={styles.wrapperLinkIcon}>
          <ArrowRight />
        </div>{' '}
      </a>
    </div>
  )
}
