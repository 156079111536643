import { CookieConsent } from '@cinch-nx/cookie-consent'
import { useAnalytics } from '@cinch-nx/shared-analytics'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'

import useCookieModalOpen from '../hooks/cookie-modal-open.hook'

const CookieConsentWrapper: React.FC = () => {
  const { isPreferencesModalOpen, setIsPreferencesModalOpen } =
    useCookieModalOpen()
  const setHasLoadedScripts = useAnalytics((s) => s.setHasLoadedScripts)
  const [routeCount, setRouteCount] = useState(0)
  const router = useRouter()

  const incrementRouteCount = useCallback(() => {
    setRouteCount(routeCount + 1)
  }, [routeCount])

  useEffect(() => {
    router.events.on('routeChangeComplete', incrementRouteCount)

    return () => {
      router.events.off('routeChangeComplete', incrementRouteCount)
    }
  }, [router.events, incrementRouteCount])

  return (
    <CookieConsent
      count={routeCount}
      setLoaded={setHasLoadedScripts}
      setIsPreferencesModalOpen={setIsPreferencesModalOpen}
      isPreferencesModalOpen={isPreferencesModalOpen}
    />
  )
}
export default CookieConsentWrapper
