import priorityLinks from './priority-links'
import Quicklink from 'quicklink'

/**
 * This is called after mega nav is mounted on the client-side.
 * Adds 500ms grace period *after* the nav has mounted before prefetching the list of priority links.
 * After 2000ms of idle time, it will then start optimistically prefetching
 * any links in the viewport.
 * @param quicklink
 */
const initQuicklink = (quicklink: typeof Quicklink) => {
  if (typeof window === 'undefined') {
    throw new ReferenceError(
      `The function 'initQuicklink' must be called once the window object is available (Hint: Have you ensured this is within a useEffect hook?).`,
    )
  }

  if (process.env.NODE_ENV !== 'production') {
    console.info(
      'Skipping Quicklink prefetching due to non-production environment',
    )
    return
  }

  const navBarEl = document.querySelector('#nav-bar')
  if (navBarEl === null) {
    throw new TypeError(
      `The function 'initQuicklink' requires an element with the id 'nav-bar' to exist in the DOM.`,
    )
  }

  setTimeout(async () => {
    try {
      await quicklink.prefetch(priorityLinks)
    } catch (error) {
      // This triggers when the link returns a 404
      console.warn(
        `Unable to prefetch priority link: ${
          (error as unknown as { target: { href: string } }).target?.href
        }`,
      )
    }
    quicklink.listen({
      el: navBarEl,
      timeout: 2000,
      throttle: 1, // Limit fetching concurrency
      delay: 200, // The amount of time each link needs to stay inside the viewport before being prefetched
      ignores: (href) =>
        href === window.location.href ||
        href.startsWith(`${window.location.href}?`), // Dont try and prefetch the page that the user is on
    })
  }, 500)
}

export default initQuicklink
