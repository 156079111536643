export const MenuIcon = () => (
  <svg
    preserveAspectRatio="xMidYMid"
    height="32px"
    width="32px"
    fill="currentColor"
    viewBox="0 0 32 32"
    aria-hidden
  >
    <path
      fillRule="evenodd"
      d="M27.597 26.5H3.807c-2 0-2-3 0-3h23.79c2 0 2 3 0 3zm.017-18H3.806c-2 0-2-3 0-3h23.808c2 0 2 3 0 3zm-.017 9H3.807c-2 0-2-3 0-3h23.79c2 0 2 3 0 3z"
    />
  </svg>
)
