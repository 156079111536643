import { Env, readFromEnv } from '@cinch-nx/environments'
import { simpleAnalyticsTrack } from '@cinch-nx/shared-analytics'
import { ConsentManagerBuilder } from '@segment/consent-manager'
import { useEffect, useState } from 'react'

import CookieConsent from './cookie-consent'
import { CookieConsentProps } from './cookie-consent.types'
import { getCookieConsentPreferences } from './utils'

const CookieConsentContainer: React.FC<
  Pick<
    CookieConsentProps,
    | 'setLoaded'
    | 'count'
    | 'setIsPreferencesModalOpen'
    | 'isPreferencesModalOpen'
  >
> = ({
  count,
  setLoaded,
  setIsPreferencesModalOpen,
  isPreferencesModalOpen,
}) => {
  /**
   * This block is here due to LAN-1478. A race condition in our integration
   * with the Segment ConsentManagerBuilder component is causing fragility in
   * the rendering of the cookie banner. This code changes the React key after
   * a slight delay to force the component to remount, causing a re-render,
   * which reliably displays the cookie banner.
   */
  const [consentBuilderLoaded, setConsentBuilderLoaded] = useState(false)

  useEffect(() => {
    const isPreferenceCookieSet = getCookieConsentPreferences() ? true : false

    if (!isPreferenceCookieSet) {
      setTimeout(() => {
        if (window.analytics?.load) window.analytics.initialized = undefined
        setConsentBuilderLoaded(true)
      }, 600)

      simpleAnalyticsTrack('cookie_banner_shown')
    }
  }, [])

  return (
    <ConsentManagerBuilder
      writeKey={readFromEnv(Env.SegmentKey)}
      key={`cmbLoaded-${consentBuilderLoaded}`}
    >
      {(consentManager) => (
        <CookieConsent
          consentManager={consentManager}
          setLoaded={setLoaded}
          setIsPreferencesModalOpen={setIsPreferencesModalOpen}
          isPreferencesModalOpen={isPreferencesModalOpen}
          count={count}
        />
      )}
    </ConsentManagerBuilder>
  )
}

export default CookieConsentContainer
