import { TextFontWeights, TextLink } from '@cinch-labs/design-system'
import { useContext } from 'react'

import { SharedUiHeaderContext } from '../../../../shared-ui-header.context'
import type { NavLink } from '../../../shared/menu-links'
import { navigationTrackingDetails } from '../../../utils/tracking'
import styles from './desktop-navigation-link.module.css'

/**
 * DesktopNavigationLink is a root level meganav link that does not have a dropdown
 * @example 'Car Finance'
 */
const DesktopNavigationLink = (item: NavLink) => {
  const { trackEvent } = useContext(SharedUiHeaderContext)

  return (
    <TextLink
      data-testid="desktop-navigation-item-link"
      href={item.href}
      className={styles.navLink}
      isOnlyWhite
      hasNoLine
      textProps={{
        fontWeight: TextFontWeights.semibold,
      }}
      onClick={() => trackEvent?.(navigationTrackingDetails(item.label))}
    >
      <span className={styles.navLinkInner}>{item.label}</span>
    </TextLink>
  )
}

export default DesktopNavigationLink
