import type {
  DealerVehicleAdvertSummary,
  DirectVehicleAdvertSummary,
} from '@cinch-nx/data-vehicle'

import customEventNames from './custom-event-names'
import { getDataLayer } from './data-layer'
import trackCustomEvent from './track-custom-event'
import { AdobeDataLayer } from './types'

enum vehicleAction {
  seenInitial = 'seenInitial',
  seenMore = 'seenMore',
  notSeen = 'notSeen',
}

export let isInitialPageLoad = true

export const getVehicleReturned = ():
  | AdobeDataLayer['page']['vehicleReturned']
  | undefined => {
  const dl = getDataLayer()
  if (!dl) {
    return
  }
  if (dl && !dl.page.vehicleReturned) {
    dl.page.vehicleReturned = new Map()
  }

  return dl.page.vehicleReturned
}

export const trackNewVehicles = (
  vehicles: Array<
    Pick<
      DealerVehicleAdvertSummary | DirectVehicleAdvertSummary,
      'vehicleId' | 'webDealerProfileId' | 'price' | 'imageCount'
    >
  > = [],
) => {
  const dl = getVehicleReturned()
  if (!dl) {
    return
  }

  dl.clear()

  const mappedVehicles = vehicles.map((vehicle) => ({
    advertId:
      typeof vehicle.vehicleId === 'number'
        ? vehicle.vehicleId.toString()
        : vehicle.vehicleId,
    dealerId: vehicle.webDealerProfileId,
    imageCount: vehicle.imageCount,
    price:
      typeof vehicle.price === 'number'
        ? vehicle.price
        : Number.parseInt(vehicle.price.replace(/\D+/g, ''), 10),
  }))

  for (const vehicle of mappedVehicles) {
    dl.set(vehicle.advertId, {
      ...vehicle,
      action: vehicleAction.notSeen,
    })
  }

  trackCustomEvent({
    name: customEventNames.vehicleListingLoadSuccess,
    details: mappedVehicles,
  })
}

export const resetProductDisplay = () => {
  const dl = getVehicleReturned()
  if (!dl) {
    return
  }
  isInitialPageLoad = true
  dl.clear()
}

export const trackVehicleDisplay = (
  advertId: string,
  isDisplayed?: boolean,
  isInitialLoad?: boolean,
) => {
  const dl = getVehicleReturned()

  if (!dl) {
    return
  }
  const vehicle = dl.get(advertId)

  if (vehicle && isDisplayed) {
    const action =
      vehicle.action === vehicleAction.seenInitial || isInitialLoad
        ? vehicleAction.seenInitial
        : vehicleAction.seenMore

    dl.set(advertId, {
      ...vehicle,
      action,
    })
  }
}
