import cn from 'classnames'
import { useContext, useEffect, useState } from 'react'

import Heart from '../assets/heart.svg'
import {
  SharedUiHeaderContext,
  noOpTracking,
} from '../shared-ui-header.context'
import styles from './favourites-button.module.css'
import { useFavouritesButtonTracking } from './favourites-button.tracking'

export const FavouritesButton: React.FC = () => {
  const [showPulse, setShowPulse] = useState(false)
  const { favourites, trackEvent } = useContext(SharedUiHeaderContext)
  const favouritesAmount = favourites?.length ?? 0

  useEffect(() => {
    setShowPulse(true)
    const timeoutId = setTimeout(() => setShowPulse(false), 1000)
    return () => clearTimeout(timeoutId)
  }, [favouritesAmount])

  const { onFavouriteButtonClick } = useFavouritesButtonTracking(
    trackEvent ?? noOpTracking,
  )

  return (
    <a
      href="/my-profile/favourites"
      className={styles.link}
      aria-label={'View your favourites'}
      onClick={() => onFavouriteButtonClick()}
      data-testid="FavouritesButton/Anchor"
    >
      <Heart className={styles.svg} />
      {favouritesAmount > 0 && (
        <span
          data-testid="FavouritesButton/Amount"
          className={cn(styles.number, { [styles.pulse]: showPulse })}
        >
          {favouritesAmount}
        </span>
      )}
    </a>
  )
}
