export const BASKET_BANNER_CLOSED = 'cinch-checkout-basket-banner-closed'

export const getBasketBannerClosed = (): boolean => {
  if (typeof window !== 'undefined') {
    return JSON.parse(
      window.localStorage.getItem(BASKET_BANNER_CLOSED) ?? 'false',
    )
  }
  return false
}

export const setBasketBannerClosed = (value: boolean) => {
  if (typeof window !== 'undefined') {
    window.localStorage.setItem(BASKET_BANNER_CLOSED, value ? 'true' : 'false')
  }
}

export const clearBasketBannerClosed = () => {
  if (typeof window !== 'undefined') {
    window.localStorage.removeItem(BASKET_BANNER_CLOSED)
  }
}
