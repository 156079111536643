import { DetailedHTMLProps, ImgHTMLAttributes, useState } from 'react'

import { basketBannerVehicleImageClickEvent } from '../banner/banner-analytics'
import HoldingImage from '../holding-image/holding-image'
import { DigitalDataEvent } from '../types'

export interface ImageProps {
  src: string | undefined
  alt: string
  lazy: boolean
  sendDigitalDataEvent: (event: DigitalDataEvent) => void
  className?: string
  clickable?: boolean
}

export function Image({
  className,
  src,
  alt,
  lazy,
  sendDigitalDataEvent,
  clickable = false,
}: ImageProps) {
  const [hasError, updateErrorState] = useState(false)
  const updateError = () => {
    updateErrorState(true)
  }

  const defaultImageProps: DetailedHTMLProps<
    ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > = {
    className: className,
    src: src,
    alt: alt || 'Image',
    loading: lazy ? 'lazy' : 'eager',
    onError: updateError,
  }

  const imageProps: ImgHTMLAttributes<HTMLImageElement> = clickable
    ? {
        ...defaultImageProps,
        'aria-hidden': true,
        onClick: () =>
          sendDigitalDataEvent(basketBannerVehicleImageClickEvent()),
      }
    : defaultImageProps

  const { src: imageSrc, alt: imageAlt, ...restImageProps } = imageProps

  return !src || hasError ? (
    <HoldingImage className={className} text="Image Unavailable" />
  ) : (
    <img src={imageSrc} alt={imageAlt} {...restImageProps} />
  )
}

export default Image
