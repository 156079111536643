import type { FooterLink, SocialLink, SocialMediaLink } from '../types'

export const footerLinks: FooterLink[] = [
  { label: 'Used cars', href: '/used-cars' },
  { label: 'About us', href: '/about-us' },
  { label: 'Careers', href: 'https://careers.cinch.co.uk/' },
  { label: 'About delivery', href: '/delivery' },
  { label: 'About collections', href: '/car-collections' },
  { label: 'Which car should I buy', href: '/which-car-should-i-buy' },
  { label: 'Plug-in-hybrid cars', href: '/electric-cars/plug-in-hybrid-suvs' },
  { label: 'Small electric cars', href: '/electric-cars/small-cars' },
  { label: '4WD electric cars', href: '/electric-cars/4wd' },
  {
    label: 'Cheap hybrid cars',
    href: '/electric-cars/cheap-used-hybrid-cars',
  },
  {
    label: '7 seat electric cars',
    href: '/electric-cars/seven-seater',
  },
  { label: 'About returns', href: '/returns' },
  { label: 'Terms of use', href: '/terms-of-use' },
  { label: 'Privacy policy', href: '/policies/privacy' },
  { label: 'Cookie policy', href: '/policies/cookie-policy' },
  {
    label: 'Gender pay gap report',
    href: 'https://www.constellationautomotive.com/corporate-responsibility/gender-pay-gap-report',
  },

  {
    label: 'Modern slavery statement',
    href: '/policies/modern-slavery-statement',
  },
  { label: 'Support', href: '/support' },
]

export const socialLinks: SocialLink[] = [
  {
    name: 'WhatsApp',
    href: 'https://wa.me/447585658040',
    linkLabel: 'Start a conversation',
  },
  { name: 'Text us', href: 'sms:02033224567', linkLabel: '0203 322 4567' },
  { name: 'Call us', href: 'tel:03330154101', linkLabel: '0333 015 4101' },
  {
    name: 'Email us',
    href: 'mailto:hello@cinch.co.uk',
    linkLabel: 'hello@cinch.co.uk',
  },
]

export const socialMediaLinks: SocialMediaLink[] = [
  {
    name: 'Instagram',
    href: ' https://www.instagram.com/cinchuk/',
    icon: 'instagram',
  },
  { name: 'Twitter', href: 'https://twitter.com/cinchuk', icon: 'twitter' },
  {
    name: 'Facebook',
    href: ' https://www.facebook.com/cinchuk',
    icon: 'facebook',
  },
  {
    name: 'LinkedIn',
    href: 'https://www.linkedin.com/company/cinchuk',
    icon: 'linkedin',
  },
  {
    name: 'YouTube',
    href: 'https://www.youtube.com/c/cinch',
    icon: 'youtube',
  },
]
