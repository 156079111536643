import { AnalyticsTag } from './types'

export function buildTagDataAttr(tag: AnalyticsTag | undefined) {
  const tagMapping: Record<string, string> = {
    eventCategory: 'ec',
    eventLabel: 'el',
    eventAction: 'ea',
    eventType: 'et',
    eventValue: 'ev',
    event: 'en',
  }

  return (
    tag &&
    // eslint-disable-next-line unicorn/prefer-object-from-entries
    Object.keys(tag).reduce((newTag: Record<string, unknown>, key) => {
      const newKey = tagMapping[key] || key
      newTag[`data-${newKey}`] = tag[key as keyof typeof tag]

      return newTag
    }, {})
  )
}

export default buildTagDataAttr
