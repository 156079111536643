import Script from 'next/script'

const isProduction = process.env['STAGE_NAME'] === 'production'

const scripts = {
  development: 'https://scripts.simpleanalyticscdn.com/latest.dev.js',
  production: 'https://scripts.simpleanalyticscdn.com/latest.js',
}

export function SimpleAnalyticsScripts() {
  const hostname = isProduction
    ? 'www.cinch.co.uk'
    : 'cambridge.preview.aws.cinch.co.uk'

  return (
    <>
      {/** SA Scripts */}
      <Script
        id="SA"
        src={isProduction ? scripts['production'] : scripts['development']}
        strategy="beforeInteractive"
        data-allow-params="CID"
        data-hostname={hostname}
      />
      <noscript>
        <img
          src={`https://queue.simpleanalyticscdn.com/noscript.gif?hostname=${hostname}`}
          alt=""
          referrerPolicy="no-referrer-when-downgrade"
        />
      </noscript>
    </>
  )
}
