// Add name of third party script that is essential to the site running into string
// This list will be added into the 'Essential' row on the Cookie Banner preferences panel
export const essentialCinchScriptsList = 'Datadog, Stripe'

export const advertisingScriptList = [
  'Tool: DC - MediaCom DoubleClick & AdWords Global Tag & GA4 Code',
  'MediaCom - Floodlight Counter - Article Page Viewed',
  'MediaCom - Floodlight Counter - Checkout Page Viewed',
  'MediaCom - Floodlight Counter - Help me choose',
  'MediaCom - Floodlight Counter - Homepage',
  'MediaCom - Floodlight Counter - Manufacturer Page Viewed',
  'MediaCom - Floodlight Counter - My Profile',
  'MediaCom - Floodlight Counter - Recommended Cars',
  'MediaCom - Floodlight Counter - Vehicles Searched',
  'MediaCom - Floodlight Counter - Vehicle Viewed',
  'MediaCom - Floodlight Counter - User Completed Sign Up Form',
  'MediaCom - Quality Traffic Pixel',
  'MediaCom - Floodlight Sales - Order Confirmation',
  'MediaCom - Floodlight Counter - Call Seller Clicked',
  'MediaCom - Floodlight Counter - Visit Dealer Website',
  'MediaCom - Floodlight Counter - Submit Email Seller Form',
]
