import { useEffect, useState } from 'react'

import { CookieConsentPreference } from '../cookie-consent.types'
import {
  getCookieConsentPreferences,
  hasCookieConsentBeenSet,
  setCookieConsentPreferences,
} from '../utils/cookies'

export const defaultCookiePreferences: CookieConsentPreference = {
  advertising: false,
  analytics: false,
  functional: false,
}

export function useCookiePreferences(setLoaded?: (arg: boolean) => void) {
  const [cookiePreferences, setCookiePreferencesState] =
    useState<CookieConsentPreference>()

  useEffect(() => {
    const cookie = getCookieConsentPreferences()
    if (cookie) {
      setCookiePreferencesState(cookie)
    } else {
      setCookiePreferencesState(defaultCookiePreferences)
    }

    setLoaded?.(true)
  }, [])

  const setCookiePreferences = (state: CookieConsentPreference) => {
    setCookiePreferencesState(state)
    setCookieConsentPreferences(state)
  }

  return {
    cookiePreferences: cookiePreferences as CookieConsentPreference,
    setCookiePreferences,
    isCookieConsentSet: hasCookieConsentBeenSet(),
  }
}
