import { z } from 'zod'

import { cv, numericString, version } from '../../types/schema'
import { toUrlSearchParams } from '../../util/to-url-search-params'

export const getStoryBySlugParams = z
  .object({
    version,
    cv,
    sort_by: z.string().optional(),
    per_page: numericString().optional(),
    page: numericString().optional(),
    with_tag: z.string().optional(),
    resolve_relations: z.string().optional(),
  })
  .optional()

export const getStoryBySlugUrlParams =
  getStoryBySlugParams.transform(toUrlSearchParams)

export type GetStoryBySlugParams = z.infer<typeof getStoryBySlugParams>
export { slug } from '../../types/schema'
