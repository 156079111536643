import {
  EventActions,
  EventCategories,
  EventNames,
  trackEventCallback,
} from '../shared-ui-header.types'
import { TrackingEventTypes } from '../types/analytics'

export const useFavouritesButtonTracking = (trackEvent: trackEventCallback) => {
  const onFavouriteButtonClick = () => {
    const evt = {
      event: {
        name: EventNames.click,
        category: EventCategories.favourites,
        action: EventActions.click,
        label: 'profile_view_favourites',
      },
    }

    trackEvent({
      eventName: 'Profile - View favourites',
      data: evt,
      type: TrackingEventTypes.ADOBE,
    })
  }

  return { onFavouriteButtonClick }
}
