import axios from 'axios'
import useSWR from 'swr'

import { VEHICLE_SEARCH_URL } from '../../endpoints'
import type { VehicleListingResponse } from '../../types'

const fetcher = (url: string) => axios.get(url).then((res) => res.data)
export const useVehicleCount = () => {
  const URL = `${VEHICLE_SEARCH_URL}`

  const { data } = useSWR<VehicleListingResponse>(URL, fetcher)

  const vehicleCount = data?.searchResultsCount

  return { vehicleCount }
}
