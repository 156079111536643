import { datadogRum } from '@datadog/browser-rum'

// Add new custom action context to this type
type CinchContext = {
  vehicle?: {
    make?: string
    model?: string
  }
}

type AddDataDogUserAction = (name: string, context: CinchContext) => void

const addDataDogUserAction: AddDataDogUserAction = (name, context) => {
  datadogRum.addAction(name, context)
}

export { addDataDogUserAction }
