export const ChevronLeft = () => (
  <svg
    width="13"
    height="24"
    viewBox="0 0 13 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5303 0.46967C12.8232 0.762563 12.8232 1.23744 12.5303 1.53033L2.06066 12L12.5303 22.4697C12.8232 22.7626 12.8232 23.2374 12.5303 23.5303C12.2374 23.8232 11.7626 23.8232 11.4697 23.5303L0.46967 12.5303C0.329017 12.3897 0.249999 12.1989 0.249999 12C0.249999 11.8011 0.329017 11.6103 0.46967 11.4697L11.4697 0.46967C11.7626 0.176777 12.2374 0.176777 12.5303 0.46967Z"
      fill="#311B77"
    />
  </svg>
)
