import { SvgIcon } from '@cinch-labs/design-system'
import cx from 'classnames'

import LogoImg from '../../../assets/logo.svg'
import styles from './mobile-menu-header.module.css'

interface HeaderProps {
  close: () => void
  onMainMenuClick?: () => void
}

const Header: React.FC<HeaderProps> = ({ close, onMainMenuClick }) => {
  const isSecondaryMenu = !!onMainMenuClick

  const classNames = cx(styles.container, {
    [styles.boxshadow]: isSecondaryMenu,
    [styles.sticky]: isSecondaryMenu,
  })

  return (
    <div className={classNames}>
      {isSecondaryMenu ? (
        <button
          onClick={onMainMenuClick}
          className={cx(styles.button, styles.mainMenuButton)}
          aria-label="Back to main menu"
        >
          <SvgIcon
            name="chevron-left"
            color="deepPurple"
            size={16}
            className={styles.mainMenuIcon}
          />
          <span>Main menu</span>
        </button>
      ) : (
        <LogoImg />
      )}
      <div className={styles.linkContainer}>
        <a
          href="/my-profile"
          className={styles.link}
          aria-label="Go to profile page"
        >
          <SvgIcon name="owner-user" color="deepPurple" />
        </a>
        <button
          className={styles.closeButton}
          onClick={close}
          aria-label="Close menu"
        >
          <SvgIcon name="cross" color="deepPurple" />
        </button>
      </div>
    </div>
  )
}

export default Header
