import create from 'zustand'

import { ChildMenuNames } from '../navigation/shared/menu-links'

interface StateStore {
  activeMenu: ChildMenuNames | null
  setActiveMenu: (menu: ChildMenuNames | null) => void
}

const useActiveMenu = create<StateStore>((set) => ({
  activeMenu: null,
  setActiveMenu: (activeMenu) => set({ activeMenu }),
}))

export default useActiveMenu
