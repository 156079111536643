import { datadogRum } from '@datadog/browser-rum'
import { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import { ErrorBoundaryPage } from './error-boundary-page'

function ErrorFallback({ error }: { error: Error }) {
  const [retryUrl, setRetryUrl] = useState<string>('/')

  useEffect(() => {
    const { pathname } = window.location
    setRetryUrl(pathname)

    if (process.env['STAGE_NAME'] !== 'local') {
      datadogRum.addError(new Error(error.message), {
        error: {
          message: 'User shown error boundary',
          name: error.name,
          stack: error.stack,
        },
        route: pathname,
      })
    }
  }, [])

  return (
    <ErrorBoundaryPage
      errorTitle="Something went wrong..."
      errorText="We are attempting to resolve the issue but if it's taking too long you can use the buttons below to continue you journey"
      homeCtaText="Return home"
      searchCarsCtaText="Search cars"
      currentUrl={retryUrl}
    />
  )
}

export const ErrorBoundaryWrapper = ({
  children,
}: {
  children: React.ReactNode
}) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>{children}</ErrorBoundary>
)
