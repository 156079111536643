import { createContext } from 'react'

import { StageName, trackEventCallback } from './shared-ui-header.types'
import { TrackingEventTypes } from './types/analytics'

export const noOpTracking: trackEventCallback = (event) => {
  console.log(
    `noop::trackEvent::${
      event.type !== TrackingEventTypes.IDENTIFY && event.eventName
    }`,
    event,
  )
}

export interface SharedUiHeaderContextProps {
  stageName?: StageName
  isLoggedIn: boolean
  accessToken: string
  favourites: { createdAt: string; vehicleId: string }[]
  firstName?: string
  activeOrder?: {
    cancelledAt?: number
    expiresAt: number
    id: string
    vehicle: {
      id: string
      derivative: string
      imageUrl?: string
      make: string
      model: string
      priceInPence: number
    }
  }
  checkIfVehicleIsReserved: (
    vehicleId: string,
  ) => Promise<{ reserved: boolean }>
  extendOrderExpiryDate: (orderId: string, token: string) => Promise<void>
  refreshActiveOrder: () => Promise<void>
  trackEvent?: trackEventCallback
  vehicleCount: number | undefined
  cancelOrder: (
    orderId: string,
    cancelOrderReason: string,
    token: string,
  ) => Promise<{
    status: number
    data: { expiresAt?: number; cancelledAt?: number }
  }>
  adminFeeFlag?: boolean
  adminFeeValueInPence?: number
}

export const SharedUiHeaderContext = createContext<
  Partial<SharedUiHeaderContextProps>
>({
  isLoggedIn: false,
  favourites: [],
})
