import SVG from './svg'

export type { SVGProps } from './svg'

export {
  default as Gradient,
  DEFAULT_GRADIENT_ID,
  GRADIENT_IDS,
} from './svg-gradient'

// eslint-disable-next-line unicorn/prefer-export-from
export default SVG
