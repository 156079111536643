import { NavigationBasket } from '../basket'
import { FavouritesButton } from '../favourites-button/favourites-button'
import { Logo } from '../logo/logo'
import { MobileMenuButton } from '../mobile-menu-button/mobile-menu-button'
import DesktopNavigation from '../navigation/desktop-navigation/desktop-navigation'
import MobileNavigation from '../navigation/mobile-navigation/mobile-navigation'
import { ProfileButton } from '../profile-button/profile-button'
import { SharedUiHeaderContext } from '../shared-ui-header.context'
import styles from './navigation-bar.module.css'
import { useViewport } from '@cinch-labs/shared-util'
import cx from 'classnames'
import { useContext, useEffect, useState } from 'react'

export type NavigationBarProps = {
  showBottomBorder: boolean
}

export function NavigationBar({ showBottomBorder }: NavigationBarProps) {
  const { isLoggedIn } = useContext(SharedUiHeaderContext)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [inCheckoutJourney, setInCheckoutJourney] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { pathname } = window.location
      setInCheckoutJourney(pathname.includes('/checkout/'))
    }
  }, [])

  const { vw } = useViewport({
    updateOnResize: true,
    updateOnOrientationChange: true,
  })
  const isMobile = vw < 1024

  return (
    <div
      className={cx(styles.container, {
        [styles.greyBottomBorder]: showBottomBorder,
      })}
      data-testid="navigation-bar"
      id="nav-bar"
    >
      <div className={styles.inner}>
        <Logo variant="light" />
        <DesktopNavigation isDesktop={!isMobile} />
        <ProfileButton />
        {!inCheckoutJourney ? <NavigationBasket /> : null}
        {isLoggedIn ? <FavouritesButton /> : null}
        <MobileMenuButton onClick={() => setShowMobileMenu(!showMobileMenu)} />
        {isMobile && showMobileMenu ? (
          <MobileNavigation
            onClose={() => setShowMobileMenu(false)}
            isOpen={showMobileMenu}
          />
        ) : null}
      </div>
    </div>
  )
}

export default NavigationBar
