import {
  CinchCareOrdersResponse,
  OrderDetailsResponse,
  OrderDetailsVehicle,
} from '../types'

export interface CinchCareVehicle {
  plan: CinchCareOrdersResponse
  vehicle: OrderDetailsVehicle | undefined
}

export const mapCinchCareOrderToVehicle = (
  cinchCarePlan: CinchCareOrdersResponse,
  allVehicleOrders: OrderDetailsResponse[] | undefined,
): CinchCareVehicle => ({
  plan: cinchCarePlan,
  vehicle: allVehicleOrders?.find(
    (order) => order.vehicle.vrm === cinchCarePlan.vehicleVrm,
  )?.vehicle,
})
