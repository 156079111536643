import { useState, createContext, ReactElement, useEffect } from 'react'

export interface ActiveOrder {
  cancelledAt?: number
  expiresAt: number
  id: string
  vehicle: {
    id: string
    derivative: string
    imageUrl?: string
    make: string
    model: string
    priceInPence: number
  }
}

export interface UserStore {
  stageName: string
  isLoggedIn: boolean
  accessToken: string
  firstName: string | undefined
  favourites: { createdAt: string; vehicleId: string }[]
  activeOrder: ActiveOrder | undefined
  refreshActiveOrder: () => Promise<void>
}

export interface LogicStore {
  handleTrackEvent: (data: Record<string, unknown>) => void
}

export interface SharedUiHeaderV2ContextType {
  userStore: UserStore
  setUserStore: React.Dispatch<React.SetStateAction<UserStore>>
  logicStore: LogicStore
  setLogicStore: React.Dispatch<React.SetStateAction<LogicStore>>
}

export interface SharedUiHeaderV2ProviderProps extends UserStore, LogicStore {
  children: ReactElement
}

export const SharedUiHeaderV2Context = createContext(
  {} as SharedUiHeaderV2ContextType,
)

/* This will be used to make the call to checkout for basket icon */
const getCheckoutApi = () => console.log('useCheckoutApi')

export const SharedUiHeaderV2Provider = ({
  children,
  stageName,
  isLoggedIn,
  accessToken,
  firstName,
  favourites,
  activeOrder,
  handleTrackEvent,
  refreshActiveOrder,
}: SharedUiHeaderV2ProviderProps) => {
  const [userStore, setUserStore] = useState({
    stageName,
    isLoggedIn,
    accessToken,
    firstName,
    favourites,
    activeOrder,
    refreshActiveOrder,
  })

  const [logicStore, setLogicStore] = useState({
    handleTrackEvent: handleTrackEvent,
  })
  useEffect(() => {
    setUserStore({
      stageName,
      isLoggedIn,
      accessToken,
      firstName,
      favourites,
      activeOrder,
      refreshActiveOrder,
    })
  }, [
    stageName,
    isLoggedIn,
    accessToken,
    firstName,
    favourites,
    activeOrder,
    refreshActiveOrder,
  ])

  useEffect(() => {
    /* example of how to make the api call */
    const getNavData = async () => {
      getCheckoutApi()
    }
    getNavData()
  }, [])

  return (
    <SharedUiHeaderV2Context.Provider
      value={{
        userStore,
        setUserStore,
        logicStore,
        setLogicStore,
      }}
    >
      {children}
    </SharedUiHeaderV2Context.Provider>
  )
}
