import { z } from 'zod'

const component = z.object(
  {
    _uid: z.string(),
    _editable: z.string().optional(),
    component: z.string(),
  },
  { description: 'The Storyblok Component Type' },
)

const SbAlternateData = z.object({
  id: z.number(),
  name: z.string(),
  slug: z.string(),
  published: z.boolean(),
  full_slug: z.string(),
  is_folder: z.boolean(),
  parent_id: z.number(),
})

const TranslatedSlug = z.object({
  path: z.string(),
  name: z.string().nullable(),
  lang: z.string(),
})

export const link = z.object({
  id: z.number(),
  slug: z.string(),
  name: z.string(),
  is_folder: z.boolean(),
  parent_id: z.number().nullable(),
  published: z.boolean(),
  position: z.number(),
  uuid: z.string().uuid(),
  is_startpage: z.boolean(),
  path: z.string().nullable(),
  real_path: z.string().nullable().optional(),
})

export const StoryblokStoryData = z.object({
  alternates: z.array(SbAlternateData),
  content: component.passthrough(),
  created_at: z.string(),
  full_slug: z.string(),
  group_id: z.string(),
  id: z.number(),
  is_startpage: z.boolean(),
  meta_data: z.any(),
  name: z.string(),
  parent_id: z.number().nullable(),
  position: z.number(),
  published_at: z.string().nullable(),
  first_published_at: z.string().nullable(),
  slug: z.string(),
  lang: z.string(),
  translated_slugs: z.array(TranslatedSlug).optional().nullable(),
  default_full_slug: z.string().optional().nullable(),
  sort_by_date: z.string().nullable(),
  tag_list: z.array(z.string()),
  uuid: z.string(),
  path: z.string().nullable().optional(),
  release_id: z.string().nullable().optional(),
})

const SLUG_REGEX_VALIDATION = new RegExp(/^(\w+-?\/?)+/, 'gim')

const ensureInputIsNumber = (input: unknown) => {
  const result = Number.parseInt(String(input), 10)

  if (Number.isNaN(result)) {
    return input
  }

  return result
}

export const numericString = (...params: Parameters<typeof z.number>) =>
  z.preprocess(ensureInputIsNumber, z.number(params[0]))

export const version = z
  .union([z.literal('draft'), z.literal('published')])
  .optional()

export const cv = numericString({
  invalid_type_error: 'expected `cv` to be number',
}).optional()

interface SlugOptions {
  displayName: string
}

export const slug = ({ displayName = 'slug' }: SlugOptions) =>
  z
    .string({ required_error: `${displayName} is required` })
    .regex(
      SLUG_REGEX_VALIDATION,
      `expected ${displayName} to match 'some-slug' or 'some/slug'`,
    )

export type BaseComponent = z.infer<typeof component>
export type Story<Content extends BaseComponent> = z.infer<
  typeof StoryblokStoryData
> & {
  content: Content
}

export type Stories<Content extends BaseComponent> = Story<Content>[]
