import { ReactElement } from 'react'
import { createPortal } from 'react-dom'

export interface DropdownModalProps {
  children: ReactElement
}

export interface PortalProps {
  children: ReactElement
}

const Portal = ({ children }: PortalProps) => {
  const portalRoot = document.querySelector('#portal')
  return portalRoot ? createPortal(children, portalRoot) : null
}

export const DropdownModal = ({ children }: DropdownModalProps) => (
  <Portal>{children}</Portal>
)
