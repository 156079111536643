import { LogoContainer } from './components/logo-container/logo-container'
import styles from './shared-ui-header-v2.module.css'
import { UserProfile } from './components/user-profile/user-profile'
import { DesktopNav } from './components/desktop-nav/desktop-nav'
import { MobileNav } from './components/mobile-nav/mobile-nav'
import { PortalPlaceholder } from './components/portal-placeholder/portal-placeholder'
import {
  ActiveOrder,
  SharedUiHeaderV2Provider,
} from './context/context-shared-ui-header-v2'
import { useState } from 'react'
import { ReactElement } from 'react'

interface SharedUIHeaderV2Props {
  handleTrackEvent: (data: Record<string, unknown>) => void
  stageName: string
  isLoggedIn: boolean
  accessToken: string
  firstName: string | undefined
  favourites: { createdAt: string; vehicleId: string }[]
  children?: ReactElement | null
  refreshActiveOrder: () => Promise<void>
  activeOrder?: ActiveOrder | undefined
  checkoutModal?: ReactElement | null
}

export const SharedUiHeaderV2 = ({
  handleTrackEvent,
  stageName,
  isLoggedIn,
  accessToken,
  firstName,
  favourites,
  children,
  refreshActiveOrder,
  activeOrder,
  checkoutModal,
}: SharedUIHeaderV2Props): JSX.Element => {
  const [shouldShowDropdown, setShouldShowDropdown] = useState(false)

  const handleDesktopItemSelection = () => {
    setShouldShowDropdown(!shouldShowDropdown)
  }

  return (
    <SharedUiHeaderV2Provider
      stageName={stageName}
      isLoggedIn={isLoggedIn}
      accessToken={accessToken}
      firstName={firstName}
      handleTrackEvent={handleTrackEvent}
      favourites={favourites}
      refreshActiveOrder={refreshActiveOrder}
      activeOrder={activeOrder}
    >
      <div className={styles.container}>
        <nav className={styles.sharedUiHeader}>
          <LogoContainer />

          <DesktopNav
            shouldShowDropdown={shouldShowDropdown}
            handleDesktopItemSelection={handleDesktopItemSelection}
          />

          <MobileNav checkoutModal={checkoutModal} />

          <UserProfile checkoutModal={checkoutModal} />
          {children}
        </nav>

        {/* Portal sits outside of the nav (desktop dropdown) 
          and is called with the dropdownModal */}
        <PortalPlaceholder
          shouldShowDropdown={shouldShowDropdown}
          handleDesktopItemSelection={handleDesktopItemSelection}
        />
      </div>
    </SharedUiHeaderV2Provider>
  )
}
