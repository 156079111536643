import { ReactElement } from 'react'
import styles from './nav-items-wrapper.module.css'

export interface NavItemsWrapper {
  children: ReactElement
}

export const NavItemsWrapper = ({ children }: NavItemsWrapper) => (
  <div className={styles.navItemsWrapper}>{children}</div>
)
