import LogoImg from '../assets/logo.svg'
import styles from './logo.module.css'

export const Logo: React.FC<{ variant: 'light' | 'dark' }> = ({ variant }) => (
  <a
    data-testid={`navigation-bar-${variant}`}
    className={variant === 'dark' ? styles.logoDark : styles.logoLight}
    href="/"
  >
    <LogoImg />
    <span className={styles.logoText}>Cinch</span>
  </a>
)
