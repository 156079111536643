import {
  SharedUiHeaderContext,
  noOpTracking,
} from '../../../shared-ui-header.context'
import styles from '../../return-to-checkout-modal.module.css'
import { useReturnToCheckoutModalTracking } from '../../return-to-checkout-modal.tracking'
import { ModalState } from '../../return-to-checkout-modal.types'
import { VehicleInformation } from '../../vehicle-information/vehicle-information.component'
import { ReturnToCheckoutCardProps } from '../types'
import { Primary, Text } from '@cinch-labs/design-system'
import React, { useContext, useEffect } from 'react'

export interface ReturnToCheckoutExpiredStillAvailableProps
  extends ReturnToCheckoutCardProps {
  i18n: {
    text: string
    buttonText: string
  }
}

export const ReturnToCheckoutExpiredStillAvailable: React.FC<
  ReturnToCheckoutExpiredStillAvailableProps
> = ({ i18n, vehicle, onButtonClick, title, isButtonLoading }) => {
  const { trackEvent } = useContext(SharedUiHeaderContext)

  const { resumeCheckoutOpenedModal } = useReturnToCheckoutModalTracking(
    trackEvent ?? noOpTracking,
  )

  useEffect(() => {
    resumeCheckoutOpenedModal(ModalState.expiredStillAvailable)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.contentWrapper}>
      {title}
      <Text fontWeight={'light'} fontSize={'s'}>
        {i18n.text}
      </Text>
      <VehicleInformation vehicle={vehicle} />

      <Primary
        isLoading={isButtonLoading}
        classNames={{ button: styles.button }}
        onClick={onButtonClick}
        size="large"
        isFullWidth
      >
        {i18n.buttonText}
      </Primary>
    </div>
  )
}
