interface SetCookieArgs {
  name: string
  value: string
  expiryInDays: number
}

export const setCookie = ({
  name,
  value,
  expiryInDays,
}: SetCookieArgs): void => {
  const expiryDate: Date = new Date()
  expiryDate.setTime(expiryDate.getTime() + expiryInDays * 24 * 60 * 60 * 1000)
  const expires = `expires=${expiryDate.toUTCString()}`
  const isLocal = process.env['STAGE_NAME'] === 'local'
  document.cookie = isLocal
    ? `${name}=${value};${expires};path=/`
    : `${name}=${value};${expires};path=/;domain=cinch.co.uk`
}

interface GetCookieArgs {
  name: string
}

export const getCookie = ({ name }: GetCookieArgs): string | undefined =>
  document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() ?? undefined
