import { Checkbox, Modal, SlideUpPanel, Text } from '@cinch-labs/design-system'
import React, { useState } from 'react'

import {
  CinchCategoriesNameMapper,
  CookieConsentPreference,
  CookiePreferencesModalProps,
} from '../../cookie-consent.types'
import { groupDestinations, setupPreferences } from '../../utils'
import styles from './cookie-preferences-modal.module.css'

const CookiePreferencesModal: React.FC<CookiePreferencesModalProps> = ({
  segmentPreferences,
  destinationPreferences,
  existingCookiePreferences,
  onSaveCookiePreferences,
  onClose,
}) => {
  const isMobile = window?.innerWidth <= 767
  const ModalComponent = isMobile ? SlideUpPanel : Modal

  const [userCookiePreferences, setUserCookiePreferences] =
    useState<CookieConsentPreference>(existingCookiePreferences)

  const categoryPreferences = setupPreferences(
    segmentPreferences.getDestinationsGroupedByCategory(),
  )

  const cinchCategoriesNameMapper: CinchCategoriesNameMapper = {
    'Customer Success': 'functional',
    Advertising: 'advertising',
    Analytics: 'analytics',
  }

  const isCategoryChecked = (category: string) => {
    const categoryName = cinchCategoriesNameMapper[
      category
    ] as keyof CookieConsentPreference
    return userCookiePreferences && userCookiePreferences[categoryName]
  }

  const handleCheckboxChange = (
    category: string,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedDestinations = segmentPreferences.getSelectedDestinations(
      destinationPreferences,
    )
    const destinationsGroupedByCategory = groupDestinations(
      selectedDestinations,
      'category',
    )
    const selectedCategoryDestinationsExist =
      !!destinationsGroupedByCategory[category]

    // No idea what this second param is doing, looks like it was always set up to pass false and I'm not sure that's right..
    segmentPreferences.setCategoryPreference(
      category,
      selectedCategoryDestinationsExist ? false : true,
    )

    // Update local state for user preferences
    const categoryName = cinchCategoriesNameMapper[category]
    const isChecked = event.target.checked

    const updatedUserPreferences = {
      ...userCookiePreferences,
      [categoryName]: isChecked,
    } as CookieConsentPreference

    setUserCookiePreferences({
      ...existingCookiePreferences,
      ...updatedUserPreferences,
    })
  }

  return (
    <ModalComponent
      heading="My preferences"
      onClose={onClose}
      ariaLabelledById={`my-preferences-${isMobile ? 'slide-up' : 'modal'}`}
      data-testid={`${isMobile ? 'slide-up' : 'main'}-modal`}
      primaryButtonText="Save preferences"
      primaryButtonOnClick={() =>
        onSaveCookiePreferences(userCookiePreferences)
      }
    >
      {categoryPreferences.map((preference, key) => (
        <div key={key} className={styles.preferencesSection}>
          <div className={styles.preferencesSectionHeader}>
            {preference?.category && (
              <Checkbox
                label=""
                type="checkbox"
                id={cinchCategoriesNameMapper[preference.category]}
                name={preference.category}
                value={preference.category}
                checked={isCategoryChecked(preference.category)}
                onChange={(e) =>
                  preference?.category
                    ? handleCheckboxChange(preference.category, e)
                    : null
                }
                data-testid={`checkbox-${
                  cinchCategoriesNameMapper[preference.category]
                }`}
              />
            )}
            <Text
              fontSizeFixed="xs"
              fontWeight="bold"
              lineHeight="display"
              className={styles.preferencesSectionHeading}
            >
              {preference.title}
            </Text>
          </div>
          <Text fontSizeFixed="xs" className={styles.preferencesBody}>
            {preference.purpose}
          </Text>
          <Text fontSizeFixed="xs">{preference.tools.join(', ')}</Text>
        </div>
      ))}
    </ModalComponent>
  )
}

export default CookiePreferencesModal
