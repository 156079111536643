import { Destination, Preference } from '../cookie-consent.types'
import {
  advertisingScriptList,
  essentialCinchScriptsList,
} from '../scripts-config'

function processToolNames(
  names: Destination[],
  extras?: { include?: string[]; remove?: string[] },
): string[] {
  return (
    names
      .filter(Boolean)
      .map((dest) => dest.name)
      // eslint-disable-next-line unicorn/prefer-spread
      .concat(extras?.include ?? [])
      .filter((name) => !extras?.remove?.includes(name) ?? true)
  )
}

// Setup preferences object which constructs the data in the cookie prefs modal
export const setupPreferences = (
  destinations: Record<string, Destination[]>,
) => {
  const functionalTools = processToolNames(
    destinations?.['Customer Success'] ?? [],
    {
      include: ['Segment'],
      remove: ['Zendesk'],
    },
  )

  const marketingTools = processToolNames(
    [
      ...(destinations?.['Analytics'] ?? []),
      ...(destinations?.['Email Marketing'] ?? []),
      ...(destinations?.['Heatmaps & Recordings'] ?? []),
    ],
    {
      include: ['Hotjar', 'TikTok'],
      remove: ['Amplitude'],
    },
  )

  const advertisingTools = processToolNames(destinations?.['Advertising'] ?? [])

  const preferences: Preference[] = [
    {
      title: 'Functional',
      category: 'Customer Success',
      purpose:
        'To monitor the performance of our site and to enhance your browsing experience. For example, these tools enable you to communicate with us via live chat.',
      tools: functionalTools,
    },
    {
      title: 'Marketing and Analytics',
      category: 'Analytics',
      purpose:
        'To understand user behavior in order to provide you with a more relevant browsing experience or personalize the content on our site. For example, we collect information about which pages you visit to help us present more relevant information.',
      tools: marketingTools,
    },
    {
      title: 'Advertising',
      category: 'Advertising',
      purpose:
        'To personalize and measure the effectiveness of advertising on our site and other websites. For example, we may serve you a personalized ad based on the pages you visit on our site.',
      tools: advertisingTools,
    },
    {
      title: 'Essential',
      category: null,
      purpose:
        'We use browser cookies that are necessary for the site to work as intended. For example, we store your website data collection preferences so we can honor them if you return to our site. You can disable these cookies in your browser settings but if you do the site may not work as intended.',
      tools: [essentialCinchScriptsList],
    },
  ]

  // Adds the cinch script names (scripts-config.ts) into the modals category preferences
  advertisingScriptList.forEach((scriptName: string) => {
    const name = scriptName.includes('MediaCom') ? 'MediaCom' : scriptName
    scriptName &&
      preferences?.[2]?.tools?.includes(name) &&
      preferences?.[2]?.tools?.push(name)
  })

  return preferences
}
