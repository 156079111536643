import {
  HasPart,
  HowToImage,
  HowToProps,
  HowToVideo,
  Step,
  Supply,
  Tool,
} from '@cinch-nx/data-storyblok'
import { BreadcrumbList, FAQPage, HowTo, VideoObject } from 'schema-dts'

import type { Breadcrumbs as TBreadcrumbs } from '../breadcrumbs/types'
import { JsonLDType } from '../json-ld-script/json-ld-script'
import type { Video } from '../video/video'

export interface Faq {
  question: string
  summary: string
}

export const formatBreadcrumbListSchema = (
  breadcrumbs: TBreadcrumbs[],
): BreadcrumbList => ({
  '@type': JsonLDType.BreadcrumbList,
  itemListElement: breadcrumbs.map((crumb, index) => ({
    '@type': JsonLDType.ListItem,
    position: index + 1,
    name: crumb.text,
    item: `${process.env.STAGE_URL}${crumb.url}`,
  })),
})

export const formatFaqPageSchema = (faqs: Faq[]): FAQPage => ({
  '@type': JsonLDType.FaqPage,
  mainEntity: faqs.map(({ question, summary }) => ({
    '@type': JsonLDType.Question,
    name: question,
    acceptedAnswer: {
      '@type': JsonLDType.Answer,
      text: summary,
    },
  })),
})

export const formatVideoSchema = (video: Video): VideoObject => ({
  '@type': JsonLDType.VideoObject,
  name: video.title,
  description: video.description,
  thumbnailUrl: video.thumbnail ? [video.thumbnail.filename] : undefined,
  duration: video.duration,
  uploadDate: video.uploadDate,
})
export const formatHowToSchema = (howto: HowToProps[]): HowTo => ({
  '@type': JsonLDType.HowTo,
  name: howto[0]?.name,
  image: howto[0]?.image?.map((p: HowToImage) => ({
    '@type': 'ImageObject',
    url: p.url,
    height: p.height,
    width: p.width,
  })),
  video: howto[0]?.video?.map((p: HowToVideo) => ({
    '@type': 'VideoObject',
    name: p.name,
    description: p.description,
    thumbnailUrl: p.thumbnailUrl,
    contentUrl: p.contentUrl,
    embedUrl: p.embedUrl,
    uploadDate: p.uploadDate,
    duration: p.duration,
    hasPart: p.hasPart?.map((element: HasPart) => ({
      '@type': 'Clip',
      '@id': element.id,
      name: element.name,
      startOffset: element.startOffset,
      endOffset: element.endOffset,
      url: element.url,
    })),
  })),
  step: howto[0]?.step?.map((p: Step) => ({
    url: p.url,
    name: p.name,
    '@type': 'HowToStep',
    image: {
      '@type': 'ImageObject',
      url: p?.image[0]?.url,
      height: p.image[0]?.height,
      width: p.image[0]?.width,
    },
    itemListElement: p.itemListElement?.map((element) => ({
      '@type': 'HowToDirection',
      text: element.text,
    })),
  })),
  supply: howto[0]?.supply?.map((element: Supply) => ({
    '@type': 'HowToSupply',
    name: element.name,
  })),
  tool: howto[0]?.tool?.map((element: Tool) => ({
    '@type': 'HowToTool',
    name: element.name,
  })),
})
