export const CloseIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.46967 22.4697C0.176777 22.7626 0.176777 23.2374 0.46967 23.5303C0.762563 23.8232 1.23744 23.8232 1.53033 23.5303L12 13.0607L22.4697 23.5303C22.7626 23.8232 23.2374 23.8232 23.5303 23.5303C23.8232 23.2374 23.8232 22.7626 23.5303 22.4697L13.0607 12L23.5303 1.53033C23.8232 1.23744 23.8232 0.762564 23.5303 0.469669C23.2374 0.176777 22.7626 0.176777 22.4697 0.469669L12 10.9393L1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L10.9393 12L0.46967 22.4697Z"
      fill="#311B77"
    />
  </svg>
)
