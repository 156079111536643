import cn from 'classnames'

import ArrowBorder from '../assets/arrow-border.svg'
import BasketIcon from '../assets/basket-icon.svg'
import TimeIcon from '../assets/time.svg'
import styles from './basket.module.css'

interface BasketProps {
  onClick: () => void
  showExpiryTimer: boolean
  orderHasExpired?: boolean
}

const BasketWithBorder: React.FC = () => (
  <>
    <ArrowBorder />
    <div className={styles.basketIcon}>
      <BasketIcon />
    </div>
  </>
)

export const Basket: React.FC<BasketProps> = ({
  onClick,
  showExpiryTimer,
  orderHasExpired,
}) =>
  showExpiryTimer || orderHasExpired ? (
    <button
      type="button"
      onClick={onClick}
      data-testid="basket-icon"
      aria-label="Your order basket"
      className={styles.basketIconContainerWithTimer}
    >
      <div className={styles.timerIcon}>
        <TimeIcon className={styles.timeIcon} data-testid="time-icon" />
      </div>
      <BasketWithBorder />
    </button>
  ) : (
    <button
      type="button"
      onClick={onClick}
      aria-label="Your order basket"
      data-testid="basket-icon"
      className={styles.basketIconContainer}
    >
      <span
        data-testid="BasketButton/Amount"
        className={cn(styles.number, styles.pulse)}
      >
        1
      </span>
      <BasketWithBorder />
    </button>
  )
