import { getToken, isSSR } from '@cinch-labs/shared-util'
import type { Token } from '@cinch-labs/shared-util'
import { Env, readFromEnv } from '@cinch-nx/environments'
import { datadogRum } from '@datadog/browser-rum'
import axios, { AxiosError, AxiosInstance, AxiosStatic } from 'axios'
import create from 'zustand'

export interface UserStoreConfig {
  authKey: string
  legacyAuthKey: string
  profileServiceUrl: string
  identityServiceUrl: string
  redirectUrlKey: string
}

let userStoreConfig: UserStoreConfig = {
  authKey: '',
  legacyAuthKey: '',
  profileServiceUrl: '',
  identityServiceUrl: '',
  redirectUrlKey: '',
}
let httpClient: AxiosInstance
let onSessionExpiredCallback: () => void

export const initUserStore = (
  config?: Partial<UserStoreConfig>,
  axiosImpl: AxiosStatic = axios,
) => {
  userStoreConfig = {
    identityServiceUrl: readFromEnv(Env.IdentityServiceUrl),
    profileServiceUrl: readFromEnv(Env.ProfileServiceUrl),
    redirectUrlKey: readFromEnv(Env.RedirectUrlKey),
    authKey: readFromEnv(Env.AuthKey),
    legacyAuthKey: readFromEnv(Env.LegacyAuthKey),
    ...config,
  }
  const _httpClient = axiosImpl.create()

  _httpClient.interceptors.request.use((request) => {
    const token = getToken({
      authKey: userStoreConfig.authKey,
      legacyAuthKey: userStoreConfig.legacyAuthKey,
    })

    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${token?.access_token}`,
    }

    return request
  })

  _httpClient.interceptors.response.use(undefined, (error: AxiosError) => {
    if (error?.response?.status === 401 || error?.response?.status === 403) {
      onSessionExpiredCallback?.()
    }

    return Promise.reject(error)
  })

  httpClient = _httpClient
}

export interface UserProfile {
  email?: string
  firstName?: string
  lastName?: string
  phoneNumber?: string
  preferenceDigital?: boolean
  preferenceEmail?: boolean
  preferenceSms?: boolean
}

export type UserStatus =
  | 'unknown'
  | 'pending'
  | 'valid'
  | 'invalid'
  | 'updating'
  | 'expired'

export type UserState = {
  status: UserStatus
  token?: Token
  profile?: UserProfile
}

export type UserStore = UserState & {
  fetchUser: () => Promise<void>
  logout: () => void
  login: () => void
  register: () => void
  updatePassword: (oldPassword: string, newPassword: string) => Promise<void>
  updateProfile: (updatedFields: Partial<UserProfile>) => Promise<void>
  setStatus: (status: UserState['status']) => void
}

export const useUserStore = create<UserStore>((set, get) => {
  onSessionExpiredCallback = () => {
    set({ status: 'expired' })
  }

  return {
    status: 'unknown',

    setStatus: (status) => set({ status }),

    fetchUser: async () => {
      const { authKey, legacyAuthKey, profileServiceUrl } = userStoreConfig
      if (isSSR()) {
        return
      }

      const token = getToken({ authKey, legacyAuthKey })

      if (!token) {
        return set(() => ({ status: 'invalid' }))
      }

      set(() => ({ status: 'pending', token }))

      try {
        const profile = (
          await httpClient.get<UserProfile>(`${profileServiceUrl}/userprofile`)
        ).data

        return set(() => ({ status: 'valid', profile, token }))
      } catch {
        datadogRum.addError(new Error('FetchUserProfileActionFailed'))
      }
    },

    updatePassword: async (oldPassword, newPassword) => {
      try {
        const { identityServiceUrl } = userStoreConfig

        set(() => ({ status: 'updating' }))
        await httpClient.put(`${identityServiceUrl}/password`, {
          oldPassword,
          newPassword,
        })
        set(() => ({ status: 'valid' }))
      } catch {
        datadogRum.addError(new Error('UpdatePasswordActionFailed'))
      }
    },

    updateProfile: async (updatedFields) => {
      try {
        const { profileServiceUrl } = userStoreConfig

        set(() => ({ status: 'updating' }))
        const fullProfile = { ...get().profile, ...updatedFields }
        const updatedProfile = { ...updatedFields }
        await httpClient.put<void>(
          `${profileServiceUrl}/userprofile`,
          updatedProfile,
        )
        set(() => ({ profile: fullProfile, status: 'valid' }))
      } catch {
        datadogRum.addError(new Error('UpdateProfileActionFailed'))
      }
    },

    logout: () => {
      const { redirectUrlKey } = userStoreConfig

      localStorage.removeItem(redirectUrlKey)
      window.location.assign('/logout')
    },

    login: () => {
      window.location.assign('/login')
    },

    register: () => {
      window.location.assign('/login')
    },
  }
})
