import { designTokens } from '@cinch-labs/design-system'
import cx from 'classnames'
import React from 'react'

import styles from './loading-spinner.module.css'

export interface SpinnerProps {
  backgroundCircleColor?: string
  foregroundCircleColor?: string
  size?: number
  className?: string
}

const { color: palette } = designTokens.customProperties.ds
const DEFAULT_SIZE = 32

export const LoadingSpinner: React.FC<SpinnerProps> = ({
  backgroundCircleColor = palette.gainsboro,
  foregroundCircleColor = palette.deepPurple,
  size = DEFAULT_SIZE,
  className,
}) => {
  const classNames = cx(styles.loadingSpinner, className)

  return (
    <svg
      className={classNames}
      xmlns="https://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 32 32"
      data-testid="LoadingSpinner"
    >
      <circle cx="16" cy="16" r="11" stroke={backgroundCircleColor} />
      <path
        className={styles.foregroundCircle}
        d="M5,16a11,11 0 1,0 22,0a11,11 0 1,0 -22,0"
        stroke={foregroundCircleColor}
      />
    </svg>
  )
}

export default LoadingSpinner
