import { NavItemsWrapper } from '../nav-items-wrapper/nav-items-wrapper'
import { NavItem } from '../nav-item/nav-item'
import { DropdownModal } from '../dropdown-modal/dropdown-modal'
import { useContext } from 'react'
import styles from './desktop-nav.module.css'
import { ListWrapper } from '../list-wrapper/list-wrapper'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import { ListItem } from '../list-item/list-item'
import { NavCard } from '../nav-card/nav-card'

export interface DesktopNavProps {
  shouldShowDropdown: boolean
  handleDesktopItemSelection: () => void
}

export const DesktopNav = ({
  shouldShowDropdown,
  handleDesktopItemSelection,
}: DesktopNavProps) => {
  const { logicStore } = useContext(SharedUiHeaderV2Context)

  const { handleTrackEvent } = logicStore

  return (
    <NavItemsWrapper>
      <NavItem
        shouldShowDropdown={shouldShowDropdown}
        handleDesktopItemSelection={handleDesktopItemSelection}
        title={'Test Nav Item'}
        handleTrackEvent={() =>
          handleTrackEvent({
            name: 'click',
            category: 'mega menu',
            label: 'Test Nav Item',
            action: 'button',
          })
        }
      >
        <DropdownModal>
          <div className={styles.navItemDropdown}>
            {/* to save these getting too big we can create 
                components out of eact secion ie -> 'popular makes', 
                'shop by budget'*/}
            <ListWrapper
              heading={'Test heading'}
              urlText={'Find more cars'}
              href={'/'}
              handleTrackEvent={() =>
                handleTrackEvent({
                  name: 'click',
                  category: 'mega menu',
                  label: 'Test Nav Item Mobile',
                  action: 'Find more cars',
                  value: 'Test heading',
                })
              }
            >
              <>
                <ListItem
                  label="ford"
                  href="/find-vehicle?make=ford"
                  ariaLabel="search ford cars"
                  handleTrackEvent={() =>
                    // once we have the full component here we can add the values for tracking dynamically
                    handleTrackEvent({
                      name: 'click',
                      category: 'mega menu',
                      label: 'find a car',
                      action: 'popular makes',
                      value: 'ford',
                    })
                  }
                />
                <NavCard
                  heading="Help Me Choose"
                  subHeading="Not sure what you are looking for?"
                  link="/test-link"
                  href="/test-href"
                  imageSrc="/img.png"
                  alt="test image"
                  isImageFullWidth={false}
                  handleTrackEvent={() =>
                    handleTrackEvent({
                      name: 'click',
                      category: 'mega menu',
                      label: 'find a car',
                      action: 'popular makes',
                      value: 'ford',
                    })
                  }
                />
              </>
            </ListWrapper>
          </div>
        </DropdownModal>
      </NavItem>
    </NavItemsWrapper>
  )
}
