import TimeIcon from '../assets/time.svg'
import {
  SharedUiHeaderContext,
  SharedUiHeaderContextProps,
  noOpTracking,
} from '../shared-ui-header.context'
import { TrackingEventTypes } from '../types/analytics'
import { ReturnToCheckoutExpiredStillAvailable } from './card-content/expired-still-available/expired-still-available-card.component'
import { ReturnToCheckoutExpiredUnavailable } from './card-content/expired-unavailable/expired-unavailable-card.component'
import { ReturnToCheckoutExpiringSoon } from './card-content/expiring-soon/expiring-soon-card.component'
import { LoadingCard } from './card-content/loading-card/loading-card.component'
import { ReturnToCheckoutOrderActive } from './card-content/order-active/order-active-card.component'
import Modal from './modal'
import styles from './return-to-checkout-modal.module.css'
import { useReturnToCheckoutModalTracking } from './return-to-checkout-modal.tracking'
import { ModalState } from './return-to-checkout-modal.types'
import { Text } from '@cinch-labs/design-system'
import React, { useCallback, useContext, useEffect, useState } from 'react'

export enum CheckoutStage {
  PersonalDetails = 'PersonalDetails',
  PartExchange = 'PartExchange',
  Finance = 'Finance',
  DeliverySlots = 'DeliverySlots',
  Extras = 'Extras',
  CinchCare = 'CinchCare',
  Payment = 'Payment',
  SignUp = 'SignUp',
}

export const returnToCheckoutOrderActiveTestId =
  'return-to-checkout-order-active-test-id'
export const returnToCheckoutExpiringSoonTestId =
  'return-to-checkout-expiring-soon-test-id'
export const returnToCheckoutExpiredStillAvailableTestId =
  'return-to-checkout-expired-still-available-test-id'
export const returnToCheckoutExpiredUnavailableTestId =
  'return-to-checkout-expired-unavailable-test-id'

export const i18n = {
  orderActive: {
    title: 'Continue buying this car?',
    buttonText: 'Return to checkout',
    secondaryButtonText: "I don't want this car",
  },
  expiringSoon: {
    title: 'Your order is timing out',
    text: "We'll reserve this car for another {bold}{timeRemaining}{/bold}. Please return to the checkout to continue your order.",
    buttonText: 'Return to checkout',
    secondaryButtonText: "I don't want this car",
  },
  expiredStillAvailable: {
    title: 'Your order timed out',
    text: 'But the car is still available! Want to start a new order with the same car?',
    buttonText: 'Go to checkout',
  },
  expiredUnavailable: {
    title: 'Car no longer available',
    text: 'We’re sorry you missed it - but we have 1,000s of other cars in stock. Start a new search to find yours.',
    buttonText: 'Browse all cars',
  },
  loading: 'Loading...',
  adminFee: 'Plus a {adminFeeValue} admin fee',
}

export interface OrderCheckoutStage {
  orderId: string
  stage: CheckoutStage
}

export interface ReturnToCheckoutModalProps {
  activeOrder: Required<SharedUiHeaderContextProps>['activeOrder']
  lastVisitedCheckoutStage?: OrderCheckoutStage
  onClose: () => void
  timeRemainingInSeconds: number
  withinExpiryTimeWindow: boolean
}

export const cardDetailsPagePath = '/checkout/card-details/'
export const deliveryPagePath = '/checkout/delivery/'
export const personalDetailsPagePath = '/checkout/personal-details/'
export const findVehiclePath = '/find-vehicle/'
export const partExchangePagePath = '/checkout/part-exchange/'
export const financePagePath = '/checkout/payment/'
export const cinchCarePagePath = 'checkout/cinch-care/'
export const extrasPagePath = '/checkout/car-care/'

export const cancelOrderReason = "Return to Checkout - I don't want this car"

type Stages = { [Stage in CheckoutStage]?: string }
export const returnToCheckoutStage = (
  checkoutStep: CheckoutStage,
  activeOrder: ReturnToCheckoutModalProps['activeOrder'],
) => {
  const vehicle = activeOrder.vehicle
  const orderId = activeOrder.id
  const stages: Stages = {
    [CheckoutStage.PartExchange]: partExchangePagePath,
    [CheckoutStage.Finance]: financePagePath,
    [CheckoutStage.DeliverySlots]: deliveryPagePath,
    [CheckoutStage.Extras]: extrasPagePath,
    [CheckoutStage.CinchCare]: cinchCarePagePath,
    [CheckoutStage.Payment]: cardDetailsPagePath,
  }
  if (stages[checkoutStep]) {
    return window.location.assign(`${stages[checkoutStep]}${orderId}`)
  }
  return window.location.assign(`${personalDetailsPagePath}${vehicle.id}`)
}

const hasExpired = (expiresAt: number | undefined) =>
  expiresAt === undefined ? false : Date.now() > expiresAt

export const ReturnToCheckoutModal: React.FC<ReturnToCheckoutModalProps> = ({
  activeOrder,
  onClose,
  lastVisitedCheckoutStage,
  timeRemainingInSeconds,
  withinExpiryTimeWindow,
}) => {
  const {
    checkIfVehicleIsReserved,
    refreshActiveOrder,
    extendOrderExpiryDate,
    trackEvent,
    cancelOrder,
    isLoggedIn,
    accessToken,
  } = useContext(SharedUiHeaderContext)

  const { resumeCheckoutClosedModal, resumeCheckoutReturnedToCheckout } =
    useReturnToCheckoutModalTracking(trackEvent ?? noOpTracking)

  const vehicle = activeOrder?.vehicle
  const orderId = activeOrder?.id

  const [currentOrder, setCurrentOrder] = useState<
    { expiresAt?: number; cancelledAt?: number } | undefined
    // eslint-disable-next-line unicorn/no-useless-undefined
  >(undefined)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [secondaryButtonLoading, setSecondaryButtonLoading] = useState(false)
  const [vehicleIsReserved, setVehicleIsReserved] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)

  const orderHasExpired = hasExpired(
    currentOrder?.expiresAt ?? currentOrder?.cancelledAt,
  )

  useEffect(() => {
    if (orderHasExpired && checkIfVehicleIsReserved) {
      setModalLoading(true)
      const getReservationStatus = async () => {
        const reservationStatus = await checkIfVehicleIsReserved(
          activeOrder.vehicle.id,
        )
        setVehicleIsReserved(reservationStatus.reserved)
        setModalLoading(false)
      }
      getReservationStatus()
    }
  }, [activeOrder, orderHasExpired, vehicle, checkIfVehicleIsReserved])

  const extendExpiry = useCallback(async () => {
    if (!accessToken) {
      return
    }
    setButtonLoading(true)
    await extendOrderExpiryDate?.(orderId, accessToken)
    setButtonLoading(false)
  }, [orderId, extendOrderExpiryDate, accessToken])

  const removeOrderForUser = async (token: string) => {
    if (!refreshActiveOrder) {
      return
    }

    try {
      const orderDetails = await cancelOrder?.(
        activeOrder.id,
        cancelOrderReason,
        token,
      )

      switch (orderDetails?.status) {
        case 200:
          setCurrentOrder(orderDetails.data)
          refreshActiveOrder()
          break
        case 403:
          window.location.assign('/login')
          break
        default:
          refreshActiveOrder()
          break
      }
      return setSecondaryButtonLoading(false)
    } catch {
      // eslint-disable-next-line unicorn/no-useless-undefined
      setCurrentOrder(undefined)
      refreshActiveOrder()
      return setSecondaryButtonLoading(false)
    }
  }

  const handleRemoveOrder = async (modalState: ModalState) => {
    trackEvent?.({
      eventName: 'checkout-resume',
      type: TrackingEventTypes.ADOBE,
      data: {
        name: 'click',
        category: 'button',
        action: 'remove-car',
        label: 'checkout-resume',
        section: modalState,
      },
    })

    setSecondaryButtonLoading(true)
    const isUserValid = accessToken && isLoggedIn

    if (!isUserValid) {
      window.location.assign('/login')
    } else {
      await removeOrderForUser(accessToken)
    }
  }

  const onButtonClick = async (modalState: ModalState) => {
    resumeCheckoutReturnedToCheckout(modalState)
    await extendExpiry()
    returnToCheckoutStage(
      (lastVisitedCheckoutStage?.stage ?? 'PersonalDetails') as CheckoutStage,
      activeOrder,
    )
  }

  const onButtonClickExpiredOrderVehicleStillAvailable = () => {
    resumeCheckoutReturnedToCheckout(ModalState.expiredStillAvailable)
    window.location.assign(`${personalDetailsPagePath}${vehicle.id}`)
  }

  const onButtonClickExpiredOrderVehicleUnavailable = () => {
    refreshActiveOrder && refreshActiveOrder()
    resumeCheckoutReturnedToCheckout(ModalState.expiredUnavailable)
    window.location.assign(findVehiclePath)
  }

  const onModalCloseExpiredOrderVehicleUnavailable = () => {
    refreshActiveOrder && refreshActiveOrder()
    resumeCheckoutClosedModal(ModalState.expiredStillAvailable)
    onClose()
  }

  const onModalClose = (modalState: ModalState) => {
    resumeCheckoutClosedModal(modalState)
    onClose()
  }

  const styleCardTitle = (title: string, icon?: boolean) => {
    if (icon) {
      return (
        <Text element="h5" className={styles.cardTitle}>
          <div className={styles.titleWithIconContainer}>
            <TimeIcon
              className={styles.timeIcon}
              data-testid="time-icon-modal-title"
            />
            {title}
          </div>
        </Text>
      )
    }
    return (
      <Text element="h5" className={styles.cardTitle}>
        {title}
      </Text>
    )
  }

  const getTitle = (modalState: ModalState) => {
    switch (modalState) {
      case ModalState.active:
        return styleCardTitle(i18n.orderActive.title)
      case ModalState.expiringSoon:
        return styleCardTitle(i18n.expiringSoon.title, true)
      case ModalState.expiredStillAvailable:
        return styleCardTitle(i18n.expiredStillAvailable.title, true)
      case ModalState.expiredUnavailable:
        return styleCardTitle(i18n.expiredUnavailable.title)
    }
  }

  if (modalLoading || !activeOrder) {
    return (
      <Modal
        onClose={onClose}
        title={'Loading'}
        classNames={styles}
        closeable={true}
      >
        <LoadingCard />
      </Modal>
    )
  }

  if (withinExpiryTimeWindow) {
    const returnToCheckoutModalState = ModalState.expiringSoon
    return (
      <Modal
        testId={returnToCheckoutExpiringSoonTestId}
        onClose={() => onModalClose(returnToCheckoutModalState)}
        classNames={styles}
        closeable={true}
      >
        <ReturnToCheckoutExpiringSoon
          title={getTitle(returnToCheckoutModalState)}
          timeRemainingInSeconds={timeRemainingInSeconds}
          onButtonClick={() => onButtonClick(returnToCheckoutModalState)}
          onSecondaryButtonClick={() =>
            handleRemoveOrder(returnToCheckoutModalState)
          }
          vehicle={vehicle}
          isButtonLoading={buttonLoading}
          isSecondaryButtonLoading={secondaryButtonLoading}
          i18n={i18n.expiringSoon}
        />
      </Modal>
    )
  }

  if (orderHasExpired) {
    if (!vehicleIsReserved) {
      const returnToCheckoutModalState = ModalState.expiredStillAvailable
      return (
        <Modal
          testId={returnToCheckoutExpiredStillAvailableTestId}
          onClose={() => onModalClose(returnToCheckoutModalState)}
          classNames={styles}
          closeable={true}
        >
          <ReturnToCheckoutExpiredStillAvailable
            onButtonClick={onButtonClickExpiredOrderVehicleStillAvailable}
            title={getTitle(returnToCheckoutModalState)}
            vehicle={vehicle}
            i18n={i18n.expiredStillAvailable}
            isButtonLoading={buttonLoading}
          />
        </Modal>
      )
    } else {
      const returnToCheckoutModalState = ModalState.expiredUnavailable
      return (
        <Modal
          testId={returnToCheckoutExpiredUnavailableTestId}
          onClose={onModalCloseExpiredOrderVehicleUnavailable}
          classNames={styles}
          closeable={true}
        >
          <ReturnToCheckoutExpiredUnavailable
            onButtonClick={onButtonClickExpiredOrderVehicleUnavailable}
            title={getTitle(returnToCheckoutModalState)}
            vehicle={vehicle}
            i18n={i18n.expiredUnavailable}
            isButtonLoading={buttonLoading}
          />
        </Modal>
      )
    }
  }

  const returnToCheckoutModalState = ModalState.active
  return (
    <Modal
      testId={returnToCheckoutOrderActiveTestId}
      onClose={() => onModalClose(returnToCheckoutModalState)}
      classNames={styles}
      closeable={true}
    >
      <ReturnToCheckoutOrderActive
        onButtonClick={() => onButtonClick(returnToCheckoutModalState)}
        onSecondaryButtonClick={() =>
          handleRemoveOrder(returnToCheckoutModalState)
        }
        title={getTitle(returnToCheckoutModalState)}
        vehicle={vehicle}
        i18n={i18n.orderActive}
        isButtonLoading={buttonLoading}
        isSecondaryButtonLoading={secondaryButtonLoading}
      />
    </Modal>
  )
}
