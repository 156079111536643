export const ChevronDown = () => (
  <svg
    width="13"
    height="8"
    viewBox="0 0 13 8"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
  >
    <path
      opacity="0.9"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.20802 0.981584C0.494334 0.682256 0.96909 0.671706 1.26842 0.95802L6.5 5.96214L11.7316 0.95802C12.0309 0.671706 12.5057 0.682256 12.792 0.981584C13.0783 1.28091 13.0677 1.75567 12.7684 2.04198L7.01842 7.54198C6.72845 7.81934 6.27155 7.81934 5.98158 7.54198L0.231584 2.04198C-0.0677439 1.75567 -0.078294 1.28091 0.20802 0.981584Z"
      fill="currentColor"
    />
  </svg>
)
