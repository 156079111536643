import React from 'react'

type TagInterpolationHandler = (
  text: string,
  index?: number,
) => string | React.ReactNode
type InterpolatedVars = Record<string, string | number>
type InterpolatedTags = Record<string, TagInterpolationHandler>

interface TagMatch {
  tag: string
  index: number
  fullMatch: string
  innerText: string
}

const matchTag = (val: string): TagMatch[] => {
  const regex = new RegExp(`\\{([^}]+)\\}(.*?)\\{/[^}]+\\}`, 'g')
  const matches: TagMatch[] = []

  do {
    const match = regex.exec(val)
    if (match) {
      matches.push({
        fullMatch: match[0],
        tag: match[1],
        innerText: match[2],
        index: match.index,
      })
    }
  } while (regex.lastIndex > 0)

  return matches
}

function interpolateVars(val: string, vars?: InterpolatedVars): string {
  return vars
    ? Object.keys(vars).reduce(
        (acc, v) =>
          acc.replace(new RegExp(`\\{${v}\\}`, 'g'), vars[v].toString()),
        val,
      )
    : val
}

const interpolateTags = (
  val: string,
  tags?: InterpolatedTags,
): Array<string | React.ReactNode> => {
  if (tags) {
    const matches = matchTag(val)

    const interpolated = []
    let lastMatchEndIndex = 0
    for (const match of matches) {
      // eslint-disable-next-line unicorn/prefer-string-slice
      interpolated.push(val.substring(lastMatchEndIndex, match.index))
      const handler = tags[match.tag]
      interpolated.push(
        // eslint-disable-next-line unicorn/prefer-dom-node-text-content
        handler ? handler(match.innerText, match.index) : match.fullMatch,
      )
      lastMatchEndIndex = match.index + match.fullMatch.length
    }
    interpolated.push(val.slice(Math.max(0, lastMatchEndIndex)))
    return interpolated
  }
  return [val]
}

/**
 We now have several cases across checkout where using raw i18n values isn't enough. The two common cases are:

 - We want to interpolate a single value, e.g. "You have {N} cars remaining"
 - We want to apply additional styling to some text, e.g. "Your {emphasized}conditional offer{/emphasized} is..."

 First case interpolation is easy if we are expecting a placeholder in the form {somename}, as we just replace any
 pattern of {.+} with a particular value.
 The second case is much harder as we don't know what formatting to apply for a given set of 'tags'. Additionally, it is
 not possible to interpolate JSX elements directly into a string. React provides a mechanism for doing this by allowing
 us to return a list of elements that are acceptable for React to render (string, null, undefined or a JSX element)

 Therefore, this function takes:
 - an input string,
 - an object of placeholders -> values for direct interpolation
 - an object of placeholders -> a handler function for jsx tag interpolation.

 A single placeholder is defined in the string in the form {NAME} and a tag placeholder is defined as {NAME}text{/NAME}

 The function will return an array of interpolated values that can be dropped directly into a React component.
 If the tags variable was undefined, or no placeholders were replaced, then splitting the string is unnecessary and the
 return value will be an array containing a single string (the input string unchanged if no placeholders were replaced).
 Placeholders not found in either object will be ignored and returned unchanged.

 If you are certain that no JSX elements are included in the array (either because tags was undefined or your handlers
 only return strings), you can return the result to a single string by using interpolateI18n(...).join('')

 * @param val The input string, usually taken directly from an i18n value
 * @param vars An object of the form {placeholder:value} e.g. {name: 'John', age: 25}
 * @param tags An object of the form {placeholder:(text:string)=>string}. The handler will be passed the text between
 *             the tags. E.g. {bold: (text:string) => <span className={styles.bold}>{text}</span>
 */
export const interpolateI18n = (
  val: string,
  vars?: InterpolatedVars,
  tags?: InterpolatedTags,
): Array<string | React.ReactNode> =>
  interpolateTags(interpolateVars(val, vars), tags)
