import cx from 'classnames'
import React from 'react'
import { FocusOn } from 'react-focus-on'
import CSSTransition from 'react-transition-group/CSSTransition'

import Portal from '../../portal/portal.component'
import Card from '../card'
import * as baseStyles from './modal.module.css'

export interface ClassNamesApiProps<T> {
  classNames?: T
}
interface ModalClassNames {
  backdropEnter?: string
  backdropEnterActive?: string
  backdropExit?: string
  backdropExitActive?: string
  backdropStyle?: string
  modalEnter?: string
  modalEnterActive?: string
  modalExit?: string
  modalExitActive?: string
  modal?: string
  backdrop?: string
  cardContainer?: string
  cardBody?: string
  cardTitle?: string
  cardTitleContainer?: string
  cardCloseIcon?: string
}

export interface ModalProps extends ClassNamesApiProps<ModalClassNames> {
  children?: React.ReactNode
  title?: string | React.ReactNode
  subtitle?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardStyles?: any
  closeText?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (e: any) => void
  closeable?: boolean
  closeIcon?: string
  closeIconSize?: number
  testId?: string
}

interface ModalState {
  mounted: boolean
}

const TIMEOUT = 250

class Modal extends React.Component<ModalProps, ModalState> {
  public elementRef: React.RefObject<HTMLDivElement>

  constructor(props: ModalProps) {
    super(props)
    this.elementRef = React.createRef()
  }

  public override state = { mounted: false }

  public override componentDidMount(): void {
    this.setState({ mounted: true })
    document.addEventListener('keydown', this.onKeyDown, false)
    document.addEventListener('mousedown', this.onMouseDown, false)
  }

  public override componentWillUnmount(): void {
    document.removeEventListener('keydown', this.onKeyDown, false)
    document.removeEventListener('mousedown', this.onMouseDown, false)
  }

  public close = (event: React.TouchEvent | MouseEvent | KeyboardEvent) => {
    if (this.isInTheDOM()) {
      if (this.props.onClose) {
        this.props.onClose(event)
      }

      this.setState({ mounted: false })
    }
  }

  public isInTheDOM() {
    return document.contains(this.elementRef.current)
  }

  private backdropId = 'backdrop'

  public onKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.close(event)
    }
  }

  public onMouseDown = (event: MouseEvent) => {
    if ((event.target as HTMLElement).id === this.backdropId) {
      this.close(event)
    }
  }

  public override render() {
    const {
      children,
      title,
      subtitle,
      cardStyles,
      classNames,
      closeText,
      closeable = true,
      closeIcon,
      closeIconSize,
      testId,
    } = this.props

    const classes = {
      ...baseStyles,
      ...classNames,
    }

    const backdropClassNames = {
      enter: classes.backdropEnter,
      enterActive: classes.backdropEnterActive,
      exit: classes.backdropExit,
      exitActive: classes.backdropExitActive,
    }

    const modalClassNames = {
      enter: classes.modalEnter,
      enterActive: classes.modalEnterActive,
      exit: classes.modalExit,
      exitActive: classes.modalExitActive,
    }

    const isDesktop =
      typeof window !== 'undefined' &&
      window.matchMedia('(min-width: 1024px)').matches

    return (
      <Portal>
        <CSSTransition
          appear={true}
          classNames={backdropClassNames}
          unmountOnExit={true}
          timeout={TIMEOUT}
          in={this.state.mounted}
        >
          {(state: string) => (
            <div
              id={this.backdropId}
              ref={this.elementRef}
              role="presentation"
              className={cx(classes.backdrop, classes.backdropStyle)}
            >
              <CSSTransition
                appear={true}
                classNames={modalClassNames}
                unmountOnExit={true}
                timeout={TIMEOUT}
                in={state === 'entered'}
              >
                <FocusOn enabled={!isDesktop}>
                  <div
                    data-testid={testId}
                    className={classes.modal}
                    role="dialog"
                    aria-modal="true"
                  >
                    <Card
                      title={title}
                      subtitle={subtitle}
                      cardStyles={cardStyles}
                      classNames={{
                        card: classNames?.cardContainer,
                        body: classNames?.cardBody,
                        title: classNames?.cardTitle,
                        titleContainer: classNames?.cardTitleContainer,
                        closeIcon: classNames?.cardCloseIcon,
                      }}
                      closeIconSize={closeIconSize}
                      closeIcon={closeIcon}
                      {...(closeable ? { onClose: this.close, closeText } : {})}
                    >
                      {children}
                    </Card>
                  </div>
                </FocusOn>
              </CSSTransition>
            </div>
          )}
        </CSSTransition>
      </Portal>
    )
  }
}

export default Modal
