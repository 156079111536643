import styles from './desktop-navigation-dropdown.module.css'

export interface DesktopNavigationDropdownProps {
  onClickBackdrop: () => void
  isOpen: boolean
  children: React.ReactNode
}

const DesktopNavigationDropdown: React.FC<DesktopNavigationDropdownProps> = ({
  onClickBackdrop,
  children,
  isOpen,
  ...rest
}) => (
  <div
    className={styles.navDropdown}
    style={{
      display: isOpen ? 'block' : 'none',
    }}
    {...rest}
  >
    <div
      className={styles.navDropdownBG}
      data-testid="Backdrop"
      onClick={() => onClickBackdrop()}
      aria-hidden="true"
    />
    <div className={styles.navDropdownInner}>{children}</div>
  </div>
)

export default DesktopNavigationDropdown
