import '@cinch-labs/design-system/dist/index.css'
import '@cinch-labs/cds-tokens/cds-tokens.css'
import { registerRumContext } from '@cinch-labs/shared-util'
import { useUserStore } from '@cinch-nx/data-user'
import { ErrorBoundaryWrapper } from '@cinch-nx/landing-feature-error-page'
import {
  AnalyticsManager,
  preserveReferrerAndCID,
} from '@cinch-nx/shared-analytics'
import CookieConsentWrapper from '@cinch-nx/shared-ui/lib/cookie-consent-wrapper/cookie-consent-wrapper'
import dynamic from 'next/dynamic'
import Script from 'next/script'
import { useEffect, useState } from 'react'
import 'react-slidedown/lib/slidedown.css'
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import '../stores'
import './_app.css'
import { getBooleanFeatureFlag } from '@cinch-nx/landing-data-feature-flags'
import Layout, {
  AppPropsWithLayoutPropsWithError,
} from '@cinch-nx/landing-ui-shared/lib/layout/layout'

const ClientSideHead = dynamic(() => import('../components/client-side-head'), {
  ssr: false,
})

function CustomApp({
  Component,
  pageProps,
  err,
}: AppPropsWithLayoutPropsWithError) {
  const [shouldShowSharedUIHeaderV2, setShouldShowSharedUIHeaderV2] =
    useState(false)
  const { layoutProps, ...componentProps } = pageProps
  const [story, setChangedStory] = useState(pageProps.story)
  const { fetchUser } = useUserStore()

  const BUILD_ID = process.env.NEXT_PUBLIC_BUILD_ID
  registerRumContext(BUILD_ID)

  useEffect(() => {
    const getFeatureFlagData = async () => {
      const result = await getBooleanFeatureFlag('shared_ui_header_v2')
      setShouldShowSharedUIHeaderV2(result)
    }
    getFeatureFlagData()
  }, [])

  useEffect(() => {
    preserveReferrerAndCID()
    fetchUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnload = () => {
    const { StoryblokBridge, location } = window
    const storyblokInstance = new StoryblokBridge()
    storyblokInstance.on(['published', 'change'], () => {
      // reload page if save or publish is clicked
      location.reload()
    })

    storyblokInstance.on('input', (payload) => {
      setChangedStory(payload.story)
    })
  }
  if (componentProps.story && story) {
    componentProps.story = story
  }

  return (
    <ErrorBoundaryWrapper>
      <ClientSideHead />
      <AnalyticsManager />

      {pageProps.preview && (
        // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
        <Script
          src="https://app.storyblok.com/f/storyblok-v2-latest.js"
          type="text/javascript"
          onLoad={handleOnload}
        />
      )}
      <Layout
        {...layoutProps}
        useVerticalGradient
        shouldShowSharedUIHeaderV2={shouldShowSharedUIHeaderV2}
      >
        <>
          <Component {...componentProps} err={err} />
          <CookieConsentWrapper />
        </>
      </Layout>
    </ErrorBoundaryWrapper>
  )
}

export default CustomApp
