export const ChevronRight = () => (
  <svg
    width="13"
    height="24"
    viewBox="0 0 13 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.46967 23.5303C0.176777 23.2374 0.176777 22.7626 0.46967 22.4697L10.9393 12L0.469669 1.53033C0.176776 1.23744 0.176776 0.762564 0.469669 0.469669C0.762562 0.176777 1.23744 0.176777 1.53033 0.469669L12.5303 11.4697C12.671 11.6103 12.75 11.8011 12.75 12C12.75 12.1989 12.671 12.3897 12.5303 12.5303L1.53033 23.5303C1.23744 23.8232 0.762563 23.8232 0.46967 23.5303Z"
      fill="#311B77"
    />
  </svg>
)
