import React from 'react'
import * as ReactDOM from 'react-dom'

export interface PortalProps {
  children?: React.ReactNode
  rootElem?: Element | string
}
const defaultRootElemSelector = '#portal-root'

const Portal: React.FC<PortalProps> = (props) => {
  const rootEl = props.rootElem || defaultRootElemSelector
  const rootNode =
    typeof rootEl === 'string'
      ? document.querySelector(rootEl)
        ? (document.querySelector(rootEl) as Element)
        : document.body
      : rootEl

  if (rootEl === document.body) {
    // eslint-disable-next-line no-console
    console.warn(
      'Portal cannot find div:#portal-root in the document, using body instead',
    )
  }
  const [container] = React.useState(() => {
    const el = document.createElement('div')
    el.classList.add('portal-element')
    return el
  })

  React.useEffect(() => {
    rootNode.append(container)
    return () => {
      container.remove()
    }
  }, [container, rootNode])

  return !props.children
    ? null
    : ReactDOM.createPortal(props.children, container)
}

export default Portal
