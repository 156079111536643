import { dashify } from '@cinch-labs/shared-util'
import cx from 'classnames'

import styles from './menu-list-section.module.css'

interface MenuListSection {
  title: string
  customClass?: string
  children: React.ReactNode
}

const MenuListSection: React.FC<MenuListSection> = (props) => (
  <li
    className={cx(styles.menuListSection, props.customClass)}
    key={props.title}
  >
    <nav aria-labelledby={`${dashify(props.title)}-heading`}>
      {props.title ? (
        <span
          id={`${dashify(props.title)}-heading`}
          className={styles.title}
          role="heading"
          aria-hidden="true"
          aria-level={1}
        >
          {props.title}
        </span>
      ) : null}
      <ul>{props.children}</ul>
    </nav>
  </li>
)

export default MenuListSection
