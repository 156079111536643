import { isBrowser } from '@cinch-labs/shared-util'

import { AdobeDataLayer, FilterFormData, TargetResponseToken } from './types'

type Params = AdobeDataLayer['page']['vehicleInformation']
type compareInfo = AdobeDataLayer['page']['compareInformation']

export const getDataLayer = (): AdobeDataLayer | undefined => {
  if (isBrowser) {
    const dl = window.adobeData

    if (dl) {
      return dl
    }
  }

  return
}

export const getTrackingTargetMeta = (): TargetResponseToken[] | undefined => {
  if (isBrowser) {
    const ttMETA = window.ttMETA

    return ttMETA ?? []
  }

  return
}

export const updateCompareInformation = (data: compareInfo) => {
  const dl = getDataLayer()

  if (dl) {
    dl.page.compareInformation = data
  }
}

// Still used by dealer favouriting
export const updateVehicleInformation = (data: Params) => {
  const dl = getDataLayer()

  if (dl) {
    dl.page.vehicleInformation = data
  }
}

// Still used by dealer find vehicle
export const updateVehicleFilters = (data: FilterFormData) => {
  const dl = getDataLayer()

  if (dl) {
    dl.page.vehicleFilters = data
  }
}

/**
 * @deprecated
 */
export const updateFindVehiclePanel = (data: string) => {
  const dl = getDataLayer()

  if (dl) {
    dl.page.pageInfo.findVehiclePanel = data
  }
}
