import { Env, readFromEnv } from '@cinch-nx/environments'

import { TagResponse } from '../../types/shared'

const STORYBLOK_BFF_URL = `${readFromEnv(Env.StoryblokBFFComponent)}`
export const getTags = async () => {
  const params = new URLSearchParams({
    cv: Date.now().toString(),
  })
  const url = `${STORYBLOK_BFF_URL}/get-tags?${params.toString()}`

  try {
    const data = await fetch(url).then((res) => res.json())

    return (data as TagResponse).tags
  } catch {
    throw new Error('get tags endpoint not working')
  }
}
