interface GetCookieArgs {
  name: string
}

export interface CookieConsentPreference {
  analytics: boolean
  advertising: boolean
  functional: boolean
}
const getCookie = ({ name }: GetCookieArgs): string | undefined =>
  document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() ?? undefined

const cookiePreferencesCookieParser = (
  cookieContents: string | undefined,
): CookieConsentPreference | undefined =>
  cookieContents ? JSON.parse(cookieContents) : undefined

const cookiePreferences = () =>
  cookiePreferencesCookieParser(getCookie({ name: 'cp' }))

export const hasConsentedToCookies = (type: keyof CookieConsentPreference) =>
  cookiePreferences()?.[type] ?? false
