export const ArrowRight = () => (
  <svg
    width="24"
    height="12"
    viewBox="0 0 24 12"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden
  >
    <path
      d="M17.4697 1.53033L21.1893 5.25L0.75 5.25C0.335787 5.25 -2.69446e-07 5.58579 -2.5134e-07 6C-2.33235e-07 6.41421 0.335787 6.75 0.75 6.75L21.1893 6.75L17.4697 10.4697C17.1768 10.7626 17.1768 11.2374 17.4697 11.5303C17.7626 11.8232 18.2374 11.8232 18.5303 11.5303L23.5303 6.53033C23.671 6.38968 23.75 6.19891 23.75 6C23.75 5.80109 23.671 5.61032 23.5303 5.46967L18.5303 0.469669C18.2374 0.176775 17.7626 0.176775 17.4697 0.469669C17.1768 0.762562 17.1768 1.23744 17.4697 1.53033Z"
      fill="currentColor"
    />
  </svg>
)
