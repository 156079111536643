import {
  Primary as PrimaryButton,
  Secondary,
  Text,
} from '@cinch-labs/design-system'
import { GetServerSidePropsContext } from 'next'
import React from 'react'

import styles from './landing-feature-error-page.module.css'

export interface LandingFeatureErrorPageProps {
  title?: string
  subTitle?: string
  image?: React.FC<React.SVGProps<SVGElement>>
}

export interface WithoutCTA extends LandingFeatureErrorPageProps {
  hasCta: false
  ctaText?: undefined
  ctaHref?: undefined
  searchCarsCtaText?: undefined
}

export interface WithCta extends LandingFeatureErrorPageProps {
  hasCta: true
  ctaText: string
  ctaHref: string
  searchCarsCtaText?: string
}

export function getStaticProps(context: GetServerSidePropsContext) {
  return {
    props: {
      generatedAt: Date.now().toString(),
      layoutProps: {
        showBorderRadius: true,
      },
    },
    revalidate: 300,
  }
}

export function LandingFeatureErrorPage({
  title,
  subTitle,
  image: Image,
  hasCta,
  ctaHref,
  ctaText,
  searchCarsCtaText,
}: WithoutCTA | WithCta) {
  return (
    <div className={styles['container']}>
      {title ? (
        <Text element="h1" className={styles['errorPageTitle']}>
          {title}
        </Text>
      ) : null}
      {subTitle ? (
        <Text className={styles['errorPageSubTitle']} element="h2">
          {subTitle}
        </Text>
      ) : null}
      {Image ? (
        <span className={styles['errorPageImage']}>
          <Image />
        </span>
      ) : null}
      {hasCta ? (
        <div className={styles['actionButtons']}>
          <PrimaryButton element="a" href={ctaHref}>
            {ctaText}
          </PrimaryButton>
          {searchCarsCtaText && searchCarsCtaText !== '' ? (
            <Secondary element="a" href="/find-vehicle">
              {searchCarsCtaText}
            </Secondary>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
