import { TextFontWeights, TextLink } from '@cinch-labs/design-system'

import type { FooterLink as FooterLinkType } from '../types'

const FooterLink: React.FC<FooterLinkType> = ({ href, label }) => (
  <TextLink
    href={href}
    isOnlyWhite={true}
    hasNoLine={false}
    textProps={{
      fontWeight: TextFontWeights.semibold,
      fontSize: 's',
    }}
  >
    {label}
  </TextLink>
)

export default FooterLink
