import { track } from '@cinch-labs/customer-data-segment'

import { CookieBannerMetrics, CookieSettings } from '../cookie-consent.types'

export const cookieSettings = (settings: CookieSettings): void => {
  track('Cookie Settings', settings)
}

export const cookieBannerMetrics = (settings: CookieBannerMetrics): void => {
  track('Cookie Banner metrics', settings)
}

export const cookieEditorShown = (timeStamp: number): void => {
  track('Cookie Editor Shown', {
    event_timing: timeStamp,
  })
}
