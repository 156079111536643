import { z } from 'zod'

export const acceptedLinkStarts = z
  .union([
    z.literal('categoriespages/makes'),
    z.literal('categoriespages/models'),
    z.literal('car-reviews'),
    z.literal('jargon'),
    z.literal('articles/guides/ask-the-experts'),
    z.literal('articles/guides/car-finance'),
    z.literal('articles/guides/car-maintenance'),
    z.literal('articles/guides/choosing-a-car'),
    z.literal('articles/guides/electric-cars'),
    z.literal('articles/news'),
    z.literal('articles/announcements/cinch-news'),
    z.literal('articles/hubs'),
    z.literal('seo-pages/electric-cars'),
    z.literal('seo-pages/car-finance'),
    z.literal('seo-pages/offers'),
    z.literal('seo-pages/car-inspection/sub-pages'),
    z.literal('seo-pages/car-inspection/index'),
    z.literal('policies'),
    z.literal('policies/modern-slavery-statement'),
    z.literal('car-care'),
  ])
  .optional()

export type AcceptedLinksStarts = z.infer<typeof acceptedLinkStarts>
