import { useCustomerData } from '@cinch-labs/customer-data-segment'

import styles from './deposit-contribution.module.css'

interface DepositContributionBannerProps {
  sendDigitalDataEvent: ((data?: Record<string, unknown>) => void) | undefined
}

export function DepositContributionBanner({
  sendDigitalDataEvent,
}: DepositContributionBannerProps) {
  const { trackEvent } = useCustomerData()
  const handleOnClick = () => {
    const data = {
      event: {
        name: 'click',
        category: 'link',
        action: 'navigate',
        label: 'selected electric cars',
      },
    }
    // TECHDEBT - currently this only fires *some* of the time,
    // there is a ticket SCD-284 to fix it and make it more accurate
    trackEvent({
      type: 'track',
      eventName: 'Promotion Clicked',
      data: {
        name: 'Deposit contribution promo banner',
        position: 'top banner',
      },
    })
    if (sendDigitalDataEvent) sendDigitalDataEvent(data)
  }
  return (
    <>
      <span className={styles.saveTag}>SAVE</span>
      <p className={styles.text}>
        Up to £2,000 towards your finance deposit on{' '}
        <a
          href="/find-vehicle?tags=deposit-contribution"
          onClick={handleOnClick}
        >
          selected electric cars
        </a>
        . Ends 4pm 24 Jan.{' '}
        <a href="/policies/deposit-contribution-electric-cars">T&Cs apply</a>.
        9.9% APR Representative.
      </p>
    </>
  )
}

export default DepositContributionBanner
