import {
  useCustomerData,
  SegmentEvent,
} from '@cinch-labs/customer-data-segment'

import styles from './spring-sale-promo-banner.module.css'

interface SalePromoBannerProps {
  sendDigitalDataEvent: ((data?: Record<string, unknown>) => void) | undefined
}

export function SpringSalePromoBanner({
  sendDigitalDataEvent,
}: SalePromoBannerProps) {
  const { trackEvent } = useCustomerData()
  const handleOnClick = (value: string) => {
    const data = {
      event: {
        name: 'click',
        category: 'promotions',
        action: 'banner',
        label: 'spring sale 2023',
        value,
      },
    }
    trackEvent({
      type: 'track',
      eventName: 'Promotion Clicked',
      data,
    } as unknown as SegmentEvent)

    if (sendDigitalDataEvent) sendDigitalDataEvent(data)
  }
  return (
    <>
      <span className={styles.saveTag}>SAVE</span>
      <p className={styles.text}>
        Up to £2000 off{' '}
        <a
          href="/find-vehicle?tags=cars-on-offer"
          onClick={() => handleOnClick('selected EV')}
        >
          selected EVs
        </a>{' '}
        plus £500 charging on us with cinchCharge. Ends 4pm 20/03, T&Cs apply.{' '}
        <a href="/offers" onClick={() => handleOnClick('find out more')}>
          Find out more
        </a>
      </p>
    </>
  )
}

export default SpringSalePromoBanner
