import { useCustomerData } from '@cinch-labs/customer-data-segment'

import styles from './winter-sale.module.css'

interface WinterSaleBannerProps {
  sendDigitalDataEvent: ((data?: Record<string, unknown>) => void) | undefined
}

export function WinterSaleBanner({
  sendDigitalDataEvent,
}: WinterSaleBannerProps) {
  const { trackEvent } = useCustomerData()
  const handleOnClick = () => {
    const data = {
      event: {
        name: 'click',
        category: 'winter flash sale',
        action: 'shop cars on offer',
        label: 'hero',
      },
    }
    // TECHDEBT - currently this only fires *some* of the time,
    // there is a ticket SCD-284 to fix it and make it more accurate
    trackEvent({
      type: 'track',
      eventName: 'Promotion Clicked',
      data: {
        name: 'Winter sale promo banner - cars on offer',
        position: 'top banner',
      },
    })
    if (sendDigitalDataEvent) sendDigitalDataEvent(data)
  }
  return (
    <>
      <span className={styles.saveTag}>SAVE</span>
      <p className={styles.text}>
        Save up to <b>£1000</b> on{' '}
        <a href="/find-vehicle?tags=cars-on-offer" onClick={handleOnClick}>
          selected cars
        </a>
        . Discount varies by car.{' '}
        <a href="/policies/winter-sale">T&Cs apply.</a>
      </p>
    </>
  )
}

export default WinterSaleBanner
