import { AxiosResponse } from 'axios'

export interface OrderDetailsResponse {
  expiresAt: number
  amountPaidInPence: number
  amountPayableInPence: number
  cancelledAt?: number
  createdAt: number
  completedAt?: number
  customerContactTelephoneNumber: string
  customerEmail: string
  customerFirstName: string
  customerLastName: string
  deliveryAddress?: OrderDetailsDeliveryAddress
  /**
   * An undefined value represents a Delivery
   */
  lastUpdatedAt?: number
  logisticalType?: LogisticalType
  collectionAddress?: OrderDetailsCollectionAddress
  deliverySlot?: {
    date: string
    startHour: number
    endHour: number
    reservationId: string
  }
  financeApplication?: FinanceApplicationResponse
  id: string
  userId: string
  lineItems: OrderDetailsLineItem[]
  orderRef: string
  orderStatus: OrderDetailsStatus
  orderTotalInPence: number
  partExVehicle?: OrderDetailsPartExVehicle
  paymentRecords: OrderDetailsPaymentRecord[]
  vehicle: OrderDetailsVehicle
  valueAddedProducts?: OrderDetailsValueAddedProduct[]
}

interface OrderDetailsDeliveryAddress {
  addressLine1: string
  addressLine2?: string
  postCode: string
  city: string
  county: string
  deliveryInstructions?: string
}

type LogisticalType = 'Delivery' | 'Collection'
export interface OrderDetailsValueAddedProduct {
  name: string
  priceInPence: number
}

interface OrderDetailsPartExVehicle {
  valuationId: string
  mileage: number
  make: string
  model: string
  derivative: string
  condition?: string
  valueInPence: number
}

interface OrderDetailsLineItem {
  referenceId: string
  productType: string
  priceInPence: number
}

interface FinanceApplicationResponse {
  id?: string
  advanceAmountInPence?: number
  status: OrderDetailsFinanceApplicationStatus
}

export type OrderDetailsFinanceApplicationStatus =
  | 'ApplicationStarted'
  | 'ApplicationSubmitted'
  | 'ApplicationDeclined'
  | 'ApplicationApproved'
  | 'ApplicationExpired'
  | 'ApplicationDepositReceived'
  | 'ESignComplete'

interface OrderDetailsPaymentRecord {
  referenceId: string
  paymentType: string
  amountInPence: number
}

export interface OrderDetailsVehicle {
  model: string
  derivative: string
  id: string
  make: string
  priceInPence: number
  imageUrl?: string
  vin: string
  vrm: string
  stockType: string
  registrationDate: string
  engineCapacityCc: number
  mileage: number
}

interface OrderDetailsCollectionAddress {
  id: string
  companyName: string
  postCode: string
  addressLine1: string
  addressLine2?: string
  city?: string
  county?: string
  coordinates: {
    latitude: number
    longitude: number
  }
}

export interface CinchCareOrdersResponse {
  planNumber?: string
  vehicleVrm: string
  annualMileage: number
  price: number
  startDate: string
  coverLevel: string
  modified: string
  updatedVehicleVrm?: string
}

export interface CinchCareOrder {
  plan: CinchCareOrdersResponse
  vehicle: OrderDetailsResponse['vehicle'] | undefined
}

export interface VehicleOrder {
  order: OrderDetailsResponse
  eligibleForCinchCare?: boolean
}

export type OrderDetailsStatus =
  | 'InProgress'
  | 'Fulfilled'
  | 'CancellationRequested'
  | 'Cancelled'
  | 'ReadyToComplete'
  | 'OrderCompleted'
  | 'AwaitingCustomerExperienceIntervention'
  | 'ReturnRequested'
  | 'OrderReturned'
  | 'AwaitingFinanceDecision'
  | 'AwaitingFinanceDeposit'
  | 'FinanceDeclined'
  | 'DepositReceived'

export type FetchStatus = 'initial' | 'pending' | 'valid' | 'invalid'

export interface OrdersStore {
  activateCinchCare: (payload: ActivateCinchCare) => unknown
  activeOrder: OrderDetailsResponse | undefined
  refreshActiveOrder: () => Promise<void>
  checkIfVehicleIsReserved: (
    vehicleId: string,
  ) => Promise<{ reserved: boolean }>
  extendOrderExpiryDate: (orderId: string, token: string) => Promise<void>
  fetchOrders: () => Promise<void>
  fetchCinchCareOrders: () => Promise<void>
  cinchCareOrders: () => CinchCareOrder[] | undefined
  vehicleOrders: () => VehicleOrder[] | undefined
  vehicleOrdersByStatus: (
    ...statuses: OrderDetailsStatus[]
  ) => VehicleOrder[] | undefined
  vehicleOrderAddingCinchCare: VehicleOrder | undefined
  setVehicleOrderAddingCinchCare: (order: VehicleOrder) => void
  vehicleOrdersRequest: {
    status: FetchStatus
    orders: OrderDetailsResponse[] | undefined
  }
  cinchCareOrdersRequest: {
    status: FetchStatus
    orders: CinchCareOrdersResponse[] | undefined
  }
  cancelOrder: (
    orderId: string,
    cancelOrderReason: string,
    token: string,
  ) => Promise<AxiosResponse<OrderDetailsResponse>>
}

export interface CinchCareQuote {
  planDetails: {
    priceInPence: number
  }
  mileageMin: number
  mileageMax: number
}

export interface Vehicle {
  registrationDate: string
  id: string
  mileage: string
  vrm: string
  engineCapacityCc: number
  vin: string
  make: string
  model: string
  priceInPence: number
}

export interface ActivateCinchCare {
  cinchCarePriceInPence: number
  vehicle: Vehicle
  customerDetails: CustomerDetails
  estimatedMileage: number
  deliverySlot?: { date: string }
  completedAt: string
}

export interface CustomerDetails {
  bankDetails: BankDetails
  userId: string
  customerContactTelephoneNumber: string
  customerEmail: string
  customerFirstName: string
  customerLastName: string
  deliveryAddress: Address
  orderId: string
  orderRef: string
  estimatedMileage: number
  completedAt: string
}

export interface Address {
  postCode: string
  addressLine1: string
  addressLine2: string
  city: string
  county: string
  deliveryInstructions: string
}
export interface BankDetails {
  accountNumber: string
  sortCode: string
  accountHolderName: string
}

export type OrdersState =
  | 'both'
  | 'none'
  | 'orderOutstanding'
  | 'orderFulfilled'

export enum OrdersStates {
  Both = 'both',
  None = 'none',
  OrderOutstanding = 'orderOutstanding',
  OrderFulfilled = 'orderFulfilled',
}
