import { TrackingEvent } from './types/analytics'

export type trackEventCallback = (event: TrackingEvent) => void

export enum EventActions {
  click = 'click',
  cinchcareAddedToOrder = 'cinchCare added to order',
  modalOpened = 'modal-opened',
  modalClosed = 'modal-closed',
  returnToCheckout = 'return-to-checkout',
  orderCompleteClick = 'order complete click',
  orderDeclinedClick = 'order declined click',
  orderTrackingViewed = 'order tracking viewed',
  deadClick = 'dead click',
}

export enum EventCategories {
  button = 'button',
  cinchcare = 'cinchcare',
  favourites = 'Favourites',
  orders = 'Orders',
  orderTracking = 'Order Tracking',
  profileDetails = 'Profile Details',
  myProfile = 'My profile',
  image = 'image',
}

export enum EventNames {
  click = 'click',
  error = 'error',
  identify = 'identify',
  load = 'load',
}

export type StageName = 'local' | 'development' | 'production'
