import styles from './basket-navigation.module.css'
import { useContext, useState, useEffect } from 'react'
import { SharedUiHeaderV2Context } from '../../context/context-shared-ui-header-v2'
import { BasketIcon } from '../../../assets/basket-icon'
import { ArrowBorder } from '../../../assets/arrow-border'
import { TimeIcon } from '../../../assets/time'
import cx from 'classnames'

interface BasketNavigationProps {
  setShouldShowCheckoutModal: () => void
}

const BasketIconWrapper = () => (
  <div className={styles.basketIconsContainer}>
    <div className={styles.basketIcon}>
      <BasketIcon />
    </div>

    <div className={styles.arrowBorder}>
      <ArrowBorder />
    </div>
  </div>
)

export const BasketNavigation = ({
  setShouldShowCheckoutModal,
}: BasketNavigationProps) => {
  const { logicStore, userStore } = useContext(SharedUiHeaderV2Context)
  const [shouldShowExpiringOrExpired, setShouldShowExpiringOrExpired] =
    useState(false)
  const [isInCheckoutJourney, setIsInCheckoutJourney] = useState(false)

  const { handleTrackEvent } = logicStore
  const { isLoggedIn, activeOrder, refreshActiveOrder } = userStore

  // logic to determine time
  const warningTime = 5 * 60 * 1000

  const timeNow = Math.floor(Date.now())

  const orderHasExpired = (activeOrder?.expiresAt ?? 0) < Date.now()

  const isExpiringSoon = activeOrder?.expiresAt
    ? Math.floor(warningTime + timeNow) > activeOrder?.expiresAt
    : null

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { pathname } = window.location
      const regex = /checkout/
      setIsInCheckoutJourney(regex.test(pathname))

      /* updates the users order on page load */
      if (refreshActiveOrder) refreshActiveOrder()
    }
  }, [])

  /** 5 mins before expiry of order, this will run to change icon */
  useEffect(() => {
    const checkOrderStatus = activeOrder && warningTime - activeOrder?.expiresAt

    const timeoutId = setTimeout(() => {
      const hasOrderExpiredOrExpiring = !!(orderHasExpired || isExpiringSoon)
      setShouldShowExpiringOrExpired(hasOrderExpiredOrExpiring)
    }, checkOrderStatus)
    return () => clearTimeout(timeoutId)
  }, [])

  const isBasketVisible =
    isLoggedIn && activeOrder && isInCheckoutJourney === false

  const isExpiringOrExpiredBasket =
    isBasketVisible && shouldShowExpiringOrExpired === true

  return (
    <>
      {isExpiringOrExpiredBasket && (
        <button
          data-testid="checkout-basket"
          aria-label="Your basket order"
          className={styles.basketContainer}
          onClick={() => {
            setShouldShowCheckoutModal()
            handleTrackEvent({
              name: 'click',
              category: 'mega menu',
              label: 'basket',
              action: 'button',
            })
          }}
        >
          <div className={cx(styles.timerIcon, styles.pulse)}>
            <TimeIcon />
          </div>
          <BasketIconWrapper />
        </button>
      )}

      {isBasketVisible && !isExpiringOrExpiredBasket && (
        <button
          aria-label="Your basket order"
          className={styles.basketContainer}
          onClick={() => {
            setShouldShowCheckoutModal()
            handleTrackEvent({
              name: 'click',
              category: 'mega menu',
              label: 'basket',
              action: 'button',
            })
          }}
        >
          <span className={styles.number}>1</span>
          <BasketIconWrapper />
        </button>
      )}
    </>
  )
}
