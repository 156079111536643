import { STORYBLOK_BFF_URL } from '../../util/api'
import { toUrlSearchParams } from '../../util/to-url-search-params'
import {
  GetDatasourceEntriesOptions,
  GetDatasourceEntriesParams,
  GetDatasourceEntriesResponse,
  getDatasourceEntriesParams,
  getDatasourceEntriesResponse,
} from './schema'

export const getDatasourceEntries = async (
  params: GetDatasourceEntriesParams,
  { storyblokUrl = STORYBLOK_BFF_URL }: GetDatasourceEntriesOptions = {},
): Promise<GetDatasourceEntriesResponse> => {
  const urlParams = getDatasourceEntriesParams
    .transform(toUrlSearchParams)
    .parse(params)
  const url = `${storyblokUrl}/get-datasource-entries?${urlParams.toString()}`

  try {
    const response = await fetch(url)

    if (response.status !== 200) {
      throw new Error(
        `'get-datasource-entries' responded with non-OK response status ${response.status}`,
      )
    }

    return getDatasourceEntriesResponse.parse(await response.json())
  } catch (error) {
    if (error instanceof Error) {
      // eslint-disable-next-line unicorn/prefer-type-error
      throw new Error(
        `'get-datasource-entries' endpoint threw an error: ${error.message}`,
      )
    }

    throw new Error(
      `'get-datasource-entries' endpoint threw an unknown error: ${error}`,
    )
  }
}

export type {
  GetDatasourceEntriesParams,
  GetDatasourceEntriesResponse,
} from './schema'
