import styles from './portal-placeholder.module.css'

export interface PortalPlaceholderProps {
  shouldShowDropdown: boolean
  handleDesktopItemSelection: () => void
}

export const PortalPlaceholder = ({
  shouldShowDropdown,
  handleDesktopItemSelection,
}: PortalPlaceholderProps) => (
  <div
    className={shouldShowDropdown ? styles.dropdownModal : ''}
    id="portal"
    onClick={() => handleDesktopItemSelection()}
  ></div>
)
