import { useContext } from 'react'
import useSWR from 'swr'

import { SharedUiHeaderContext } from '../shared-ui-header.context'
import type { LatestCarReview } from '../types'

const fetcher = async (url: string): Promise<LatestCarReview> => {
  const request = await fetch(url)
  return request.json() as Promise<LatestCarReview>
}

export const useLatestCarReview = (isDesktop = true) => {
  const { stageName = 'development' } = useContext(SharedUiHeaderContext)

  const REQUEST_URL =
    stageName === 'production'
      ? 'https://storyblok-bff.snc-prod.aws.cinch.co.uk/latest-car-review'
      : 'https://storyblok-bff.snc-dev.aws.cinch.co.uk/latest-car-review'

  const { data } = useSWR<LatestCarReview>(
    isDesktop ? REQUEST_URL : null,
    fetcher,
  )

  return data
}
