import {
  addIntersectionObserver,
  removeIntersectionObserver,
} from '@cinch-labs/shared-util'
import cx from 'classnames'
import { SyntheticEvent, useEffect, useState } from 'react'

import styles from './lazy-image.module.css'

export interface LazyProps
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  onError?: (event?: SyntheticEvent) => void
}

const LazyImage: React.FC<LazyProps> = ({
  className = '',
  onError,
  ...props
}) => {
  let imgRef: Element
  const [isLoaded, updateLoadedState] = useState(false)
  const [isVisible, setVisibility] = useState(false)

  const handleIntersection = (isIntersecting: boolean) => {
    if (isIntersecting && !!imgRef) {
      removeIntersectionObserver(imgRef)
      setVisibility(true)
    }
  }

  const addObserver = () => {
    if (imgRef) {
      addIntersectionObserver(handleIntersection, imgRef)
    }
  }

  useEffect(() => {
    if (!imgRef) {
      return
    }

    addObserver()

    return () => {
      if (imgRef) {
        removeIntersectionObserver(imgRef)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.src])

  const handleRef = (ref: Element) => {
    if (!ref && imgRef) {
      removeIntersectionObserver(imgRef)
    }
    imgRef = ref
    addObserver()
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const visibleProp = {} as any

  if (isVisible) {
    visibleProp.src = props.src
  }

  return (
    <div className={styles.lazyWrapper}>
      <img
        className={cx(styles.lazy, className, {
          [styles.lazyLoaded]: isLoaded,
        })}
        ref={handleRef}
        alt={props.alt || ''}
        onError={onError}
        onLoad={() => updateLoadedState(true)}
        {...visibleProp}
      />
    </div>
  )
}

export default LazyImage
