import { datadogRum } from '@datadog/browser-rum'
import { IncomingMessage } from 'http'
import { NextPageContext } from 'next'
import { NextApiRequestCookies } from 'next/dist/server/api-utils'
import { useEffect } from 'react'

import { default as SignPost } from '../../../assets/404.svg'
import { LandingFeatureErrorPage } from '../../error-page/landing-feature-error-page'

interface GenericObject {
  [key: string]: string
}
interface ErrorInformation {
  params: string
  query: GenericObject
  cookies: NextApiRequestCookies
  headers: IncomingMessage['headers']
}

interface CustomErrorProps {
  statusCode: number
  error: Error | ErrorInformation
}

const CustomError = ({ statusCode, error }: CustomErrorProps) => {
  useEffect(() => {
    if (process.env['STAGE_NAME'] !== 'local') {
      datadogRum.addError(new Error(`An error of statusCode ${statusCode}`), {
        route: window.location.pathname,
        errorInformation: error,
      })
    } else {
      console.log({ error, statusCode })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <LandingFeatureErrorPage
      title={`${statusCode} - Oops! Something went wrong`}
      subTitle="Don’t worry, we can put you back on track!"
      image={SignPost}
      hasCta={true}
      ctaText="Take me home"
      ctaHref="/"
    />
  )
}

CustomError.getInitialProps = ({
  res,
  req,
  err,
  query,
  pathname,
}: NextPageContext) => {
  const statusCode = res ? res.statusCode : err ? err.message : 404

  const errorLogs = {
    url: req?.url,
    pathname,
    query,
    cookies: req?.headers?.cookies,
    headers: req?.headers,
  }

  const error = err ? err : errorLogs

  return { statusCode, error }
}

export default CustomError
