import { Destination } from '../cookie-consent.types'

export const groupDestinations: (
  group: Destination[],
  prop: keyof Destination,
) => { [key: string]: Destination[] } = (group, prop) =>
  // eslint-disable-next-line unicorn/prefer-object-from-entries
  group.reduce<{ [key: string]: typeof group[0][] }>((grouped, item) => {
    const key = item[prop]

    if (!Object.prototype.hasOwnProperty.call(grouped, prop)) {
      grouped[key] = []
    }

    grouped[key].push(item)
    return grouped
  }, {})
