import type { StoryblokGetLinksResponse } from '../../types/shared'
import { STORYBLOK_BFF_URL } from '../../util/api'
import { AcceptedLinksStarts, acceptedLinkStarts } from './schema'

export const getLinks = async (startsWith?: AcceptedLinksStarts) => {
  startsWith = acceptedLinkStarts.parse(startsWith)
  const params = new URLSearchParams({
    cv: Date.now().toString(),
    ...(startsWith && { starts_with: startsWith }),
    version: 'published',
  })

  const url = `${STORYBLOK_BFF_URL}/get-links?${params.toString()}`

  try {
    const res = await fetch(url)
    const data: StoryblokGetLinksResponse = await res.json()

    if (data?.links && Object.keys(data.links).length === 0) return {}

    return data.links
  } catch {
    throw new Error('links endpoint not working')
  }
}
