export enum TrackingEventTypes {
  TRACK = 'trackQueue',
  IDENTIFY = 'identifyQueue',
  PAGE = 'pageQueue',
  ADOBE = 'adobeQueue',
  HOTJAR_EVENT = 'hotJarEventQueue',
  HOTJAR_TAG = 'hotJarTagQueue',
  HOTJAR_TRIGGER = 'hotJarTriggerQueue',
}
interface AnalyticsEvent {
  eventName: string
  type: Exclude<TrackingEventTypes, TrackingEventTypes.IDENTIFY>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
}

interface IdentifyEvent {
  type: TrackingEventTypes.IDENTIFY
  userId?: string
  data?: Record<string, unknown>
}

export type TrackingEvent = AnalyticsEvent | IdentifyEvent
