import { z } from 'zod'

import { cv } from '../../types/schema'

export const getDatasourceEntriesParams = z.object({
  cv,
  datasource: z.string(),
  dimension: z.string().optional(),
})

export const datasourceEntry = z.object({
  id: z.number(),
  name: z.string(),
  value: z.string(),
  dimension_value: z.string().nullable(),
})

export const getDatasourceEntriesResponse = z.object({
  datasource_entries: z.array(datasourceEntry),
})

export type GetDatasourceEntriesParams = z.infer<
  typeof getDatasourceEntriesParams
>

export type GetDatasourceEntriesResponse = z.infer<
  typeof getDatasourceEntriesResponse
>

export type GetDatasourceEntriesOptions = {
  storyblokUrl?: string
}
