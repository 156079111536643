import { Primary, Text, TextStyles } from '@cinch-labs/design-system'
import cx from 'classnames'

import Cross from '../../assets/cross.svg'
import * as defaultCardStyles from './card.module.css'

export interface CardProps {
  children?: React.ReactNode
  title?: string | React.ReactNode
  subtitle?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cardStyles?: any
  closeText?: string
  closeIcon?: string
  closeIconSize?: number
  classNames?: {
    body?: string
    title?: string
    titleContainer?: string
    closeIcon?: string
    card?: string
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onClose?: (e: any) => void
  id?: string
}

const Card: React.FC<CardProps> = ({
  children,
  title = '',
  subtitle = '',
  onClose,
  closeText,
  cardStyles,
  classNames,
  id,
  ...props
}) => {
  const styles = cardStyles || defaultCardStyles

  return (
    <div
      className={cx(styles.card, classNames?.card)}
      {...props}
      data-id="card"
      id={id}
    >
      {(title || onClose) && (
        <div className={cx(styles.title, classNames?.titleContainer)}>
          <Text
            type={TextStyles.cardTitle}
            element="h5"
            className={classNames?.title}
          >
            {title}
            {subtitle && (
              <Text
                className={styles.subtitle}
                fontWeight="light"
                element="span"
              >
                {subtitle}
              </Text>
            )}
          </Text>

          {onClose && !closeText && (
            <button
              title="Close"
              onClick={onClose}
              className={cx(styles.close, classNames?.closeIcon)}
              data-testid="card-close-button"
            >
              <Cross />
            </button>
          )}
          {onClose && closeText && (
            <Primary
              onClick={onClose}
              classNames={{ button: styles.textButton }}
              type="button"
              data-testid="card-close-button"
            >
              {closeText}
            </Primary>
          )}
        </div>
      )}
      <div className={cx(styles.body, classNames?.body)} data-id="card-body">
        {children}
      </div>
    </div>
  )
}

export default Card
