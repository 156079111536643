import {
  SharedUiHeaderContext,
  noOpTracking,
} from '../../../shared-ui-header.context'
import styles from '../../return-to-checkout-modal.module.css'
import { useReturnToCheckoutModalTracking } from '../../return-to-checkout-modal.tracking'
import { ModalState } from '../../return-to-checkout-modal.types'
import { VehicleInformation } from '../../vehicle-information/vehicle-information.component'
import { ReturnToCheckoutCardProps } from '../types'
import { Secondary, Text } from '@cinch-labs/design-system'
import React, { useContext, useEffect } from 'react'

export interface ReturnToCheckoutExpiredUnavailableProps
  extends ReturnToCheckoutCardProps {
  i18n: {
    text: string
    buttonText: string
  }
}

export const ReturnToCheckoutExpiredUnavailable: React.FC<
  ReturnToCheckoutExpiredUnavailableProps
> = ({ i18n, vehicle, onButtonClick, title, isButtonLoading }) => {
  const { trackEvent } = useContext(SharedUiHeaderContext)
  const { resumeCheckoutOpenedModal } = useReturnToCheckoutModalTracking(
    trackEvent ?? noOpTracking,
  )

  useEffect(() => {
    resumeCheckoutOpenedModal(ModalState.expiredUnavailable)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.contentWrapper}>
      {title}
      <VehicleInformation vehicle={vehicle} hidePrice={true} grayscale={true} />
      <Text
        className={styles.expiredUnavailableText}
        fontWeight={'light'}
        fontSize={'s'}
      >
        {i18n.text}
      </Text>

      <Secondary
        isLoading={isButtonLoading}
        onClick={onButtonClick}
        size="large"
        isFullWidth={true}
      >
        {i18n.buttonText}
      </Secondary>
    </div>
  )
}
