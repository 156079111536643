import { isBrowser, getAnalyticIDs } from '@cinch-labs/shared-util'

interface DigitalDataEventExperiment {
  name: string
  value: string | undefined
}

export interface AddToDigitalDataEvent {
  experiment: DigitalDataEventExperiment
}

interface DataLayerEvent {
  name: string
}

interface DataLayerPage {
  pageName: string
  path: string
  previousPage: string
}

export interface DigitalDataEvent {
  event?: DataLayerEvent
  experiment?: DigitalDataEventExperiment
  page?: DataLayerPage
}

export const sendDataLayerEvent = (data: unknown) => {
  if (isBrowser) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push(data)
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addServiceTrackingIdsToEvent = (data: Record<string, any>) => {
  if (
    typeof window === 'undefined' ||
    !Object.prototype.hasOwnProperty.call(data, 'event')
  ) {
    return data
  }
  const { adobeID, gaID } = getAnalyticIDs()
  const event = data.event

  if (adobeID) {
    event.adobeID = adobeID
  }

  if (gaID) {
    event.gaID = gaID
  }

  return {
    ...data,
    event,
  }
}

export const sendDigitalDataEvent = <T = unknown>(data?: Record<string, T>) => {
  if (window && data) {
    window.digitalData = window.digitalData || []
    const trackableEvent = addServiceTrackingIdsToEvent(data)
    window.digitalData.push(trackableEvent)
  }
}

export const addToDigitalDataEvent = (data: AddToDigitalDataEvent) => {
  if (typeof window !== 'undefined' && window.digitalData.length > 0) {
    const updatedDigitDataLayer = window.digitalData.reduce(
      (dataLayer, event) => {
        const updatedDataLayerObject = {
          ...event,
          ...data,
        }

        return [{ ...dataLayer, ...updatedDataLayerObject }]
      },
      [] as DigitalDataEvent[],
    )

    window.digitalData = updatedDigitDataLayer as DigitalDataEvent[]
  }
}

export const sendHotjarEvent = (event: string) => {
  if (typeof window !== 'undefined' && window.hj) {
    window.hj('event', event)
  }
}

export const sendHotjarTag = (tag: string) => {
  if (typeof window !== 'undefined' && window.hj) {
    window.hj('tagRecording', [tag])
  }
}

export const sendHotjarTrigger = (triggerName: string) => {
  if (typeof window !== 'undefined' && window.hj) {
    window.hj('trigger', triggerName)
  }
}
