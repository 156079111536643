import SocialMediaLink from '../social-media-link/social-media-link'
import type { SocialMediaLink as SocialMediaLinkType } from '../types'
import styles from './social-media-link-list.module.css'

interface SocialMediaLinkListProps {
  links: SocialMediaLinkType[]
}

const SocialMediaLinkList: React.FC<SocialMediaLinkListProps> = ({ links }) => (
  <ul className={styles.list}>
    {links.map((props) => (
      <SocialMediaLink key={props.name} {...props} />
    ))}
  </ul>
)

export default SocialMediaLinkList
