import useActiveMenu from '../hooks/use-active-menu'
import {
  CheckoutStage,
  OrderCheckoutStage,
  ReturnToCheckoutModal,
} from '../return-to-checkout-modal'
import {
  SharedUiHeaderContext,
  SharedUiHeaderContextProps,
} from '../shared-ui-header.context'
import { Basket } from './basket.component'
import { useExpiry } from './hooks/use-expiry-warning'
import { isBrowser } from '@cinch-labs/shared-util'
import { useContext, useEffect, useState } from 'react'

const isOrderCheckoutStage = (
  maybeCheckoutStage: unknown,
): maybeCheckoutStage is OrderCheckoutStage =>
  Object.prototype.hasOwnProperty.call(maybeCheckoutStage, 'orderId') &&
  Object.prototype.hasOwnProperty.call(maybeCheckoutStage, 'stage')

const getLastCheckoutStage = (
  activeOrder?: SharedUiHeaderContextProps['activeOrder'],
): OrderCheckoutStage | undefined => {
  const storageLastCheckoutStage = isBrowser
    ? JSON.parse(sessionStorage?.getItem('checkout-last-visited-stage') ?? '{}')
    : null

  if (!activeOrder) {
    return
  }

  if (
    isOrderCheckoutStage(storageLastCheckoutStage) &&
    storageLastCheckoutStage.orderId === activeOrder?.id
  ) {
    return storageLastCheckoutStage
  }

  return {
    orderId: activeOrder.id,
    stage: CheckoutStage.PersonalDetails,
  }
}

export const NavigationBasket: React.FC = () => {
  const { activeOrder, refreshActiveOrder, isLoggedIn } = useContext(
    SharedUiHeaderContext,
  )
  const { setActiveMenu } = useActiveMenu()

  const lastVisitedCheckoutStage = getLastCheckoutStage(activeOrder)

  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [showExpiryTimer, setShowExpiryTimer] = useState(false)
  const [timeRemainingInSeconds, setTimeRemaining] = useState(0)

  useExpiry({
    warningTime: 5 * 60 * 1000,
    expiresAt: activeOrder?.expiresAt,
    setShowExpiryWarning: setShowExpiryTimer,
    startTimerAt: setTimeRemaining,
  })

  useEffect(() => {
    if (isLoggedIn) {
      refreshActiveOrder?.()
    }
  }, [isLoggedIn, refreshActiveOrder])

  const handleBasketClick = () => {
    setModalIsOpen(!modalIsOpen)
    setActiveMenu(null)
  }

  if (activeOrder?.id) {
    return (
      <>
        <Basket
          onClick={() => handleBasketClick()}
          showExpiryTimer={showExpiryTimer}
          orderHasExpired={(activeOrder?.expiresAt ?? 0) < Date.now()}
        />
        {modalIsOpen && (
          <ReturnToCheckoutModal
            activeOrder={activeOrder}
            lastVisitedCheckoutStage={lastVisitedCheckoutStage}
            onClose={() => setModalIsOpen(false)}
            timeRemainingInSeconds={timeRemainingInSeconds}
            withinExpiryTimeWindow={showExpiryTimer}
          />
        )}
      </>
    )
  }

  return null
}
