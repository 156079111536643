import FooterLink from '../footer-link/footer-link'
import type { FooterLink as FooterLinkType } from '../types'
import styles from './footer-links-list.module.css'

interface FooterLinksListProps {
  links: FooterLinkType[]
}

const FooterLinksList: React.FC<FooterLinksListProps> = ({ links }) => (
  <ul className={styles.links}>
    {links.map(({ label, href }) => (
      <li className={styles.link} key={label}>
        <FooterLink label={label} href={href} />
      </li>
    ))}
  </ul>
)

export default FooterLinksList
