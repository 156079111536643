import { BaseComponent } from '../../types/components'
import { StoryBlokSingleStory, StoryData } from '../../types/shared'
import { STORYBLOK_BFF_URL } from '../../util/api'
import { GetStoryBySlugParams, getStoryBySlugUrlParams } from './schema'

const hasStoryblokStory = <T extends BaseComponent>(
  input: unknown,
): input is StoryBlokSingleStory<T> => {
  if (!input || !Object.prototype.hasOwnProperty.call(input, 'story')) {
    return false
  }

  return true
}

export async function getStoryBySlug<ContentType extends BaseComponent>(
  slug: string,
  params?: GetStoryBySlugParams,
): Promise<StoryData<ContentType> & { rels?: unknown[] }> {
  const urlParams = getStoryBySlugUrlParams.parse(params)
  urlParams.append('slug', slug)

  const url = `${STORYBLOK_BFF_URL}/story-by-slug?${urlParams.toString()}`

  try {
    const response = await fetch(url).then((res) => res.json())

    if (!hasStoryblokStory<ContentType>(response)) {
      throw new Error('Story Response has no story property.')
    }

    return { ...response.story, rels: response.rels }
  } catch (error) {
    let errorMessage = error || 'Unknown Error'

    if (error instanceof Error) {
      errorMessage = error.message
    }

    throw new Error(`'story-by-slug' endpoint error
        Original Error: "${errorMessage}"
        Slug: "${slug}",
        Failing Url: "${url}"
    `)
  }
}

export type { GetStoryBySlugParams } from './schema'
