import 'intersection-observer'

class Intersection {
  public static observer: IntersectionObserver
  public static listeners: Map<
    Element,
    {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      callback: (isIntersecting: boolean, isInitialLoad: boolean) => any
      isInitialLoad: boolean
    }
  > = new Map()
}

const hasIntersectionObserver = (element: Element) =>
  Intersection.listeners.has(element)

export const removeIntersectionObserver = (element: Element) => {
  Intersection.observer.unobserve(element)
  Intersection.listeners.delete(element)
}

export const addIntersectionObserver = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  callback: (isIntersecting: boolean, isInitialLoad: boolean) => any,
  element: Element,
) => {
  if (Intersection.listeners.size === 0) {
    Intersection.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const target = entry.target
        const observer = Intersection.listeners.get(target)
        if (observer) {
          observer.callback(
            entry.isIntersecting || !!entry.intersectionRatio,
            observer.isInitialLoad,
          )
          observer.isInitialLoad = false
        }
      })
    })
  }
  if (!hasIntersectionObserver(element)) {
    Intersection.listeners.set(element, { callback, isInitialLoad: true })
    Intersection.observer.observe(element)
  }
}
