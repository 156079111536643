import { useCustomerData } from '@cinch-labs/customer-data-segment'

import styles from './sale-promo-banner.module.css'

interface SalePromoBannerProps {
  sendDigitalDataEvent: ((data?: Record<string, unknown>) => void) | undefined
}

export function SalePromoBanner({
  sendDigitalDataEvent,
}: SalePromoBannerProps) {
  const { trackEvent } = useCustomerData()
  const handleOnClick = () => {
    const data = {
      event: {
        name: 'click',
        category: 'link',
        action: 'navigate',
        label: 'shop sale now',
      },
    }
    trackEvent({
      type: 'track',
      eventName: 'Promotion Clicked',
      data: {
        name: 'Sale promo banner - cars on offer',
        position: 'top banner',
      },
    })
    if (sendDigitalDataEvent) sendDigitalDataEvent(data)
  }
  return (
    <>
      <span className={styles.saveTag}>SHOP</span>
      <p className={styles.text}>
        Hundreds of price reductions{' '}
        <a href="/find-vehicle?tags=cars-on-offer" onClick={handleOnClick}>
          on selected cars
        </a>
        , from £250 - £2000 off
      </p>
    </>
  )
}

export default SalePromoBanner
