import Wrapper from '@cinch-nx/shared-ui/lib/wrapper/wrapper'
import { formatBreadcrumbListSchema } from '../helpers'
import { JsonLdScript } from '../json-ld-script'
import styles from './breadcrumbs.module.css'
import type { BreadcrumbProps } from './types'

export const Breadcrumbs: React.FC<BreadcrumbProps> = ({ breadcrumbs }) => {
  const linkableCrumbs = breadcrumbs.filter(
    (el, idx) => idx !== breadcrumbs.length - 1,
  )
  const lastCrumb = breadcrumbs[breadcrumbs.length - 1]

  return (
    <>
      <JsonLdScript schema={formatBreadcrumbListSchema(breadcrumbs)} />
      <Wrapper isSlim className={{ inner: styles.wrapper }}>
        <ul className={styles.list} data-testid="bread-crumbs">
          {linkableCrumbs.map((crumb) => (
            <li className={styles.listItem} key={crumb.text}>
              <a href={crumb.url}>{crumb.text}</a>
            </li>
          ))}
          <li className={styles.listItem}>
            <span>{lastCrumb.text}</span>
          </li>
        </ul>
      </Wrapper>
    </>
  )
}

export default Breadcrumbs
