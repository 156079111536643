import { ChevronRight } from '../../../assets/chevron-right'
import { ChevronLeft } from '../../../assets/chevron-left'
import { ChevronDown } from '../../../assets/chevron-down'
import styles from './nav-item.module.css'
import cx from 'classnames'
import { ReactElement } from 'react'

export interface NavItemProps {
  shouldShowDropdown?: boolean
  handleDesktopItemSelection?: () => void
  handleSecondaryMobileMenu?: () => void
  handleTrackEvent: () => void
  isSecondaryMenu?: boolean
  title: string
  children?: ReactElement | null
}

export const NavItem = ({
  shouldShowDropdown = false,
  handleDesktopItemSelection,
  handleSecondaryMobileMenu,
  handleTrackEvent,
  isSecondaryMenu = false,
  title,
  children,
}: NavItemProps) => {
  const handleNavItemClick = () => {
    if (handleSecondaryMobileMenu) {
      handleSecondaryMobileMenu()
    }
    if (handleDesktopItemSelection) {
      handleDesktopItemSelection()
    }
    handleTrackEvent()
  }

  return (
    <>
      <button
        aria-label={title}
        className={cx(styles.navItemContainer, {
          [styles.navItemContainerActive]: shouldShowDropdown,
          [styles.secondaryNavItemContainer]: isSecondaryMenu,
        })}
        onClick={handleNavItemClick}
      >
        {isSecondaryMenu && (
          <div className={styles.mobileChevron}>
            <ChevronLeft />
          </div>
        )}

        <span>{title}</span>

        {!isSecondaryMenu && !shouldShowDropdown && (
          <div className={styles.mobileChevron}>
            <ChevronRight />
          </div>
        )}

        {!isSecondaryMenu && (
          <div className={styles.desktopChevron}>
            <ChevronDown />
          </div>
        )}
      </button>

      {shouldShowDropdown && children}
    </>
  )
}
