import styles from './menu-list-item.module.css'

interface MenuListItemProps {
  children: React.ReactNode
}

const MenuListItem: React.FC<MenuListItemProps> = (props) => (
  <li className={styles.menuListItem}>{props.children}</li>
)

export default MenuListItem
