import cx from 'classnames'

import ChevronDownIcon from '../../../../assets/chevron-down.svg'
import type { NavParent } from '../../../shared/menu-links'
import styles from './desktop-navigation-parent.module.css'

/**
 * DesktopNavigationParent is a root level meganav link that expands a dropdown
 * sub-menu on click
 * @example 'Find a car ⌄'
 */
const DesktopNavigationParent = (item: NavParent) => (
  <button
    className={cx(styles.navButton, {
      [styles.navButtonActive]: item.activeMenu === item.child,
    })}
    aria-label={
      item.activeMenu === item.child
        ? `Hide ${item.label} menu`
        : `Show ${item.label} menu`
    }
    aria-expanded={item.activeMenu === item.child}
    onClick={() => item.onButtonClick(item.child)}
    data-testid={`desktop-navigation-parent-${item.child}`}
  >
    <span className={styles.navButtonInner}>
      {item.label}
      <ChevronDownIcon className={styles.chevronDownIcon} />
    </span>
  </button>
)

export default DesktopNavigationParent
