export const BLOK_YOUTUBE = 'YoutubeVideo'

export interface YoutubeBlokProps extends Record<string, unknown> {
  videoId: string
  title: string
  buttonLabel: string
  thumbnail: {
    alt: string
    name: string
    title: string
    filename: string
  }
}

export const isYoutubeBlok = (
  props: Record<string, unknown>,
): props is YoutubeBlokProps =>
  Object.prototype.hasOwnProperty.call(props, 'videoId') &&
  Object.prototype.hasOwnProperty.call(props, 'title') &&
  Object.prototype.hasOwnProperty.call(props, 'buttonLabel')
