import cx from 'classnames'
import debounce from 'lodash/debounce'
import { useEffect, useState } from 'react'
import { useCallback } from 'react'
import { FocusOn } from 'react-focus-on'
import { CSSTransition } from 'react-transition-group'

import Portal from '../../portal/portal.component'
import { ChildMenuNames } from '../shared/menu-links'
import Header from './mobile-menu-header/mobile-menu-header'
import styles from './mobile-navigation.module.css'
import PrimaryMobileMenu from './primary-mobile-menu/primary-mobile-menu'
import SecondaryMobileMenu from './secondary-mobile-menu/secondary-mobile-menu'

export interface MobileNavigationProps {
  onClose: () => void
  isOpen: boolean
}

const MobileNavigation: React.FC<MobileNavigationProps> = ({
  onClose,
  isOpen,
}) => {
  const [activeMenu, setActiveMenu] = useState<ChildMenuNames>('findACar')
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(isOpen)
  const [isTransitioning, setIsTransitioning] = useState<boolean>(false)

  const [showPrimaryMenu, setShowPrimaryMenu] = useState<boolean>(true)
  const [showSecondaryMenu, setShowSecondaryMenu] = useState<boolean>(false)

  useEffect(() => {
    setIsMenuOpen(true)
    setShowSecondaryMenu(false)
  }, [])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnClose = useCallback(debounce(onClose, 500), [])

  const handleClose = () => {
    setIsMenuOpen(false)
    debouncedOnClose()
  }

  const handlePrimaryMenuClick = (menuName: ChildMenuNames) => {
    setIsTransitioning(true)
    setShowSecondaryMenu(true)
    setActiveMenu(menuName)
    setShowPrimaryMenu(false)
  }

  const handleHeaderBackClick = () => {
    setIsTransitioning(true)
    setShowPrimaryMenu(true)
    setShowSecondaryMenu(false)
  }

  return (
    <Portal>
      <div
        aria-modal="true"
        aria-expanded={isMenuOpen}
        data-testid="mobile-navigation"
      >
        <div
          className={styles.backdrop}
          onClick={handleClose}
          data-testid="backdrop"
        >
          <FocusOn onEscapeKey={handleClose}>
            <CSSTransition
              in={isMenuOpen}
              unmountOnExit
              timeout={500}
              classNames={{
                enter: styles.containerEnter,
                enterActive: styles.containerEnterActive,
                exit: styles.containerExit,
                exitActive: styles.containerExitActive,
              }}
            >
              <nav
                className={styles.inner}
                onClick={(e) => e.stopPropagation()}
              >
                <Header
                  close={handleClose}
                  onMainMenuClick={
                    showSecondaryMenu || (showPrimaryMenu && isTransitioning)
                      ? () => handleHeaderBackClick()
                      : undefined
                  }
                />
                <div
                  className={styles.menuContainer}
                  aria-labelledby="Main menu"
                  data-testid="mobile-main-menu"
                >
                  {/** Primary Menu */}
                  <CSSTransition
                    in={showPrimaryMenu}
                    timeout={300}
                    onEntered={() => setIsTransitioning(false)}
                    classNames={{
                      enter: styles.menuPrimaryEnter,
                      enterDone: styles.menuPrimaryEnterDone,
                      exit: styles.menuPrimaryExit,
                      exitDone: styles.menuPrimaryExitDone,
                    }}
                  >
                    <div
                      className={cx(styles.menu, styles.primaryMenu)}
                      aria-hidden={!showPrimaryMenu}
                      data-testid="mobile-primary-menu"
                      hidden={showSecondaryMenu && !isTransitioning}
                    >
                      <PrimaryMobileMenu
                        onButtonClick={(menuType) =>
                          handlePrimaryMenuClick(menuType)
                        }
                      />
                    </div>
                  </CSSTransition>
                  {/** Secondary Menu */}
                  <CSSTransition
                    in={showSecondaryMenu}
                    onEntered={() => setIsTransitioning(false)}
                    timeout={300}
                    classNames={{
                      enter: styles.menuSecondaryEnter,
                      enterDone: styles.menuSecondaryEnterDone,
                      exit: styles.menuSecondaryExit,
                      exitDone: styles.menuSecondaryExitDone,
                    }}
                  >
                    <div
                      className={cx(styles.menu, styles.secondaryMenu)}
                      aria-hidden={!showSecondaryMenu}
                      data-testid="mobile-secondary-menu"
                      hidden={showPrimaryMenu && !isTransitioning}
                    >
                      <SecondaryMobileMenu activeMenu={activeMenu} />
                    </div>
                  </CSSTransition>
                </div>
              </nav>
            </CSSTransition>
          </FocusOn>
        </div>
      </div>
    </Portal>
  )
}

export default MobileNavigation
