import {
  CookieBannerMetrics,
  CookieConsentPreference,
  CookieSettings,
  SettingsType,
} from '../cookie-consent.types'
import { getCookie, setCookie } from './cookie'

export const COOKIE_CONSENT_PREFERENCES_KEY = 'cp'

const cookiePreferencesCookieParser = (
  cookieContents: string | undefined | null,
): CookieConsentPreference | undefined =>
  cookieContents ? JSON.parse(cookieContents) : undefined

const getCookiePreferencesFromUrl = () => {
  if (!window) {
    return
  }

  try {
    const url = new URL(window.location.href)
    const preferences = url.searchParams.get(COOKIE_CONSENT_PREFERENCES_KEY)

    url.searchParams.delete(COOKIE_CONSENT_PREFERENCES_KEY)
    window.history.replaceState(window.history.state, 'unused', url.toString())

    return cookiePreferencesCookieParser(preferences)
  } catch {
    return
  }
}

export const getCookieConsentPreferences = () => {
  const cookiePreferencesFromUrl = getCookiePreferencesFromUrl()

  if (cookiePreferencesFromUrl) {
    setCookieConsentPreferences(cookiePreferencesFromUrl)
    return cookiePreferencesFromUrl
  }

  return cookiePreferencesCookieParser(
    getCookie({ name: COOKIE_CONSENT_PREFERENCES_KEY }),
  )
}

export const hasCookieConsentBeenSet = () =>
  getCookieConsentPreferences() ? true : false

export const setCookieConsentPreferences = (
  preferences: CookieConsentPreference,
) =>
  setCookie({
    name: COOKIE_CONSENT_PREFERENCES_KEY,
    value: JSON.stringify(preferences),
    expiryInDays: 365,
  })

export const getCookieSettings = (
  time: number,
  preferences: CookieConsentPreference,
  type: SettingsType,
): CookieSettings => ({
  preference_functional: preferences['functional'] ? true : false,
  preference_advertising: preferences['advertising'] ? true : false,
  preference_marketing: preferences['analytics'] ? true : false,
  type: type,
  time: Math.round(Date.now() / 1000 - time / 1000),
})

export const getCookieBannerMetrics = (
  time: number,
  mode: 'banner' | 'modal',
  count: number,
): CookieBannerMetrics => ({
  display_duration: Math.round(Date.now() / 1000 - time / 1000),
  acceptance_mode: mode,
  navigation_count: count,
  event_timing: Math.round(Date.now() / 1000 - time / 1000),
})
