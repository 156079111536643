import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import { CINCHCARE_ELIGIBLE_DAYS_AFTER_DELIVERY } from '../constants'
import {
  CinchCareOrdersResponse,
  OrderDetailsResponse,
  OrderDetailsStatus,
} from '../types'

dayjs.extend(utc)

export const checkCinchCareEligibility = (
  vehicleOrder: OrderDetailsResponse,
  allCinchCareOrders: CinchCareOrdersResponse[] | undefined,
): boolean => {
  const deliverySlotDate = vehicleOrder.deliverySlot?.date
  const deliveryDate = deliverySlotDate ? dayjs.utc(deliverySlotDate) : null
  const overTwoWeeksAgo = dayjs
    .utc()
    .subtract(CINCHCARE_ELIGIBLE_DAYS_AFTER_DELIVERY, 'days')
    .subtract(1, 'days') // this ensures exactly 2 weeks ago is allowed
  const alreadyHasCinchCare = allCinchCareOrders
    ?.map((plan) => plan.vehicleVrm)
    .includes(vehicleOrder.vehicle.vrm)

  if (!deliveryDate || alreadyHasCinchCare) {
    return false
  }

  return (
    orderIsCompleted(vehicleOrder.orderStatus) &&
    deliveryDate.isAfter(overTwoWeeksAgo, 'seconds')
  )
}

const orderIsCompleted = (orderStatus: OrderDetailsStatus): boolean =>
  orderStatus === 'OrderCompleted' || orderStatus === 'Fulfilled'
