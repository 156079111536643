import { ActiveOrder } from '../types'

export function checkOrderHasExpired(
  activeOrder: ActiveOrder | undefined,
  callback: () => void,
): void {
  if (!activeOrder) {
    return
  }

  const activeOrderExpiresCancelsAt =
    activeOrder.expiresAt ?? activeOrder.cancelledAt

  if (!activeOrderExpiresCancelsAt) {
    return
  }

  const timeDifference = activeOrderExpiresCancelsAt - Date.now()

  if (timeDifference <= 0) {
    callback()
    return
  }

  setTimeout(callback, timeDifference)
}
