import { getDatasourceEntries } from '@cinch-nx/data-storyblok'

export const DEV_STORYBLOK_URL = `https://storyblok-bff.snc-dev.aws.cinch.co.uk`

type FeatureFlags = Record<string, string>

async function getFeatureFlags(): Promise<FeatureFlags> {
  try {
    const { datasource_entries } = await getDatasourceEntries(
      {
        datasource: 'feature-flags',
        ...resolveDimension(),
      },
      {
        storyblokUrl: DEV_STORYBLOK_URL,
      },
    )

    return datasource_entries.reduce<FeatureFlags>(
      (acc, entry) => ({
        ...acc,
        [entry.name]: entry.dimension_value ?? entry.value,
      }),
      {} as FeatureFlags,
    )
  } catch (error) {
    const errorMessage = resolveErrorMessage(error)
    throw new Error(`fetching the Feature Flags failed! Error: ${errorMessage}`)
  }
}

/**
 * Returns a Boolean value depending on the string value of the featureflag.
 * We use On/Off to determine booleans as it is more natural to read.
 * @param flagName
 * @returns whether the flag is on(true) or off(off)
 */
export async function getBooleanFeatureFlag(
  flagName: string,
): Promise<boolean> {
  try {
    const featureFlag = await getFeatureFlag(flagName)

    if (!/on|off/i.test(featureFlag)) {
      console.warn(
        `value for flag "${flagName}" is not boolean, this will always return false`,
      )
    }

    // On/on/oN should pass, but Anything else is considered false
    return /on/i.test(featureFlag)
  } catch (error) {
    const errorMessage = resolveErrorMessage(error)
    console.warn(`Error Occurred: ${errorMessage}, returning "false" `)
    return false
  }
}

/**
 * Returns the feature flag value.
 * @param flagName
 * @returns the flag value
 */
export async function getFeatureFlag(flagName: string): Promise<string> {
  try {
    const featureFlags = await getFeatureFlags()

    if (!(flagName in featureFlags)) {
      console.warn(`Unknown Feature flag "${flagName}"; returning "Off"`)
      return 'Off'
    }

    return featureFlags[flagName]
  } catch (error) {
    const errorMessage = resolveErrorMessage(error)
    console.warn(`Error Occurred: ${errorMessage}, returning "Off"`)
    return 'Off'
  }
}

function resolveDimension() {
  const stageName = process.env['STAGE_NAME'] ?? 'local'

  if (stageName === 'local') {
    return {}
  }

  return {
    dimension: stageName === 'production' ? 'Production' : 'Development',
  }
}

function resolveErrorMessage(error: unknown) {
  let errorMessage = 'Unknown'
  if (error instanceof Error) {
    errorMessage = error.message
  }

  return errorMessage
}
