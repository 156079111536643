export const getAnalyticIDs = (): {
  adobeID: string | undefined
  gaID: string | undefined
} => {
  const adobeID = window?.s?.marketingCloudVisitorID
  const googleAnalyticObj =
    typeof window?.ga?.getAll === 'function' && window?.ga?.getAll()
  const gaID =
    (Array.isArray(googleAnalyticObj) &&
      googleAnalyticObj.length > 0 &&
      googleAnalyticObj[0].get('clientId')) ||
    undefined

  return {
    adobeID,
    gaID,
  }
}
